import { Connections } from 'modules/connections';
import { Dashboard } from 'modules/dashboard';
import { ActivityLog } from 'modules/activityLog';
import { MyData } from 'modules/myData';
import { Settings } from 'modules/settings';
import { Playground } from 'modules/playground';
import { Admin } from 'modules/admin';
import Page404 from 'modules/404/404';
import { ServiceDetails } from 'modules/connections/services';
import { Catalog, DataSources, Services } from 'modules/connections';

const PrivateRoutes = [
  {
    component: Admin,
    exact: false,
    path: '/admin',
    enableExtensionCheck: false,
  },
  {
    component: Dashboard,
    exact: false,
    path: '/dashboard',
    enableExtensionCheck: true,
  },
  // path '/' used to be onboarding, now is redirected to Dashboard
  {
    component: Dashboard,
    exact: true,
    path: '/',
    enableExtensionCheck: true,
  },
  {
    component: Catalog,
    exact: true,
    path: '/connections/catalog',
    enableExtensionCheck: true,
  },
  {
    component: Connections,
    exact: true,
    path: '/connections',
    enableExtensionCheck: true,
  },
  {
    component: DataSources,
    exact: true,
    path: '/connections/data-sources',
    enableExtensionCheck: true,
  },
  {
    component: ActivityLog,
    exact: false,
    path: '/activityLog',
    enableExtensionCheck: true,
  },

  {
    component: Playground,
    exact: false,
    path: '/playground',
    enableExtensionCheck: false,
  },
  {
    component: MyData,
    exact: true,
    path: '/myData',
    enableExtensionCheck: true,
  },
  {
    component: MyData,
    exact: false,
    path: '/myData/:resourcePath*',
    enableExtensionCheck: true,
  },
  {
    component: Settings,
    exact: false,
    path: '/settings',
    enableExtensionCheck: true,
  },
  {
    component: Services,
    exact: true,
    path: '/connections/services',
    enableExtensionCheck: true,
  },
  {
    component: ServiceDetails,
    exact: true,
    path: '/connections/services/:service',
    enableExtensionCheck: true,
  },
  {
    component: ServiceDetails,
    exact: true,
    path: '/connections/catalog/service/:service',
    enableExtensionCheck: true,
  },
  {
    component: Page404,
    exact: true,
    path: '*',
    enableExtensionCheck: false,
  },
];

export default PrivateRoutes;
