import { DEFAULTS } from '@datavillage-me/dv-common-ui';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './modules/app/containers';
import LoadingOverlay from "./components/overlay/LoadingOverlay";

// define the default progress element to be displayed while loading promises
DEFAULTS.loader = () => <LoadingOverlay relative={true} />

ReactDOM.render(<App />, document.getElementById('index'));
