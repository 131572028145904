import { DataSourceMetadata } from '@datavillage-me/api';
import styled from 'config/theme/styled';
import { palette } from 'config/theme/themeFiles';
import React from 'react';

type Props = {
  isOpen: boolean;
  handleShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  dataSourceDetails: DataSourceMetadata;
};

const DataSourceDetails = ({ isOpen, handleShowDetails, dataSourceDetails }: Props) => {
  return (
    <DataSourceDetailsWrapper
      isOpen={isOpen}
      handleShowDetails={handleShowDetails}
      dataSourceDetails={dataSourceDetails}
    >
      <Header>
        <HeaderContext>
          <Title>{dataSourceDetails.label}</Title>
        </HeaderContext>
        <Description>
          Spotify is a digital music, podcast, and video service that gives you access to millions of songs and other
          content from creators all over the world.
        </Description>
      </Header>
      <Content>
        <Subtitle>Actions used</Subtitle>
        <ActionsList>
          {dataSourceDetails?.mappings?.map((mappedAction) => (
            <>
              {mappedAction?.categories?.map((actionCategory: string, index) => (
                <li key={index}>
                  <Checkbox type="checkbox" checked={true} />
                  {actionCategory.split('/').pop()}
                </li>
              ))}
            </>
          ))}
        </ActionsList>
      </Content>
      <ButtonsWrapper>
        <div>
          <Cancel onClick={() => handleShowDetails(!isOpen)}>Cancel</Cancel>
        </div>
        {/* <div>
        <Deactivate className="mr">Deactivate</Deactivate>
        <EnableSelection>Enable selection</EnableSelection>
      </div> */}
      </ButtonsWrapper>
    </DataSourceDetailsWrapper>
  );
};

export default DataSourceDetails;

const Description = styled.p`
  color: ${palette.neutral.shade6};
`;

const Header = styled.div`
  background: ${palette.neutral.shade1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 26px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

const ActionsList = styled.ul`
  padding: 0;
  list-style-type: none;
  color: ${palette.neutral.shade6};
  font-weight: 300;
  font-size: 1.1em;
`;

const HeaderContext = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const Title = styled.h1`
  text-transform: uppercase;
  color: ${palette.primary.shade7};
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 26px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const Cancel = styled.button`
  background: ${palette.neutral.shade1};
  padding: 12px 26px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: ${palette.primary.shade7};
`;

// const Deactivate = styled.button`
//   background: ${palette.danger.shade1};
//   padding: 12px 26px;
//   border-radius: 10px;
//   border: none;
//   margin-right: 10px;
//   cursor: pointer;
//   color: ${palette.danger.shade4};
// `;

const Checkbox = styled.input`
  margin-right: 14px;
`;

// const EnableSelection = styled.button`
//   background: ${palette.success.shade1};
//   padding: 12px 26px;
//   border-radius: 10px;
//   border: none;
//   cursor: pointer;
//   color: ${palette.success.shade4};
// `;

const Subtitle = styled.h2`
  color: ${palette.neutral.shade8};
  font-size: 1.1em;
  font-weight: 600;
`;

const Content = styled.div`
  padding: 26px;
`;
const DataSourceDetailsWrapper = styled.div<Props>`
  background: white;
  border-radius: 25px;
  box-shadow: 0 4px 11px 9px rgb(158 158 158 / 9%);
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  left: calc(50% - 400px);
  margin: 16px;
  max-height: 95%;
  max-width: 95%;
  position: fixed;
  top: 50px;
  width: 800px;
  z-index: 2;
`;
