import CheckmarkIcon from 'assets/icons/check-mark.svg';
import React from 'react';
import {
  Checkmark,
  Header,
  Logo,
  LogoHelper,
  ServiceCategory,
  ServiceHeader,
  StyledService,
} from './ServiceTile.styled';

type Props = {
  to: string;
  name: string;
  category?: 'all' | 'mobility' | 'finance' | 'retail' | 'health' | 'media' | 'interests' | 'social';
  logo: string;
  enabled?: boolean;
};

const ServiceTile = ({ to, name, category, logo, enabled }: Props) => {
  return (
    <StyledService to={to} className={`${enabled && 'enabled'}`}>
      <Header>
        <ServiceHeader>{name}</ServiceHeader>
        {category && <ServiceCategory>{category}</ServiceCategory>}
      </Header>
      <LogoHelper>
        <Logo src={logo} alt="logo" />
      </LogoHelper>
      {enabled && (
        <Checkmark>
          <img src={CheckmarkIcon} alt="checkmark" />
        </Checkmark>
      )}
    </StyledService>
  );
};

export default ServiceTile;
