import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useToggle from 'hooks/useToggle';
import { AppContext, useRemoteClient } from '../../app/components/AppContext';
import { ActivitySearchQuery, Activity } from '@datavillage-me/api';

const useActivityLogStore = () => {
  const { t } = useTranslation(['errors']);
  const remoteClient = useRemoteClient();
  const userId = React.useContext(AppContext).currentUser?.id;
  const [activityLog, setActivityLog] = React.useState<Activity[] | null>(null);
  const [isPending, setIsPending] = useToggle(true);

  const fetchActivityLog = React.useCallback(
    (parameters?: ActivitySearchQuery) => {
      // TODO: fix typing to accept parameters

      if (userId) {
        setIsPending(true);
        remoteClient
          .getActivityLog()
          .getActivityLog({ userId, ...parameters })
          .then((result) => {
            setActivityLog(result);
          })
          .finally(() => {
            setIsPending(false);
          })
          .catch(() => {
            toast.error(t('activityLog.activityLog_fetch'));
          });
      } else {
        toast.error(t('authentication.notLoggedIn'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsPending]
  );

  return {
    isPending,
    fetchActivityLog,
    activityLog,
  };
};

export default useActivityLogStore;
