import ArrowLeft from 'assets/icons/arrow-left.png';
import { palette } from 'config/theme/themeFiles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from '../../config/theme/styled';
import Tooltip from '../tooltip';

const BackButton = () => {
  const { t } = useTranslation(['translation']);
  const history = useHistory();

  return (
    <Tooltip content={t('navigation.goBack')}>
      <NavLinkBackButton onClick={history.goBack}>
        <img src={ArrowLeft} alt="arrow left to go back" />
      </NavLinkBackButton>
    </Tooltip>
  );
};

const NavLinkBackButton = styled.span<{ isDisabled?: boolean }>`
  align-items: center;
  position: absolute;
  background: white;
  border-radius: 50%;
  border: none;
  color: ${palette.neutral.shade4};
  cursor: pointer;
  height: 42px;
  display: flex;
  justify-content: space-around;
  margin: 0 2px;
  padding: 0;
  transition: all 0.25s ease-out;
  width: 42px;
  top: 55px;
  left: 4px;
  ${(props) => props.isDisabled && 'cursor: not-allowed'};

  &:hover {
    color: ${palette.neutral.shade1};
    background: ${(props) => (props.isDisabled ? 'transparent' : palette.primary.shade1)};
    box-shadow: ${(props) => (props.isDisabled ? '' : '0px 2px 8px rgba(20, 130, 130, 0.4)')};
  }

  > span > svg,
  > span > span > svg {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    stroke: ${(props) => (props.isDisabled ? palette.neutral.shade4 : palette.neutral.shade9)};
    transition: all 0.25s ease-out;
  }
  img {
    height: 30px;
    width: 30px;
  }
`;

export default BackButton;
