import styled from 'styled-components';
import { Flexer } from 'components/interface';
import Badge from 'components/badge';

export const RecomendationLogo = styled.img`
  max-width: 80px;
  margin-right: 30px;
`;

export const RecommendationRow = styled(Flexer)`
  padding: 10px 0 20px;
  width: 100%;
`;

export const StyledBadge = styled(Badge)`
  margin-left: auto;
`;
