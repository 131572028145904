import * as React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { palette } from '../../config/theme/themeFiles';

type Props = {
  height: number;
  children: JSX.Element[] | JSX.Element;
} & IContentLoaderProps;

const GlobalContentLoader: React.FC<Props> = ({ ...props }: Props) => (
  <ContentLoader
    speed={2}
    foregroundColor={palette.neutral.shade2}
    backgroundColor={palette.neutral.shade1}
    {...props}
  />
);

export default GlobalContentLoader;
