import { useContext, useEffect, useState } from 'react';
import { MASTER_POD_ALIAS } from '@datavillage-me/api';

import { AppContext, useRemoteClient } from '../modules/app/components/AppContext';
import { PassportAppContext, Status } from '../modules/passport-app/context/PassportContext';
import { extractActionsFromMapping } from '../utils/dataSources';

export const useOnProviderActivationSuccess = () => {
  const appContext = useContext(AppContext);
  const passportContext = useContext(PassportAppContext);
  const remoteClient = useRemoteClient();

  const [provider, setProvider] = useState('');

  const linkProvider = (dataSourceId: string) => {
    const dataSource = passportContext.dataSources?.value?.find((dataSource) => dataSource.id === dataSourceId);
    dataSource &&
      passportContext.updateCtx({
        sharedDataSources: [
          ...(passportContext.sharedDataSources || []),
          {
            dataSourceId: dataSource.id,
            categories: extractActionsFromMapping(dataSource.mappings),
          },
        ],
      });
  };

  const handleStorageProviderActivationSuccess = (provider: string) => {
    if (provider !== '' && provider !== undefined && appContext?.currentUser?.id) {
      passportContext.updateCtx({ activatedDataSources: { status: Status.LOADING } });
      remoteClient
        .getUsersServices()
        .getPodInstance(appContext.currentUser.id, MASTER_POD_ALIAS)
        .getActivatedDatasources()
        .then((activatedDatasources) => {
          passportContext.updateCtx({
            activatedDataSources: {
              status: Status.SUCCESS,
              value: activatedDatasources,
            },
          });
          linkProvider(provider);
        })
        .catch(() => passportContext.updateCtx({ activatedDataSources: { status: Status.FAIL } }));
    }
  };

  const listenerOnProvider = () => {
    const providerIdActivated = window.localStorage.getItem('providerActivationSuccess') || '';
    providerIdActivated !== provider && setProvider(providerIdActivated);
    window.localStorage.removeItem('providerActivationSuccess');
  };

  useEffect(() => {
    handleStorageProviderActivationSuccess(provider);
  }, [provider]);

  useEffect(() => {
    window.addEventListener('storage', listenerOnProvider);

    return () => {
      window.removeEventListener('storage', listenerOnProvider);
    };
  }, [handleStorageProviderActivationSuccess]);
};
