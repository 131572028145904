import React from 'react';

import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
  circleColor?: string;
  className?: string;
};

const CheckMarkCircle: React.FC<Props> = ({ color, circleColor, className }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper className={className}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill={circleColor || '#E3FCEF'} />
        <path
          d="M13.75 7.5L8.78906 12.5L6.25 9.94318"
          stroke={color || '#188A50'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </BaseSvgWrapper>
  );
};

export default CheckMarkCircle;
