import { createGlobalStyle } from './styled';
import { typography } from './themeFiles';
import paletteTheme from './themeFiles/palette';

const GlobalStyle = createGlobalStyle`
    
    html, body {
      font-family: ${typography.fontFamily};
      font-size: 16px;
      height: 100%;
      line-height: 1.5;
      margin: 0;
      min-height: 100vh;
      padding: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }

    #index {
      height: 100%;
    }

    body {
      background: ${paletteTheme.neutral.shade1};
      color: ${paletteTheme.neutral.shade9};
    }

    #root {
      width: 100%;
      min-height: 100vh;
      display: flex;
      height: 100%;
      width: 100%;
    }

    *, *::before, *::after {
      box-sizing: border-box;
    }
    
    *:focus {
      outline: none;
    }

    h3, h4, h5, h6, p {
      font-weight: 400;
      margin: 0;
    }

    b {
      font-weight: 600;
    }
    
    h1 {
      font-size: 2em;
      font-weight: 700;
      margin: 0 0 5px 0;
      @media (min-width: 768px) {
        margin: 0 0 1em 0;
      }
    }

    h2 {
      font-weight: 700;
      margin: 0 0 0.8em 0;;
    }

    a {
      text-decoration: none;

      &:hover {
        color: ${paletteTheme.primary.shade7};
      }
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background: ${paletteTheme.neutral.shade2};
    }
    ::-webkit-scrollbar-thumb {
      background: ${paletteTheme.neutral.shade4};
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${paletteTheme.neutral.shade5};
    }
    ::-webkit-scrollbar-button {
      display: 'none';
    }

    ::selection { 
      background: ${paletteTheme.warning.shade3}; 
      color: ${paletteTheme.neutral.shade9}; 
    }

    button{
      font-family: ${typography.fontFamily};
      font-size: 1em;
    }

    #index {
      width: 100%;
      height: 100%;
    }
  `;

export default GlobalStyle;
