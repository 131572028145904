import CheckmarkIcon from 'assets/icons/check-mark.svg';
import { palette } from 'config/theme/themeFiles';
import React from 'react';
import styled from 'styled-components';
import { Checkmark, DataSourceActions, Header, Logo, LogoHelper, StyledDataSource } from './DataSourceTile.styled';

type Props = {
  enabled?: boolean;
  logo: string;
  name: string;
  numberOfActions?: number;
  onClick?: VoidFunction;
};

const DataSourceTile = ({ name, numberOfActions, logo, enabled, onClick }: Props) => {
  return (
    <StyledDataSource className={`${enabled && 'enabled'}`} onClick={onClick}>
      <LogoHelper>
        <Logo src={logo} alt="logo" />
      </LogoHelper>
      <Header>
        <DataSourceHeader>{name}</DataSourceHeader>
        {numberOfActions && <DataSourceActions>{numberOfActions} actions</DataSourceActions>}
      </Header>

      {enabled && (
        <Checkmark>
          <img src={CheckmarkIcon} alt="checkmark" />
        </Checkmark>
      )}
    </StyledDataSource>
  );
};

const DataSourceHeader = styled.h2`
  font-size: 0.8em;
  font-weight: 400;
  color: ${palette.primary.shade7};
  margin: 0;
`;

export default DataSourceTile;
