import * as React from 'react';
import styled from 'styled-components';
import { palette } from '../../config/theme/themeFiles';

export const StyledDate = styled.span`
  color: ${palette.neutral.shade5};
  display: block;
  font-size: 0.85em;
  width: 120px;
`;

export const FullDateWithHour = ({ timestamp }: { timestamp: number }) => {
  const date = new Date(timestamp).toDateString();
  const time = new Date(timestamp).toLocaleTimeString();

  return (
    <StyledDate>
      <div>{date}</div>
      <div>{time}</div>
    </StyledDate>
  );
};
