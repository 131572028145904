import styled from 'config/theme/styled';

type Props = {
  isOpen: boolean;
};

export const StyledBurger = styled.button<Props>`
  background-color: white;
  border-radius: 5px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 12px 2px #00000017;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
  padding: 10px 5px;
  width: 40px;
  z-index: 11;

  &:focus {
    outline: none;
  }
  div {
    background: #000000de;
    border-radius: 10px;
    height: 4px;
    position: relative;
    transition: all 0.3s linear;
    width: 30px;
    :first-child {
      transform: ${(props) => (props.isOpen ? 'rotate(34deg) translateY(-2px)' : 'rotate(0) translateY(0)')};
      width: ${(props) => (props.isOpen ? '36px' : '30px')};
      transform-origin: top left;
    }
    :nth-child(2) {
      opacity: ${(props) => (props.isOpen ? '0' : '1')};
      transform: ${(props) => (props.isOpen ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${(props) => (props.isOpen ? 'rotate(-34deg) translateY(2px)' : 'rotate(0) translateY(0)')};
      width: ${(props) => (props.isOpen ? '36px' : '30px')};
      transform-origin: bottom left;
    }
  }
`;

export default StyledBurger;
