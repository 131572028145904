import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from 'components/Skeleton';

const CatalogLoadingState: React.FC = () => (
  <section style={{ maxWidth: 800 }}>
    <SkeletonGenerator count={1}>
      <ContentLoader width={800} height={150}>
        <rect x="0" y="0" rx="10" ry="10" width="200" height="150" />
        <rect x="220" y="0" rx="10" ry="10" width="200" height="150" />
        <rect x="440" y="0" rx="10" ry="10" width="200" height="150" />
      </ContentLoader>
    </SkeletonGenerator>
  </section>
);

export default CatalogLoadingState;
