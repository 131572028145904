import styled from 'config/theme/styled';
import React from 'react';
import LogoImg from '../../../assets/images/Codename_Logo.svg';

const Policy: React.FC = () => {
  function createMarkup() {
    const privacyPolicyHtml = `<div><strong><span style="font-size: 26px;"><span data-custom-class="title">PRIVACY NOTICE</span></span></strong></div>
    <div>&nbsp;</div>
    <div><span style="color: #7f7f7f;"> <strong> <span style="font-size: 15px;"> <span data-custom-class="subtitle"> Last updated November 03, 2021 </span> </span> </strong> </span></div>
    <div>&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #7f7f7f;"> <span style="color: #595959; font-size: 15px;"> <span data-custom-class="body_text"> Thank you for choosing to be part of our community at Datavillage (" <strong>Company</strong> </span> <span data-custom-class="body_text"> ," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"). We are committed to protecting your personal information, your right to privacy and portability. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at contact@datavillage.me . </span> </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span data-custom-class="body_text">This privacy notice describes how your personal data are managed once you use our platform at&nbsp;<a href="http://mywallet.datavillage.me" target="_blank" rel="noopener" data-custom-class="link">http://myprofile.datavillage.me</a></span></span></span></div>
    <div>
    <div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span data-custom-class="body_text">The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how it is used, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</span></span></span></div>
    <div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span style="color: #595959;"><span data-custom-class="body_text"><strong>Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</strong></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #7f7f7f;"><span style="color: #000000;"><strong><span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <a href="#infocollect" data-custom-class="link"><span style="color: #595959;">1. WHAT INFORMATION DO WE COLLECT?</span></a> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <a href="#infouse" data-custom-class="link"><span style="color: #595959;">2. HOW DO WE USE YOUR INFORMATION?</span></a> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <span style="color: #595959;"><a href="#infoshare" data-custom-class="link">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <a href="#sociallogins" data-custom-class="link"><span style="color: #595959;"><span style="color: #595959;"><span style="color: #595959;">4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></span></span></a> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <a href="#inforetain" data-custom-class="link"><span style="color: #595959;">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <a href="#infosafe" data-custom-class="link"><span style="color: #595959;">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <a href="#infominors" data-custom-class="link"><span style="color: #595959;">7. DO WE COLLECT INFORMATION FROM MINORS?</span></a> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: #595959;"><a href="#privacyrights" data-custom-class="link">8. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#DNT" data-custom-class="link"><span style="color: #595959;">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a href="#policyupdates" data-custom-class="link"><span style="color: #595959;">10. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></div>
    <div style="line-height: 1.5;"><a href="#contact" data-custom-class="link"><span style="color: #595959; font-size: 15px;">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></div>
    <div style="line-height: 1.5;"><a href="#request" data-custom-class="link"><span style="color: #595959;">12. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</span></a></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="infocollect" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #000000;" data-custom-class="heading_2"><span style="font-size: 15px;"><strong>Personal information you disclose to us</strong></span></span></div>
    <div>
    <div>&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short: </em></strong></span></span></span></span><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"> <em>We DO NOT collect any personal information. We help you to get back control of your data. All personal information are under your control in your own data space, your personal data wallet.</em></span></span></span></span></span></span></div>
    </div>
    </div>
    </div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><strong style="color: #595959; font-size: 15px;">Social Login Data. </strong><span style="color: #595959; font-size: 15px;">We provide you with the option to register with us using your existing social media account details, like your Facebook, or other personal data wallet account like Solid or Google Drive. If you choose to register in this way, we will give you back control on the information described in the section called "</span><span style="font-size: 15px;"><span data-custom-class="body_text"><a href="#sociallogins" data-custom-class="link">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></span></span><span style="color: #595959; font-size: 15px;">" below.</span></div>
    </div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #000000;" data-custom-class="heading_2"><span style="font-size: 15px;"><strong>Information collected from other sources</strong></span></span></div>
    <div>
    <div>&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>You may connect data from <span style="color: #595959;"><span style="font-size: 15px;"><em><span data-custom-class="body_text">social media platforms, </span></em></span></span>and other outside sources.</em></span></span></span></span></span></span></div>
    </div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">The personal information that we connect depends on the context of your interactions with the platform the choices you make and the features you use. Any personal information that we connect depends on your decision and authorization.</span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">All personal information that you connect with the platform must be true, complete and accurate, and you must notify us of any changes to such personal information.</span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;">All personal information are under your control in your own data space, your personal data wallet.</span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="infouse" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">2. HOW DO WE USE YOUR INFORMATION?</span></strong></span></span></span></span></span></div>
    <div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We NEVER process your information for our own purpose. You information are processed by our partners based on your explicit consent.</em></span></span></span></span></span></span></div>
    </div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">Our partners process your personal information for the purpose they define and share with you. You always have to explicitly consent for the usage of your data.</span></span></span></div>
    <div style="line-height: 1.5;">
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="infoshare" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;Your information is NEVER shared with anyone. Our patners can process your information only with your explicit consent.</em></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">Our partners process your data that you hold if you have given us specific consent to use your personal information for a specific purpose.</span></span></span></div>
    <div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="sociallogins" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong> </span> </span> </span> </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</em></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"> <span style="font-size: 15px; color: #595959;"> <span data-custom-class="body_text"> Our <span style="font-size: 15px; color: #595959;"> <span style="font-size: 15px; color: #595959;"> <span style="font-size: 15px;"> <span data-custom-class="body_text"> <span style="font-size: 15px; color: #595959;"> <span style="font-size: 15px; color: #595959;"> <span style="font-size: 15px;"> <span data-custom-class="body_text"> platform&nbsp;</span> </span> </span> </span> </span> </span> </span> </span> offers you the ability to register and login using your third-party social media account details (like your Facebook login). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform. </span> </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"> <span style="font-size: 15px; color: #595959;"> <span data-custom-class="body_text"> We will store the information in your personal data wallet and it will only be used for the purposes that are described in the consent you have to explictly acknowledge. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps. </span> </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="inforetain" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em> We DO NOT keep your information. All your data are under your control into your personal data wallet</em></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We will only keep access to your personal data wallet until you revoke access to it. Your personal information are under your control and you can delete it whenever you want.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="infosafe" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em> We aim to protect your personal information through a system of organizational and technical security measures.</em></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our <span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text">Website</span></span></span></span></span></span></span></span> is at your own risk. You should only access the <span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text">Website</span></span></span></span></span></span></span></span> within a secure environment.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="infominors" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">7. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We do not knowingly collect data from or market to children under 18 years of age.</em></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the <span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text">Website</span></span></span></span></span></span></span></span>, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the <span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px;"><span data-custom-class="body_text">Website</span></span></span></span></span></span></span></span>. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">frederic@datavillage.me</span></span>.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="privacyrights" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">8. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the <a href="#contact" data-custom-class="link"><span style="font-size: 15px;"><span style="color: #3030f1;">contact details</span></span></a> provided below. We will consider and act upon any request in accordance with applicable data protection laws.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text"><span style="color: #3030f1;"><span data-custom-class="body_text"><a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener noreferrer" data-custom-class="link"><span style="font-size: 15px;">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span>.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <span style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text"><span style="color: #3030f1;"><span data-custom-class="body_text"><span style="font-size: 15px;"><a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener noreferrer" data-custom-class="link">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span></span></span></span></span>.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If you have questions or comments about your privacy rights, you may email us at <span style="font-size: 15px;"><span style="color: #595959;"><span data-custom-class="body_text">contact@datavillage.me</span></span></span>.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #000000;" data-custom-class="heading_2"><span style="font-size: 15px;"><strong>Account Information</strong></span></span></div>
    <div>
    <div>&nbsp;</div>
    <div style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">If you would at any time like to review or change the information in your account or terminate your account, you can:</span></span></span></span></span></span></div>
    <ul>
    <li style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">Log in the platform.</span></span></span></span></span></span></li>
    </ul>
    <div>&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account. Your personal information will remain into your personal data wallet but we will not be able to access it anymore.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="DNT" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We do not use cookie except technical cookie to maintain user session.</span></span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="policyupdates" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">10. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong> </span> </span> </span> </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"><em><strong>In Short: </strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="contact" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong> </span> </span> </span> </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"> <span style="font-size: 15px; color: #595959;"> <span data-custom-class="body_text"> If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) , F<span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">rederic Lebeau </span> </span> , <span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text"> by email at contact</span></span></span><span style="color: #595959; font-size: 15px;"><span data-custom-class="body_text">@datavillage.me </span> </span> </span></span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">If you have any further questions or comments, you may also contact us by post at the following corporate address:</span></span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <span data-custom-class="body_text"> Datavillage </span> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <span data-custom-class="body_text"> Pl. Sainte-Gudule 5, 1000 Bruxelles, Belgium </span> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <span data-custom-class="body_text"> Bruxelles <span style="font-size: 15px;"> <span data-custom-class="body_text"> <span style="color: #595959;"> , Bruxelles 1000 </span> </span> </span> </span> </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"> <span data-custom-class="body_text"> Belgium </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div id="request" style="line-height: 1.5;"><span style="color: #7f7f7f;"><span style="color: #595959; font-size: 15px;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span id="control" style="color: #000000;"><strong><span data-custom-class="heading_1">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA ?</span></strong> </span> </span> </span> </span> </span></div>
    <div style="line-height: 1.5;">&nbsp;</div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: #595959;"><span style="font-size: 15px; color: #595959;"><span data-custom-class="body_text">To request to review, update, or delete your personal information, please </span><span data-custom-class="body_text">visit the privacy section&nbsp;<a href="https://myprofile.datavillage.me/privacy" target="_blank" rel="noopener" data-custom-class="link">https://myprofile.datavillage.me/privacy</a></span></span><span data-custom-class="body_text">. You get a view on your personal data wallet and you have full control on it. Other personal data wallet manager exists and you can use these one to manage your data as well.</span></span></div>
    </div>
    </div>
    &nbsp;</div>
    <div style="line-height: 1.5;">&nbsp;</div>`;

    return { __html: privacyPolicyHtml };
  }

  return (
    <PolicyWrapper>
      <Logo src={LogoImg} alt="logo" />
      <div dangerouslySetInnerHTML={createMarkup()} />
    </PolicyWrapper>
  );
};

export default Policy;

const Logo = styled.img`
  margin-bottom: 50px;
  width: 250px;
`;

const PolicyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  margin-top: 50px;
  max-width: 100%;
  padding: 16px;
`;
