import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'config/theme/styled';
import XCircle from 'assets/icons/x-circle.svg';
import Refresh from 'assets/icons/refresh.svg';
import DownloadCloud from 'assets/icons/download-cloud.svg';
import AuthorizationDetails from './AuthorizationDetails';
import Table from 'components/table/Table';
import { Checkbox } from 'components/table/Table.styles';
import { AppContext, useRemoteClient } from '../app/components/AppContext';
import { PromiseFnContainer, usePromiseFn } from '@datavillage-me/dv-common-ui';
import { MASTER_POD_ALIAS } from '@datavillage-me/api';
import paletteTheme from '../../config/theme/themeFiles/palette';

type DismissHelperProps = {
  isOpen: boolean;
};

const Authorizations: React.FC = () => {
  const { t } = useTranslation();
  const context = useContext(AppContext);

  const client = useRemoteClient();

  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const activeProviders = usePromiseFn(
    () =>
      client
        .getUsersServices()
        .getPodInstance(context.currentUser!.id, MASTER_POD_ALIAS)
        .getActivatedDatasources()
        .then((providers) => Object.fromEntries(providers.map((p) => [p.resourceUri, p]))),
    [client, context.currentUser!.id]
  );

  return (
    <React.Fragment>
      <p>{t('authorizations.subTitle')}</p>
      <Table
        withCheckBox
        tableHeaders={[
          { title: 'Data provider' },
          { title: 'Authorized since' },
          { title: 'Action types' },
          { title: '' },
        ]}
      >
        <PromiseFnContainer promiseFn={() => client.getDataBridge().getDataSources()} deps={[]}>
          {(dataSources) => (
            <React.Fragment>
              {dataSources.map((dataSource) => (
                <tr key={dataSource.id}>
                  <td>
                    <Checkbox type="checkbox" />
                  </td>
                  <td>
                    <DetailsLink onClick={toggleShowDetails}>{dataSource.label}</DetailsLink>
                  </td>
                  <td>
                    {activeProviders.result &&
                      activeProviders.result[dataSource.id] &&
                      new Date(activeProviders.result[dataSource.id].activationDate).toLocaleString()}
                  </td>
                  <td>
                    {Array.from(
                      dataSource.mappings.reduce((set, mapping) => {
                        mapping.categories.forEach((c) =>
                          set.add(c.split('/').pop() /* temp solution : take sdo URL last element as label */)
                        );

                        return set;
                      }, new Set())
                    ).join(',')}
                  </td>
                  <td>
                    <RevokeIcon
                      src={XCircle}
                      title="Revoke authorization"
                      alt="revoke"
                      onClick={() =>
                        client
                          .getUsersServices()
                          .getPodInstance(context.currentUser!.id, MASTER_POD_ALIAS)
                          .revokeDatasource(context.currentUser!.id)
                      }
                    />
                    <RefreshIcon
                      src={Refresh}
                      alt="refresh"
                      title="Renew Authorization"
                      onClick={async () =>
                        window.open(
                          await client
                            .getPassport()
                            .getDatasourceActivationUrl(window.location.toString(), dataSource.id, true)
                        )
                      }
                    />
                    <DownloadIcon
                      src={DownloadCloud}
                      alt="refresh"
                      title="Import Data"
                      onClick={() => client.getDataBridge().importData([context.currentUser!.id], dataSource.id)}
                    />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          )}
        </PromiseFnContainer>
      </Table>
      <AuthorizationDetails isOpen={showDetails} handleShowDetails={toggleShowDetails} />
      {false && <DismissHelper isOpen={showDetails} onClick={toggleShowDetails} />}
    </React.Fragment>
  );
};

export default Authorizations;

const DetailsLink = styled.button`
  cursor: pointer;
  background: none;
  text-decoration: underline;
  border: none;
`;

// const ConsentsTable = styled.table`
//   width: 100%;
//   margin-top: 50px;
//   border-spacing: 50px;
//   padding: 2em;
//   border-collapse: collapse;
//   tr {
//     border-bottom: 1px solid ${paletteTheme.neutral.shade3};
//     border-spacing: 0 15px;
//   }
//   tr:last-child {
//     border-bottom: none;
//   }
//   td,
//   th {
//     padding: 20px 5px;
//   }
// `;

export const DownloadIcon = styled.img`
  margin-right: 10px;
  background: ${paletteTheme.primary.shade1};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`;

export const RevokeIcon = styled.img`
  margin-right: 10px;
  background: ${paletteTheme.danger.shade1};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`;

export const RefreshIcon = styled.img`
  background: ${paletteTheme.success.shade1};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`;

const DismissHelper = styled.div<DismissHelperProps>`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0;
  left: 0;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;
