import { ApplicationDescriptor } from '@datavillage-me/api';
import { FullDateWithHour } from 'components/datatypes/date';
import { Flexer } from 'components/interface';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { logoFactory } from 'utils/images';
import ApplicationLabel from '../components/ApplicationLabel';
import TileLoadingState from '../Dashboard.loading';
import { RecommendationType } from '../types';
import { RecomendationLogo, RecommendationRow, StyledBadge } from './RecentRecommendations.styled';

type Props = {
  activeApplications: ApplicationDescriptor[] | null;
  isAApending: boolean;
};

const RecentRecommendations = ({ activeApplications, isAApending }: Props) => {
  const { t } = useTranslation(['dashboard']);

  // Filter recent recommendations first if it has new items, then sort on date, then take first 5
  const recentRecommendations =
    activeApplications !== null &&
    activeApplications
      .reduce((recommendation, app) => {
        app.recentActivity &&
          recommendation.push(...app.recentActivity.map((summary) => ({ ...summary, appId: app.id })));

        return recommendation;
      }, [] as RecommendationType[])
      .filter((recom) => recom.newItems.length > 0)
      .sort((s1, s2) => (s1.timestamp < s2.timestamp ? 1 : -1))
      .slice(0, 5);

  return (
    <React.Fragment>
      <h2>{t('recentRecommendations.title')}</h2>

      {isAApending ? (
        <TileLoadingState />
      ) : recentRecommendations ? (
        recentRecommendations.map((recentRecom) => (
                <RecommendationRow align='center' key={recentRecom.timestamp}>
                    <RecomendationLogo src={logoFactory(recentRecom.appId)} />
                    <Flexer direction='column'>
                        <ApplicationLabel appId={recentRecom.appId} />
                        <div>
                            <FullDateWithHour timestamp={new Date(recentRecom.timestamp).getTime()} />
                        </div>
                    </Flexer>
                    <StyledBadge color='success'>{recentRecom.newItems.length} new</StyledBadge>
                </RecommendationRow>
        ))
      ) : null}
    </React.Fragment>
  );
};

export default RecentRecommendations;
