import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const UpdateConsent: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M8 4V5C8 5.55 8.45 6 9 6H15C15.55 6 16 5.55 16 5V4H19C19.55 4 20 4.45 20 5V21C20 21.55 19.55 22 19 22H5C4.45 22 4 21.55 4 21V5C4 4.45 4.45 4 5 4H8Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M15 6H9C8.45 6 8 5.55 8 5V3C8 2.45 8.45 2 9 2H15C15.55 2 16 2.45 16 3V5C16 5.55 15.55 6 15 6Z"
        ></path>
        <path
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M12.84 11.1L10.36 15.39L10.3 16.5L11.22 15.89L13.7 11.6L12.84 11.1Z"
        ></path>
      </svg>
    </BaseSvgWrapper>
  );
};

export default UpdateConsent;
