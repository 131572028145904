import styled from 'styled-components';

export const CatalogContainer = styled.div`
  display: flex;
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 26px;
`;
