import React from 'react';
import styled from 'styled-components';
import PuffLoader from 'react-spinners/PuffLoader';
import paletteTheme from 'config/theme/themeFiles/palette';

const LoadingOverlay = (props: { relative?: boolean }) => (
  <StyledLoadingOverlay {...props}>
    <PuffLoader color={paletteTheme.primary.shade5} />
  </StyledLoadingOverlay>
);

const StyledLoadingOverlay = styled.div<{ relative?: boolean }>`
  background: rgba(255, 255, 255, 0.9);
  blur: 10px;
  height: 100%;
  width: 100%;
  position: ${(props) => (props.relative ? undefined : 'fixed')};
  top: ${(props) => (props.relative ? '50%' : undefined)};
  left: ${(props) => (props.relative ? '50%' : undefined)};
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    z-index: 999;
  }
`;

export default LoadingOverlay;
