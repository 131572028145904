import {
  UserScopeApplication,
  ConsentReceipt,
  DataSourceMetadata,
  DatasourceRecord,
  Consent,
  DataSourceMappingMetadata,
} from '@datavillage-me/api';

import { createUpdatableContext } from '../../../utils/utils';

export enum Status {
  'LOADING' = 'LOADING',
  'FAIL' = 'FAIL',
  'SUCCESS' = 'SUCCESS',
}

/**
 * Data provider shared within the scope of a consent
 */
export type SharedDataSource = {
  /** ID of the provider */
  dataSourceId: DataSourceMetadata['id'];

  /** List of fully qualified URIs for the available action categories */
  categories: DataSourceMappingMetadata['categories'];
};

export type PassportContextType = {
  /** Passport Config */
  passportConfig?: { clientId?: string; applicationId?: string };

  /** Passport Application */
  application?: { value?: UserScopeApplication; status?: Status };

  /** Application Consent Receipt */
  consentReceipt?: { value?: ConsentReceipt; status?: Status };

  /** Previously signed consent */
  previousConsent?: { value?: Consent; status?: Status };

  /** Data sources */
  dataSources?: { value?: (DataSourceMetadata & { recommended?: boolean })[]; status?: Status };

  /** Activated data providers */
  activatedDataSources?: { value?: DatasourceRecord[]; status?: Status };

  /** Activated data providers */
  sharedDataSources: SharedDataSource[];
};

export const PassportAppContext = createUpdatableContext<PassportContextType>({sharedDataSources: []});
