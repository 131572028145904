import { AppContext, useRemoteClient } from "../app/components/AppContext";
import {
  PromiseFnContainer,
  PromiseStateContainer,
  useParameterizedPromiseFn
} from "@datavillage-me/dv-common-ui";
import * as React from "react";
import { useContext, useState } from "react";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { Switch } from "react-router";
import { ConsentStatus, MASTER_POD_ALIAS } from "@datavillage-me/api";
import Refresh from "../../assets/icons/refresh.svg";
import { RefreshIcon } from "../authorizations/Authorizations";
import { ColumnFlexDiv, Tab, Tabs } from "./styles";
import IconButton from "../../components/button/IconButton";
import { RevokeConsent } from "../../assets/icons";
import { Button, buttonTypes } from "@datavillage-me/dv-common-ui";

export function AdminUsersRoute() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:userId`}>
        <AdminUserDetails />
      </Route>
      <Route path={`${path}`}>
        <AdminUsersList />
      </Route>
    </Switch>
  );
}

export const AdminUsersList = () => {
  const client = useRemoteClient();
  const { path } = useRouteMatch();

  const ctx = useContext(AppContext);

  return (
    <ColumnFlexDiv>
      <h2>Users</h2>
      <PromiseFnContainer promiseFn={() => client.getUsersMgmtService().getUsers({})} deps={[]}>
        {(users) => (
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>Created</td>
                <td>Master POD</td>
                <td>Role</td>
              </tr>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <Link to={`${path}/${user.id}`}>
                      {user.id}
                      {ctx.currentUser?.id == user.id ? (
                        <sup>
                          <b>ME</b>
                        </sup>
                      ) : (
                        ''
                      )}
                    </Link>
                  </td>
                  <td>{user.creationDate}</td>
                  <td>{user.masterPodUri}</td>
                  <td>{user.role}</td>
                  <td>
                    <IconButton onClick={() => client.getUsersMgmtService().deleteUser(user.id)}>
                      <RevokeConsent />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};

export const AdminUserDetails = () => {
  const client = useRemoteClient();
  const { params } = useRouteMatch<{ userId: string }>();

  const [activeTab, setActiveTab] = useState(0);

  const status = useParameterizedPromiseFn((userId, fix?: boolean) => client.getUsersMgmtService().checkIntegrity(userId, fix), [client]);

  return (
    <ColumnFlexDiv>
      <h2>User {params.userId}</h2>
      <div>
        <Button buttonType={buttonTypes.primary} onClick={() => status.fetch(params.userId)} label="Check status"/>
        {
          status.result?.status == "NOK" ?
            <Button buttonType={buttonTypes.secondary} onClick={() => status.fetch(params.userId, true)} label="Fix"/> : null
        }
        <PromiseStateContainer promiseState={status}>
          {(status) => <span>{status.status} : {status.message}</span>}
        </PromiseStateContainer>
      </div>
      <PromiseFnContainer promiseFn={() => client.getUsersServices().getUser(params.userId)} deps={[params.userId]}>
        {(user) => (
          <>
            <div>
              <Tabs>
                <Tab className={`left ${activeTab == 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
                  User Details
                </Tab>
                <Tab className={`right ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                  Datasources
                </Tab>
                <Tab className={`right ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
                  Consents
                </Tab>
              </Tabs>
            </div>

            {activeTab == 0 ? (
              <table>
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{user.id}</td>
                  </tr>
                  <tr>
                    <th>Client</th>
                    <td>{user.clientId}</td>
                  </tr>
                  <tr>
                    <th>Creation Date</th>
                    <td>{user.creationDate}</td>
                  </tr>
                  <tr>
                    <th>Master Pod</th>
                    <td>{user.masterPodUri}</td>
                  </tr>
                  <tr>
                    <th>Role</th>
                    <td>{user.role}</td>
                  </tr>
                </tbody>
              </table>
            ) : activeTab == 1 ? (
              <UserDatasources userId={params.userId} />
            ) : activeTab == 2 ? (
              <UserConsents userId={params.userId} />
            ) : null}
          </>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};

export const UserDatasources = (props: { userId: string }) => {
  const client = useRemoteClient();

  return (
    <PromiseFnContainer
      promiseFn={() => {
        const activeSources = client
          .getUsersServices()
          .getPodInstance(props.userId, MASTER_POD_ALIAS)
          .getActivatedDatasources();
        const runningJobs = client.getJobsService().getJobs({ userId: props.userId, type: 'IMPORT_DATA' });

        return Promise.all([activeSources, runningJobs]);
      }}
      deps={[props.userId]}
    >
      {([datasources, jobs]) => (
        <table>
          <tbody>
            <tr>
              <td>Provider ID</td>
              <td>Activation Date</td>
              <td>User ID</td>
              <td>User Email</td>
              <td>User Name</td>
              <td>Last job</td>
            </tr>
            {datasources.map((ds) => (
              <tr key={ds.resourceUri}>
                <td>{ds.resourceUri}</td>
                <td>{ds.activationDate}</td>
                <td>{ds.userInfo?.id}</td>
                <td>{ds.userInfo?.email}</td>
                <td>{ds.userInfo?.name}</td>
                <td>
                  {
                    jobs.find(
                      (job) => job.request.type == 'IMPORT_DATA' && job.request.params.datasourceId == ds.resourceUri
                    )?.status
                  }
                </td>
                <td>
                  <RefreshIcon
                    src={Refresh}
                    alt="refresh"
                    onClick={() => client.getDataBridge().startImportJob([props.userId], ds.resourceUri)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </PromiseFnContainer>
  );
};

export const UserConsents = (props: { userId: string }) => {
  const client = useRemoteClient();

  return (
    <PromiseFnContainer
      promiseFn={() => {
        return client.getConsentsServices().getConsents(props.userId);
      }}
      deps={[props.userId]}
    >
      {(consents) => (
        <table>
          <tbody>
            <tr>
              <td>Consent ID</td>
              <td>Application ID</td>
              <td>Status</td>
              <td>Timestamp</td>
              <td>Expiry</td>
            </tr>
            {consents.map((consent) => {
              const expiryDate = new Date(consent['gConsent:hasExpiry']['time:hasEnd']['time:inXSDDateTimeStamp']);

              return (
                <tr key={consent['@id']}>
                  <td>
                    <Link to={`/admin/consents/${consent['@id']}`}>{consent['@id']}</Link>
                  </td>
                  <td>
                    <Link to={`/admin/clients/${consent.clientId}/apps/${consent.applicationId}`}>
                      {consent.applicationId}
                    </Link>
                  </td>
                  <td>{consent['gConsent:hasStatus']}</td>
                  <td>{consent['gConsent:atTime']['time:inXSDDateTimeStamp']}</td>
                  <td>{expiryDate.toLocaleString()}</td>
                  {consent['gConsent:hasStatus'] == ConsentStatus.Given &&
                  expiryDate.getTime() > new Date().getTime() ? (
                    <td>
                      <RefreshIcon
                        src={Refresh}
                        alt="refresh"
                        onClick={() =>
                          client.getJobsService().startJob({
                            type: 'INFER',
                            params: {
                              userIds: [props.userId],
                              clientId: consent.clientId,
                              applicationId: consent.applicationId,
                            },
                          })
                        }
                      />
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </PromiseFnContainer>
  );
};
