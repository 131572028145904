import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const WriteDataPod: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M2 18.0012H7"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M19.586 6.29119L11.0707 14.8012L10 18.0012L13.192 16.9412L21.7073 8.43119C22.0976 8.04119 22.0976 7.40119 21.7073 7.01119L21.0069 6.29119C20.6066 5.90119 19.9762 5.90119 19.586 6.29119Z"
        ></path>
      </svg>
    </BaseSvgWrapper>
  );
};

export default WriteDataPod;
