import { getRemoteClient, POD_IDENTITY_PROVIDER } from '@datavillage-me/api';
import GoogleImg from 'assets/images/google.svg';
import InruptImg from 'assets/images/inrupt.png';
import SolidImg from 'assets/images/solid.svg';
import styled from 'config/theme/styled';
import paletteTheme from 'config/theme/themeFiles/palette';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { inIframe, useQuery } from 'utils';
import { AppContext } from '../../app/components/AppContext';
import { AppContextType } from '../../app/type';

export async function handleExternalAuth(
  appContext: AppContextType,
  redirectUrl: string,
  apiUrl: string,
  podType: POD_IDENTITY_PROVIDER,
  issuer?: string
) {
  try {
    if (!redirectUrl) {
      // if no redirectUrl is provided, redirect to the app root
      const currentUrl = new URL(window.location.href);
      currentUrl.hash = '';
      redirectUrl = encodeURIComponent(currentUrl.toString());
    }

    // TODO use separate login window always, or only when embedded in iFrame ?
    if (inIframe()) {
      // postMessage listening function
      const listener = (e: MessageEvent) => {
        if (e.origin != window.location.origin) {
          console.warn(`Received a login callback from wrong origin (${e.origin}) ; ignoring `);
        } else {
          try {
            if (e.data == 'OK') {
              // the login has succeeded - let's redirect to the original redirectUrl ...
              window.location.href = redirectUrl;
            } else {
              // TODO process error status
              console.warn('Unknown login flow event : ' + e.data);
            }
          } finally {
            // remove the postMessage listener
            window.removeEventListener('message', listener, false);
            loginWindow && loginWindow.close();
          }
        }
      };

      // use /login/callback as redirectUrl. That will result in a postMessage upon login
      // the actual redirectUrl will then be used in the listener above
      const authUrl = await getRemoteClient(apiUrl)
        .getPassport()
        .getAuthenticationUrl(window.location.origin + '/login/callback', podType, issuer);

      window.addEventListener('message', listener, false);
      const loginWindow = window.open(authUrl);
    } else {
      // Build the external auth URL that will target the Passport Auth component
      // The successful login flow will eventually result in a redirect from Passport to the redirectUrl
      const authUrl = await getRemoteClient(apiUrl).getPassport().getAuthenticationUrl(redirectUrl, podType, issuer);

      window.location.href = authUrl;
    }
  } catch (error) {
    toast.error(error);
  }
}

type Props = { passport?: boolean };
type SelectProviderProps = Props & { redirectUri: string };
const SelectProviderNew = ({ passport, redirectUri }: SelectProviderProps) => {
  const { t } = useTranslation();

  const appContext = useContext(AppContext);
  const redirectUrlParam = useQuery().redirectUrl;

  const handleGoogleExternalAuth = (e: React.FormEvent) => {
    e.preventDefault();
    handleExternalAuth(appContext, redirectUrlParam || redirectUri, appContext.passport_url, POD_IDENTITY_PROVIDER.Google);
  };

  const handleSolidCommunityExternalAuth = async (e: React.FormEvent) => {
    e.preventDefault();

    handleExternalAuth(
      appContext,
      redirectUrlParam || redirectUri,
      appContext.passport_url,
      POD_IDENTITY_PROVIDER.Solid,
      'https://solidcommunity.net/'
    );
  };

  const handleInruptNetExternalAuth = async (e: React.FormEvent) => {
    e.preventDefault();

    handleExternalAuth(
      appContext,
      redirectUrlParam || redirectUri,
      appContext.passport_url,
      POD_IDENTITY_PROVIDER.Solid,
      'https://inrupt.net/'
    );
  };

  const CustomOption = ({ data, innerProps }: { data: any; innerProps: any }) => {
    const imageMapping = {
      inruptNet: InruptImg,
      solidCommunity: SolidImg,
    };

    return (
      <PlatformButtonNoBorder {...innerProps}>
        <BrandImg src={imageMapping[data.value]} alt={t(`login.external.${data.value}`)}></BrandImg>
        <span>{t(`login.external.${data.value}`)}</span>
      </PlatformButtonNoBorder>
    );
  };

  const CustomSingleValue = ({ data, innerProps }: { data: any; innerProps: any }) => {
    const imageMapping = {
      inruptNet: InruptImg,
      solidCommunity: SolidImg,
    };
    const onClickMapping = {
      inruptNet: handleInruptNetExternalAuth,
      solidCommunity: handleSolidCommunityExternalAuth,
    };

    return (
      <PlatformButtonNoBorder {...innerProps} onClick={onClickMapping[data.value]}>
        <BrandImg src={imageMapping[data.value]} alt={t(`login.external.${data.value}`)}></BrandImg>
        <span>{t(`login.external.${data.value}`)}</span>
      </PlatformButtonNoBorder>
    );
  };

  return (
    <LoginWrapper passport={passport}>
      <PlatformButton onClick={handleGoogleExternalAuth}>
        <BrandImg src={GoogleImg} alt={t('login.external.google')}></BrandImg>
        <span>{t('login.external.google')}</span>
      </PlatformButton>
      <Select
        defaultValue={{ label: 'solidCommunity', value: 'solidCommunity' }}
        styles={{ container: selectContainer, control: selectControl, input: selectInput }}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        options={[
          { value: 'solidCommunity', label: 'solidCommunity' },
          { value: 'inruptNet', label: 'inruptNet' },
        ]}
      />
    </LoginWrapper>
  );
};

export default SelectProviderNew;

const LoginWrapper = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${(props) => (props.passport ? '0px' : '50px')};
  width: ${(props) => props.passport && '100%'};
  max-width: 100%;
  padding: 16px;
`;

const PlatformButton = styled.button`
  background: none;
  border-radius: 10px;
  border: 1px solid ${paletteTheme.neutral.shade2};
  color: ${paletteTheme.neutral.shade7};
  cursor: pointer;
  display: block;
  font-weight: 400;
  margin-bottom: 20px;
  padding: 5px 16px;
  text-align: center;
  width: 100%;

  &:hover {
    color: ${paletteTheme.neutral.shade9};
    border: 1px solid ${paletteTheme.neutral.shade5};
  }

  span {
    margin-left: 10px;
  }
`;

const PlatformButtonNoBorder = styled.div`
  background: none;
  border-radius: 10px;
  color: ${paletteTheme.neutral.shade7};
  font-weight: 400;
  padding: 5px 16px;
  text-align: center;
  width: 100%;

  :hover {
    cursor: pointer;
  }

  span {
    margin-left: 10px;
  }
`;

const BrandImg = styled.img`
  height: 35px;
  vertical-align: middle;
`;

const selectContainer = (provided: any) => ({
  ...provided,
  padding: '2px',
  background: 'none',
  borderRadius: '10px',
  border: `1px solid ${paletteTheme.neutral.shade2}`,
  color: `${paletteTheme.neutral.shade7}`,
  cursor: 'pointer',
  display: 'block',
  fontWeight: '400',
  marginBottom: '0px',
  textAlign: 'center',
  width: '100%',
});

const selectControl = (provided: any) => ({
  ...provided,
  borderStyle: 'none',
  boxShadow: 'none',
  backgroundColor: 'none',
});

const selectInput = (provided: any) => ({
  ...provided,
  display: 'none',
});
