import React from 'react';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { NavLink } from 'react-router-dom';

import styled from '../../config/theme/styled';
import { useTranslation } from 'react-i18next';
import Logo from 'assets/images/Wallet_Logo_White.png';

export const LandingFooter: React.FC<{ inLogin?: boolean }> = ({ inLogin }) => {
  const { t } = useTranslation();

  return (
    <FooterContainer inLogin={inLogin}>
      {!inLogin && <DvLogo src={Logo}></DvLogo>}
      <div>
        <NavLink to="/privacy/terms-of-use">
          <Button className={'cta'} onClick={() => undefined} buttonType={buttonTypes.secondary} label={t('login.termsOfUse')} />
        </NavLink>
        <NavLink to="/privacy/policy">
          <Button className={'cta'} onClick={() => undefined} buttonType={buttonTypes.secondary} label={t('login.policy')} />
        </NavLink>
      </div>
    </FooterContainer>
  );
};

const FooterContainer = styled.div<{ inLogin?: boolean }>`
  align-items: center;
  width: 100%;
  // higher zIndex causes the footer to overlay some dropdowns like the pod provider selector
  z-index: ${(props) => (props.inLogin ? '0' : '1')};
  display: flex;
  justify-content: ${(props) => (props.inLogin ? 'center' : 'space-between')};
  height: ${(props) => (props.inLogin ? 'unset' : '200px')};

  .cta {
    color: white;
    width: auto;
    margin-right: 30px;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const DvLogo = styled.img`
  height: 50px;
  margin: 0 10px 0 40px;
`;
