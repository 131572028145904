import React from 'react';
import styled from '../../config/theme/styled';

export const FacebookCta: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <FacebookCtaContainer onClick={onClick}>
      <Logo
        className="img"
        src="https://static.xx.fbcdn.net/rsrc.php/v3/yN/r/szGrb_tkxMW.png"
        alt=""
        width="24"
        height="24"
      />
      <Text>Continuer avec Facebook</Text>
    </FacebookCtaContainer>
  );
};

const FacebookCtaContainer = styled.div`
  background-color: #1877f2;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  border: none;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.25px;
  white-space: nowrap;
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-right: 12px;
`;

const Logo = styled.img`
  margin-right: 10px;
  margin-left: 12px;
`;
