import React from 'react';
import styled from '../../config/theme/styled';
import { palette } from '../../config/theme/themeFiles';

type Props = {
  checked: boolean;
  className?: string;
  toggle: VoidFunction;
};

export const Toggle: React.FC<Props> = ({ checked, toggle, className }: Props) => (
  <Switch className={className}>
    <Input type={'checkbox'} checked={checked} onChange={toggle} />
    <Span />
  </Switch>
);

const Switch = styled.label`
  display: inline-block;
  height: 24px;
  position: relative;
  width: 46px;
`;

const Input = styled.input`
  height: 0;
  opacity: 0;
  width: 0;

  :checked + span {
    background-color: ${palette.primary.shade4};
  }

  :focus + span {
    box-shadow: 0 0 1px ${palette.primary.shade4};
  }

  :checked + span:before {
    transform: translateX(16px);
  }
`;

const Span = styled.span`
  background-color: #ccc;
  border-radius: 20px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  :before {
    background-color: white;
    border-radius: 50%;
    bottom: 4px;
    content: '';
    height: 16px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 16px;
  }
`;
