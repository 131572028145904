import styled from 'config/theme/styled';
import { mediaSize } from '../../config/theme/themeFiles/MediaSizes';
import { palette } from '../../config/theme/themeFiles';

export const MainContainer = styled.div<{ passport?: boolean }>`
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-image: ${(props) =>
    props.passport
      ? 'unset'
      : `linear-gradient(to bottom right, ${palette.secondary.shade9}, ${palette.primary.shade4})`};
`;

export const LoginWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  max-width: 100%;
  width: 750px;
  padding: 16px;
`;

export const LoginContainer = styled.div<{ passport?: boolean }>`
  background-color: white;
  ${mediaSize.tabletAndAbove`
box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
`}
  border-radius: 15px;
  max-width: 90%;
  padding: 30px 20px;
`;

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 50px;
`;
