import { palette } from 'config/theme/themeFiles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Explanations = () => {
  const { t } = useTranslation(['dashboard']);

  return (
    <React.Fragment>
      <h2>{t('explanations.title')}</h2>
      <Block>
        <h3>{t('explanations.services')}</h3>
        <p>{t('explanations.servicesExpl')}</p>
      </Block>
      <Block>
        <h3>{t('explanations.dataSources')}</h3>
        <p>{t('explanations.dataSourcesExpl')}</p>
      </Block>
      <Block>
        <h3>{t('explanations.dataWallet')}</h3>
        <p>{t('explanations.dataWalletExpl')}</p>
      </Block>
      <Block>
        <h3>{t('explanations.dataCompanion')}</h3>
        <p>{t('explanations.dataCompanionExpl')}</p>
      </Block>
    </React.Fragment>
  );
};

const Block = styled.div`
  display: flex;
  margin: 10px 0px 40px;
  flex-direction: column;

  > h3 {
    font-weight: 600;
    color: ${palette.primary.shade4};
    padding-bottom: 6px;
  }
  > p {
    color: ${palette.neutral.shade6};
    font-size: 15px;
  }
`;

export default Explanations;
