import styled from 'styled-components';
import { palette } from 'config/theme/themeFiles';

export const FlexHelper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

export const ActivityIconWrapper = styled.div`
  background: ${palette.secondary.shade8};
  border-radius: 50%;
  height: 46px;
  margin-right: 30px;
  min-width: 46px;
  width: 46px;
  display: flex;
  justify-content: center;

  svg {
    margin-top: 4px;
    padding: 5px;
    width: 38px;
    height: 38px;
  }
`;

export const ActivityType = styled.span`
  color: ${palette.neutral.shade7};
`;

export const ActivityMessageStyle = styled.span`
  align-self: center;
  color: ${palette.neutral.shade8};
`;

export const Activity = styled.li`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const TypeAndDateWrapper = styled.div`
  min-width: 300px;
  max-width: 500px;
`;
