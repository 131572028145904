import { Activity as ActivityApiType } from '@datavillage-me/api';
import * as React from 'react';
import { FullDateWithHour } from '../../../components/datatypes/date';
import ActivityLogIcon from '../components/ActivityLogIcon';
import {
  Activity,
  ActivityIconWrapper,
  ActivityMessageStyle,
  ActivityType,
  TypeAndDateWrapper,
} from './ActivityLog.styled';
import ActivityMessage from './ActivityMessage';

type Props = {
  activity: ActivityApiType;
  hideType?: boolean;
};

const ActivityItem = ({ activity, hideType }: Props) => {
  return (
    <Activity>
      <ActivityIconWrapper>
        <ActivityLogIcon activityType={activity.type} />
      </ActivityIconWrapper>
      <TypeAndDateWrapper>
        {hideType ? null : <ActivityType>{activity.type}</ActivityType>}
        <FullDateWithHour timestamp={activity.timestamp} />
      </TypeAndDateWrapper>
      <ActivityMessageStyle>
        <ActivityMessage activity={activity} />
      </ActivityMessageStyle>
    </Activity>
  );
};

export default ActivityItem;
