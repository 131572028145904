import { useContext, useEffect } from 'react';
import { useQuery } from '../utils';
import { PassportAppContext } from '../modules/passport-app/context/PassportContext';

export const usePassportQueryParams = () => {
  const passportContext = useContext(PassportAppContext);
  const uQ = useQuery();

  useEffect(() => {
    passportContext.updateCtx({ passportConfig: { clientId: uQ.clientId, applicationId: uQ.applicationId } });
  }, [uQ.clientId, uQ.applicationId]);
};
