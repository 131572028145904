import styled from 'styled-components';

export const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Breadcrumb = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export const Divider = styled.div`
  margin: 0 10px;
`;

export const Folder = styled.td`
  cursor: pointer;
  text-decoration: underline;
  display: flex;
`;

export const File = styled.td`
  cursor: pointer;
  text-decoration: underline;
  display: flex;
`;

export const Location = styled.td`
  cursor: pointer;
  position: relative;
`;

export const Icon = styled.img`
  &.margin-x {
    margin-right: 10px;
  }
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
