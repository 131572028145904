import { useRemoteClient } from '../app/components/AppContext';
import { PromiseFnContainer } from '@datavillage-me/dv-common-ui';
import * as React from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router';
import { CodeEditor, guessMode } from '../../utils/code-editor';
import { useState } from 'react';
import { ColumnFlexDiv, Tab, Tabs } from './styles';

export function AdminJobsRoute(props: any) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:jobId`}>
        <AdminJobDetails />
      </Route>
      <Route path={`${path}`}>
        <AdminJobsList />
      </Route>
    </Switch>
  );
}

export const AdminJobsList = () => {
  const client = useRemoteClient();
  const { path } = useRouteMatch();

  return (
    <ColumnFlexDiv>
      <h2>Jobs</h2>
      <PromiseFnContainer promiseFn={() => client.getJobsService().getJobs({})} deps={[]}>
        {(jobs) => (
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>Type</td>
                <td>Created</td>
                <td>Started</td>
                <td>Duration</td>
                <td>Status</td>
                <td>Message</td>
              </tr>
              {jobs
                .sort((job1, job2) => (job1.creationTime < job2.creationTime ? 1 : -1))
                .map((job) => (
                  <tr key={job.id}>
                    <td>
                      <Link to={`${path}/${job.id}`}>{job.id}</Link>
                    </td>
                    <td>{job.request.type}</td>
                    <td>{job.creationTime && new Date(job.creationTime).toLocaleString()}</td>
                    <td>{job.startTime && new Date(job.startTime).toLocaleString()}</td>
                    <td>{(job.endTime && job.startTime && job.endTime - job.startTime) || null}</td>
                    <td>{job.status}</td>
                    <td>{job.message}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};

export const AdminJobDetails = () => {
  const client = useRemoteClient();
  const { params } = useRouteMatch<{ jobId: string }>();

  const [activeTab, setActiveTab] = useState(0);

  return (
    <ColumnFlexDiv>
      <h2>Job {params.jobId}</h2>
      <PromiseFnContainer promiseFn={() => client.getJobsService().getJob(params.jobId)} deps={[params.jobId]}>
        {(job) => (
          <>
            <div>
              <Tabs>
                <Tab className={`left ${activeTab == 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
                  Job Details
                </Tab>
                <Tab className={`right ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                  Request
                </Tab>
                <Tab className={`right ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
                  Result
                </Tab>
              </Tabs>
            </div>

            {activeTab == 0 ? (
              <table>
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{job.id}</td>
                  </tr>
                  <tr>
                    <th>User</th>
                    <td>{job.userId}</td>
                  </tr>
                  <tr>
                    <th>Consent scope</th>
                    <td>{job.consentId}</td>
                  </tr>
                  <tr>
                    <th>Created on</th>
                    <td>{job.creationTime && new Date(job.creationTime).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Started on</th>
                    <td>{job.startTime && new Date(job.startTime).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Duration</th>
                    <td>{(job.endTime && job.startTime && job.endTime - job.startTime) || null}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{job.status}</td>
                  </tr>
                  <tr>
                    <th>Message</th>
                    <td>{job.message}</td>
                  </tr>
                </tbody>
              </table>
            ) : activeTab == 1 ? (
              <CodeEditor
                code={JSON.stringify(job.request, undefined, 4)}
                language={guessMode(undefined, 'application/json')}
                onCodeChange={(value) => {}}
              />
            ) : activeTab == 2 ? (
              <CodeEditor
                code={JSON.stringify(job.result, undefined, 4)}
                language={guessMode(undefined, 'application/json')}
                onCodeChange={(value) => {}}
              />
            ) : null}
          </>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};
