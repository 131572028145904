import styled from 'styled-components';
import { palette } from '../../config/theme/themeFiles';

export const Tabs = styled.div`
  box-shadow: 0 0 8px 7px rgb(0 0 0 / 4%);
  display: inline-block;
  border-radius: 10px;
  margin: 5px 0;
`;

export const Tab = styled.button`
  background: white;
  border: none;
  padding: 10px 16px;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.active {
    background: ${palette.primary.shade5};
    color: ${palette.neutral.shade1};
  }
`;

export const ColumnFlexDiv = styled.div`
  display: flex;
  flex-direction: column;

  // make sure the flex element does not exceed its allowed size
  // cf https://stackoverflow.com/questions/41674979/flex-child-is-growing-out-of-parent
  flex: 1;
  min-height: 0;

  /*
  & > div {
    flex: 1;
    min-height: 0;
  }
 */
`;
