import NoServices from 'assets/illustrations/no_result.svg';
import { EmptyStateImage, EmptyStateWrapper } from 'components/emptyState/EmptyState';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const DataSourcesEmptyState = () => {
  const { t } = useTranslation(['connections']);

  return (
    <tr>
      <td colSpan={3}>
        <EmptyStateWrapper>
          <EmptyStateImage src={NoServices} />
          <p>{t('dataSources.overview.noDataSourcesFound')}</p>
        </EmptyStateWrapper>
      </td>
    </tr>
  );
};

export default DataSourcesEmptyState;
