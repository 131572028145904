import styled from 'styled-components';
import { palette } from '../../config/theme/themeFiles';

export const TableHeader = styled.th`
  color: ${palette.neutral.shade3};
  font-weight: 500;
  letter-spacing: 0.5px;
  border-bottom: 1px solid ${palette.neutral.shade2};
  padding: 20px 5px;
`;

export const StyledTable = styled.table<{ noHover?: boolean }>`
  width: 100%;
  padding: 2em;
  border-collapse: collapse;

  tr:last-child {
    border-bottom: none;
  }
  td {
    padding: 20px 5px;
  }
  .stretch {
    width: 99%;
  }
`;

export const TableRow = styled.tr<{ noHover?: boolean; noLine?: boolean }>`
   border-bottom: ${(props) => (props.noLine ? 'none' : '1px solid ${palette.neutral.shade2}')};
  border-spacing: 0 15px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: ${(props) => (props.noHover ? 'default' : 'pointer')};
    box-shadow: ${(props) => (props.noHover ? 'none' : '0px 0px 20px 0px rgba(0, 0, 0, 0.10);')};
  }
`;

export const Checkbox = styled.input`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  cursor: pointer;
`;

export const FlexTableData = styled.td`
  display: flex;
  justify-content: flex-start;
  > span {
    min-width: 120px;
  }
  > div {
    margin-top: -11px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;
