import * as React from 'react';

const FeedbackForm = () => (
  <React.Fragment>
    <div
      data-tf-sidetab="nryYHDBa"
      data-tf-width="320"
      data-tf-height="400"
      data-tf-custom-icon="https://images.typeform.com/images/2PQhPgTN5kct"
      data-tf-button-color="#00FFFE"
      data-tf-button-text="Give feedback"
      style={{ all: 'unset' }}
    ></div>
    <script src="//embed.typeform.com/next/embed.js"></script>
  </React.Fragment>
);

export default FeedbackForm;
