import styled from 'config/theme/styled';
import { palette } from 'config/theme/themeFiles';

const NavButton = styled.button`
  border-radius: 10px;
  border: none;
  background: none;
  color: ${palette.primary.shade7};
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0 10px;
  text-align: center;
  transition: 0.2s all;
  margin: 26px 0 20px 14px;
  width: 215px;
  text-align: left;
  cursor: pointer;
  min-height: 50px;
  transition: all 0.2s ease-in-out;
  svg {
    stroke: ${palette.primary.shade7};
  }

  &:hover {
    background: ${palette.secondary.shade8};
    color: ${palette.primary.shade4};
    svg {
      stroke: ${palette.primary.shade4};
      transition: all 0.2s ease-in-out;
    }
    box-shadow: 0px 4px 20px 2px #101f3c17;
    transition: all 0.2s ease-in-out;
  }
`;

export default NavButton;
