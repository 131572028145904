import React from 'react';
import { DataPodDetails } from 'modules/data-pod-details';
import { useParams } from "react-router-dom";

const MyData: React.FC = () => {
  const urlParams = useParams<{ resourcePath?: string }>();

  return (
    <>
      <DataPodDetails path={urlParams.resourcePath}/>
    </>
  );
};

export default MyData;
