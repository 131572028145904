import { Client } from '@datavillage-me/api';
import { UpdatableContextType } from '../../../utils/utils';
import { AppContextType } from '../type';

export const fetchApplications = (context: UpdatableContextType<AppContextType>, client: Client) => {
  context.updateCtx({ applications: { loading: true } });
  if (context.currentUser?.id) {
    client
      .getCollaborationSpacesServices()
      .getAllForUser(context.currentUser?.id)
      .then((applications) => {
        context.updateCtx({ applications: { loading: false, data: applications } });
      })
      .catch((error) => {
        context.updateCtx({ applications: { loading: false, error: error } });
      });
  } else {
    context.updateCtx({ applications: { loading: false, error: 'no current user in context' } });
  }
};
