import styled from 'styled-components';
import { palette } from 'config/theme/themeFiles';

export const NavLinkTitle = styled.span`
  padding-left: 10px;
`;

export const NavButtonTitle = styled.span`
  padding-left: 10px;
`;

export const NavLogo = styled.img`
  max-height: 50px;
  max-width: 50px;
  margin: 0 10px 0 10px;
`;

export const CurrentPodName = styled.span`
  font-size: 0.9em;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Label = styled.p`
  font-size: 0.8em;
`;

export const CurrentPodContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 150px;
  width: 100%;
  padding-left: 8px;
`;

export const CurrentInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  width: 100%;
  padding-left: 0px;
  flex-direction: column;
`;

export const ConnectionsNavLinks = styled.div`
  opacity: 0%;
  transition: all 0.7s ease-in-out;
  height: 0;
  overflow: hidden;
  pointer-events: none;

  &.current {
    transition: all 0.7s ease-in-out;
    height: inherit;
    opacity: 100%;
    pointer-events: all;
  }

  &.active {
    background: ${palette.secondary.shade8};
    color: ${palette.primary.shade4};
    svg {
      stroke: ${palette.primary.shade4};
      transition: all 0.1s ease-in-out;
    }
    border-left: 3px solid ${palette.primary.shade4};
  }
`;
