import { palette } from 'config/theme/themeFiles';
import styled, { css, keyframes } from 'styled-components';

const slide = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const ContextMenuItemWrapper = styled('div')<{
  isDisabled?: boolean;
  iconSize?: 'small' | 'regular';
  isLoading?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;

  ${(props) =>
    !props.isLoading &&
    css`
      &:hover {
        cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};
        background-color: ${palette.neutral.shade1};
      }
    `}

  > p {
    font-size: 14px;
    color: ${(props) => (props.isDisabled ? palette.neutral.shade3 : 'inherit')};
  }

  > span svg {
    height: ${(props) => (props.iconSize === 'small' ? '16px' : '24px')};
    margin-right: 15px;
    fill: ${(props) => (props.isDisabled ? palette.neutral.shade3 : 'inherit')};
  }

  ${(props) =>
    props.isLoading &&
    css`
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: ${palette.neutral.shade2};
        opacity: 0.3;
        animation: ${slide} 1.5s infinite ease-in-out;
      }
    `}
`;
