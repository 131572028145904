import { Activity as ActivityApiType } from '@datavillage-me/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationsType } from '../../app/type';
import { AppContext } from '../../app/components/AppContext';

const getApplicationLabel = (appId: string, applications?: ApplicationsType) => {
  return (applications && !applications.loading && applications?.data?.find((app) => app.id == appId)?.name) || appId;
};

type Props = {
  activity: ActivityApiType;
  applications?: ApplicationsType;
};

const ActivityMessage: React.FC<Props> = ({ activity }: Props) => {
  const { t } = useTranslation(['activityLog']);

  const applications = React.useContext(AppContext).applications;

  // for backward compatibility with old activity log that contains message but no params
  if ((activity as ActivityApiType<undefined>).message) {
    return <p>{(activity as ActivityApiType<undefined>).message}</p>;
  }

  switch (activity.type) {
    case 'ISSUE_TOKEN':
      return t('messages.issueToken', { scope: activity.params.scope });

    case 'DATASOURCE_GRANT':
      return t('datasourceGrant', { datasource: activity.params.datasource });

    case 'ADD_CONSENT':
    case 'UPDATE_CONSENT':
      return t('messages.consentSigned', { consent: getApplicationLabel(activity.params.applicationId, applications) });

    case 'REVOKE_CONSENT':
      return t('messages.consentRevoked', {
        application: getApplicationLabel(activity.params.applicationId, applications),
      });

    case 'DATA_IMPORT':
      return t('messages.dataImport', { datasource: activity.params.datasource });

    case 'DATA_PROCESSING':
      return t('messages.dataProcessing', {
        application: getApplicationLabel(activity.params.applicationId, applications),
        sources: activity.params.sources.map((source) => source.label).join(', '),
      });

    case 'INIT_POD':
      return t('messages.initPod', { podUri: activity.params.podUri });

    case 'WRITE_APP_RESULT':
      return t('messages.writeResults', { results: getApplicationLabel(activity.params.applicationId, applications) });

    default:
      return <p>{'messages.missing'}</p>;
  }
};

export default ActivityMessage;
