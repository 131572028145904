import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import GLOBAL_EN from '../../translations/en/translation.json';
import ACTIVITYLOG_EN from '../../translations/en/activityLog.json';
import CATALOG_EN from '../../translations/en/catalog.json';
import CONNECTIONS_EN from '../../translations/en/connections.json';
import DASHBOARD_EN from '../../translations/en/dashboard.json';
import DATAPODS_EN from '../../translations/en/dataPods.json';
import DATASOURCES_EN from '../../translations/en/dataSources.json';
import ERRORS_EN from '../../translations/en/errors.json';
import ONBOARDING_EN from '../../translations/en/onboarding.json';
import SERVICES_EN from '../../translations/en/services.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: GLOBAL_EN,
        activityLog: ACTIVITYLOG_EN,
        catalog: CATALOG_EN,
        connections: CONNECTIONS_EN,
        dashboard: DASHBOARD_EN,
        dataPods: DATAPODS_EN,
        dataSources: DATASOURCES_EN,
        errors: ERRORS_EN,
        onboarding: ONBOARDING_EN,
        services: SERVICES_EN,
      },
    },
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: false,
    },
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
