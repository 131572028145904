import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

import paletteTheme from '../../config/theme/themeFiles/palette';
import styled from '../../config/theme/styled';

export const Loader = () => {
  return (
    <PuffContainer>
      <PuffLoader color={paletteTheme.primary.shade5} />
    </PuffContainer>
  );
};

const PuffContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
