import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, buttonTypes, Text, textTypes } from '@datavillage-me/dv-common-ui';

import { DataProviderCard } from '../../components/dataProvider/DataProviderCard';
import { extractActionsFromMapping, isDataSourceActivated } from '../../utils/dataSources';
import { useOnProviderActivationSuccess } from '../../hooks/useOnProviderActivationSuccess';
import { Loader } from '../../components/loader/Loader';
import { PassportAppContext, Status } from '../passport-app/context/PassportContext';
import { replaceAppNameInLabel } from '../../utils/labels';
import { Footer } from '../passport-app/containers/PassportApp';
import { DataSourceMetadata } from '@datavillage-me/api';

type Props = {
  index: number;
  goToNextStep: VoidFunction;
  goToPreviousStep: VoidFunction;
  validateStep: VoidFunction;
  unvalidateStep: VoidFunction;
};

export const PassportSelectSources: React.FC<Props> = ({
  index,
  goToNextStep,
  goToPreviousStep,
  validateStep,
  unvalidateStep,
}) => {
  const passportContext = useContext(PassportAppContext);
  const { t } = useTranslation();
  useOnProviderActivationSuccess();

  const activatedDataSources = passportContext.activatedDataSources?.value;
  const dataSources = passportContext.dataSources?.value;
  const sharedDataSources = passportContext.sharedDataSources;

  const isLoading = [
    passportContext.activatedDataSources?.status,
    passportContext.dataSources?.status,
    passportContext.previousConsent?.status,
  ].some((status) => [undefined, Status.LOADING].includes(status));

  useEffect(() => {
    if (sharedDataSources && sharedDataSources.length > 0) {
      validateStep();
    } else {
      unvalidateStep();
    }
  }, [sharedDataSources]);

  useEffect(() => {
    if (sharedDataSources && sharedDataSources.length > 0) {
      validateStep();
    } else {
      unvalidateStep();
    }
  }, [sharedDataSources]);

  const activateDataProvider = (dataSource: DataSourceMetadata) => {
    passportContext.updateCtx({
      sharedDataSources: [
        ...(passportContext.sharedDataSources || []),
        {
          dataSourceId: dataSource.id,
          categories: extractActionsFromMapping(dataSource.mappings),
        },
      ],
    });
  };

  const deactivateDataProvider = (dataSource: DataSourceMetadata) => {
    const newSharedDataSources = passportContext.sharedDataSources || [];
    const indexToRemove = newSharedDataSources.findIndex((sDS) => sDS.dataSourceId === dataSource.id);
    newSharedDataSources.splice(indexToRemove, 1);
    passportContext.updateCtx({ sharedDataSources: newSharedDataSources });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Container>
      <MainContent>
        <Text type={textTypes.h4}>
          {replaceAppNameInLabel(t, 'passport.selectSources.availableSources', passportContext)}
        </Text>
        <ProvidersContainer>
          {(dataSources || []).map((dataSource, index) => (
            <DataProviderCard
              {...{
                activateDataProvider: () => activateDataProvider(dataSource),
                deactivateDataProvider: () => deactivateDataProvider(dataSource),
                key: dataSource.id + index,
                dataSource,
                activated: isDataSourceActivated(dataSource, activatedDataSources || []),
              }}
            />
          ))}
        </ProvidersContainer>
      </MainContent>
      <Footer>
        {index === 0 ? (
          <Button
            {...{
              buttonType: buttonTypes.secondary,
              onClick: () =>
                window.parent.postMessage(
                  {
                    source: 'codename',
                    content: 'closeModal',
                  },
                  '*'
                ),
              label: t('general.cancel'),
            }}
          />
        ) : (
          <Button
            {...{
              buttonType: buttonTypes.secondary,
              onClick: goToPreviousStep,
              label: t('general.previous'),
            }}
          />
        )}
        <Button
          {...{
            disabled: !! (dataSources && dataSources.length && !(sharedDataSources && sharedDataSources.length > 0)),
            buttonType: buttonTypes.primary,
            onClick: goToNextStep,
            label: t('general.continue'),
          }}
        />
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
`;

const MainContent = styled.div`
  margin: 0 auto;
`;

const ProvidersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
