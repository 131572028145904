import { Login } from 'modules/login';
import SelectProvider from 'modules/login/external-provider/SelectProvider';
import * as React from 'react';
import { Switch } from 'react-router';
import { Route, useParams } from 'react-router-dom';
import { useQuery } from '../../../utils';
import PublicRoutes from '../config/PublicRoutes';
import PrivateRoutes from '../config/PrivateRoutes';
import PrivateRoute from '../routes/PrivateRoute';

const MainRouter: React.FC = () => {
    const callbackTarget = useQuery().target;

    return (
        <Switch>
            {/* <Route exact path="/register" component={Register} /> */}
            <Route exact path='/login' component={Login} />
            {/* This route should be used as a callback when the login flow is triggered from an external window/extension .
          The 'target' query parameter can be 'opener' (default) or 'extension:<extensionId>' .
          If using the opener, it assumes the window.opener is the window waiting for a notification (see handleExternalAuth)
          If targeting an extension, the target value must contain the ID of the extension to notify
      */}
            <Route
                exact
                path='/login/callback'
                component={() => {
                    if (!callbackTarget || callbackTarget == 'opener') {
                        // Use window.postMessage to notify the original opener of this login window
                        if (!window.opener) console.warn('Original caller unknown - login status cannot be sent back to caller');
                        else {
                            window.opener.postMessage('OK', window.location.origin);
                        }
                    } else if (callbackTarget.startsWith('extension:')) {
                        if (chrome.runtime) {
                            // Notify the extension specified in the target
                            const extensionId = callbackTarget.substring('extension:'.length);
                            chrome.runtime.sendMessage(extensionId, 'AUTH_OK');
                        } else {
                            console.warn('Chrome runtime not found in scope - cannot notify ' + callbackTarget);
                        }
                    } else {
                        console.warn('Callback target unknown : ' + callbackTarget);
                    }

                    return <></>;
                }}
            />
            <Route
                exact
                path='/provider/:providerId/success'
                component={() => {
                    const urlParams = useParams<{ providerId: string }>();
                    localStorage.setItem('providerActivationSuccess', urlParams.providerId);
                    window.open('about:blank', '_self');
                    window.close();

                    return <></>;
                }}
            />

            <Route exact path='/login/external' component={SelectProvider} />

            {/* Map out public routes based on config (except SelectProviders) */}
            {PublicRoutes.map((route, i) => (
                <Route exact={route.exact} path={route.path} key={i}>
                    <route.component />
                </Route>
            ))}

            {PrivateRoutes.map((route, i) => (
                <PrivateRoute exact={route.exact} path={route.path} key={i}
                              enableExtensionCheck={route.enableExtensionCheck}>
                    <route.component />
                </PrivateRoute>
            ))}
        </Switch>
    );
};

export default MainRouter;
