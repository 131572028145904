import { palette } from 'config/theme/themeFiles';
import styled, { css } from 'styled-components';

export const LogoHelper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const NameAndCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0;
`;

export const Name = styled.div`
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  text-transform: capitalize;
`;

export const DataSources = styled.div`
  color: ${palette.neutral.shade9};
  font-weight: 500;
  text-align: left;
`;

export const Logo = styled.img`
  margin-right: 20px;
  max-width: 80px;
  min-width: 30px;
  max-height: 30px;
`;

export const StateContainer = styled.div`
  min-width: 180px;
  display: inline-block;
`;

const Status = styled.span<{ disabled?: boolean }>`
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: 600;
  z-index: 99;
`;

export const Connected = styled(Status)`
  background: ${palette.success.shade1};
  color: ${palette.success.shade4};
  transition: all 0.2s ease-in-out;
`;

export const Disconnected = styled(Status)`
  background: ${palette.primary.shade1};
  color: ${palette.secondary.shade8};
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.disabled
      ? css`
          background: ${palette.neutral.shade2};
          color: ${palette.neutral.shade4};
        `
      : css`
          &:hover {
            background: ${palette.primary.shade7};
            color: ${palette.primary.shade1};
            cursor: pointer;
          }
        `}
`;
