import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const DataImport: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M12 8H2V4H10L12 8Z"
        ></path>
        <path fill="none" d="M13 20H2V8H12H22V16" clipRule="evenodd" fillRule="evenodd"></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M13 20H2V8H12H22V16"
        ></path>
        <path
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M16 20H22"
        ></path>
        <path
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M19 17V23"
        ></path>
      </svg>
    </BaseSvgWrapper>
  );
};

export default DataImport;
