import { palette } from 'config/theme/themeFiles';
import { mediaSize } from 'config/theme/themeFiles/MediaSizes';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode[] | React.ReactNode | null;
  heading?: string;
  subHeading?: string;
};

export const Section = styled('section')<Props>`
  background-color: ${palette.neutral.shade1};
  border-radius: 12px;
  box-shadow: 2px 6px 34px rgba(0, 0, 0, 0.08), 1px 3px 6px rgba(0, 0, 0, 0.04);
  margin: 25px;
  padding: 35px;

  ${() => mediaSize.tablet`
    overflow: auto;
    margin: 12px;
    padding: 24px;
  `};

  ${() => mediaSize.laptopSM`
    margin: 16px;
    padding: 24px;
  `}

  ${() => mediaSize.desktop`
    max-width: 1600px;
  `};
`;

export const PaperHeader = styled.h2`
  margin: 15px 0px 8px 25px;
  font-size: 2em;
`;

export const PaperSubHeading = styled.p`
  margin: 0px 0px 30px 25px;
  max-width: 800px;
  color: ${palette.neutral.shade6};
  white-space: pre-line;
  line-height: 160%;
  font-size: 18px;
`;

const Paper: React.FC<Props> = (props: Props) => (
  <React.Fragment>
    {props.heading && <PaperHeader>{props.heading}</PaperHeader>}
    {props.subHeading && <PaperSubHeading>{props.subHeading}</PaperSubHeading>}
    <Section {...props}>{props.children}</Section>
  </React.Fragment>
);

export default Paper;
