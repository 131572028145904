import { useEffect } from 'react';

import { useQuery } from '../utils';

export const useSetAppInLocalStorage = () => {
  const { appId } = useQuery();
  useEffect(() => {
    localStorage.setItem('appId', appId);
  }, [appId]);
};
