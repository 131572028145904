import { useState, useEffect } from 'react';

export const useOnScroll = () => {
  const [scrollValue, setScrollValue] = useState<number>(0);

  useEffect(() => {
    const index = window.document.getElementById('index');
    const updateScrollValue = () => {
      index && setScrollValue(index.scrollTop);
    };

    index && index.addEventListener('scroll', updateScrollValue);

    return () => {
      index && index.removeEventListener('scroll', updateScrollValue);
    };
  }, []);

  return scrollValue;
};
