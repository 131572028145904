import React from 'react';
import styled from 'styled-components';
import NotFoundImage from 'assets/illustrations/not_found.svg';

const Page404: React.FC = () => {
  return (
    <Container>
      <Soon>This page does not exist</Soon>
      <Image src={NotFoundImage} />
    </Container>
  );
};

export default Page404;

const Soon = styled.h1`
  align-self: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Image = styled.img`
  max-height: 60vh;
  align-self: center;
`;
