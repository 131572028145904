import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from 'components/Skeleton';

const DataSourcesLoadingState: React.FC = () => (
  <tr>
    <td colSpan={4}>
      <section style={{ width: '100%' }}>
        <SkeletonGenerator count={4}>
          <ContentLoader width={800} height={65}>
            <rect x="0" y="0" rx="0" ry="0" width="600" height="50" />
          </ContentLoader>
        </SkeletonGenerator>
      </section>
    </td>
  </tr>
);

export default DataSourcesLoadingState;
