import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../components/table/Table';
import styled from '../../config/theme/styled';
import Logo from 'assets/images/Wallet_Logo_Dark.png';
import LogoWallet from 'assets/images/Wallet_Only.png';
import CheckMarkCircle from '../../assets/icons/CheckMarkCircle';
import { palette } from '../../config/theme/themeFiles';
import UnCheckMarkCircle from '../../assets/icons/UnCheckMarkCircle';
import LimitedCircle from '../../assets/icons/LimitedCircle';
import { mediaSize } from '../../config/theme/themeFiles/MediaSizes';

export const LandingComparison: React.FC = () => {
  const { t } = useTranslation();
  const comparisonPoints: { title: string; dv?: boolean; noDv?: boolean }[] = t('landing.comparison.points', {
    returnObjects: true,
  });

  const getLogo = (val?: boolean) => {
    switch (val) {
      case true: {
        return <CheckMarkCircle color={palette.primary.shade7} circleColor={palette.primary.shade1} />;
      }
      case false: {
        return <UnCheckMarkCircle color={palette.danger.shade5} />;
      }
      default: {
        return <LimitedCircle color={palette.warning.shade8} />;
      }
    }
  };

  return (
    <SectionContainer>
      <TableContainer>
        <Table
          withCheckBox={false}
          tableHeaders={[
            { title: '', className: 'mainCol' },
            {
              title: (
                <>
                  <DvImg src={Logo} />
                  <DvImgMobile src={LogoWallet} />
                </>
              ),
              className: 'secondaryCol',
            },
            { title: t('landing.comparison.withoutWallet'), className: 'dvCol' },
          ]}
        >
          {comparisonPoints.map((point) => (
            <tr key={point.title}>
              <td>
                <p>{point.title}</p>
              </td>
              <LogoTd bigger={point.dv}>{getLogo(point.dv)}</LogoTd>
              <LogoTd>{getLogo(point.noDv)}</LogoTd>
            </tr>
          ))}
        </Table>
      </TableContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  background-color: white;
  margin-top: 50px;
`;

const TableContainer = styled.div`
  margin: auto;

  .mainCol {
    width: 50%;

    ${mediaSize.mobile`
        width: 40%;
    `}
  }

  .secondaryCol {
    width: 25%;
    ${mediaSize.mobile`
  width: 25%;
    `}
  }

  .dvCol {
    width: 25%;
    ${mediaSize.mobile`
  width: 35%;
    `}
  }
`;

const DvImg = styled.img`
  width: 200px;
  ${mediaSize.mobile`
    display: none
    `}
`;

const DvImgMobile = styled.img`
  display: none;
  ${mediaSize.mobile`
  display: inherit;
  width: 100%;
    `}
`;

const LogoTd = styled.td<{ bigger?: boolean }>`
  text-align: center;

  svg {
    width: ${(props) => (props.bigger ? '50px' : '35px')};
    height: ${(props) => (props.bigger ? '50px' : '35px')};
  }
`;
