import useToggle from 'hooks/useToggle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AppContext, useRemoteClient } from '../../../app/components/AppContext';
import { Service } from '../types';

const useServicesStore = () => {
  const { t } = useTranslation(['errors']);
  const remoteClient = useRemoteClient();
  const userId = React.useContext(AppContext).currentUser?.id;

  const [services, setServices] = React.useState<Service[] | null>(null);
  const [isFetchServicesPending, setIsFetchServicePending] = useToggle(true);

  const [servicesDetail, setServicesDetail] = React.useState<Service | null>(null);
  const [isFetchServiceDetailPending, setIsFetchServiceDetailPending] = useToggle(true);

  const [isDisconnectServicePending, setIsDisconnectServicePending] = React.useState<string | false>(false);

  const fetchServices = React.useCallback(() => {
    if (userId) {
      setIsFetchServicePending(true);
      remoteClient
        .getCollaborationSpacesServices()
        .getAllForUser(userId)
        .then((result) => {
          setServices(result);
        })
        .finally(() => {
          setIsFetchServicePending(false);
        })
        .catch(() => {
          toast.error(t('services.servicesFetch'));
        });
    } else {
      toast.error(t('authentication.notLoggedIn'));
    }
  }, [remoteClient, setIsFetchServicePending, t, userId]);

  const disconnectService = React.useCallback(
    (consentId: string, successCallback?: VoidFunction) => {
      if (userId) {
        setIsDisconnectServicePending(consentId);
        remoteClient
          .getConsentsServices()
          .withdrawConsent(consentId)
          .then(() => {
            toast.success(t('services.servicesDisconnect_success'));
            successCallback?.();
          })
          .finally(() => {
            setIsDisconnectServicePending(false);
          })
          .catch(() => {
            toast.error(t('services.servicesDisconnect'));
          });
      } else {
        toast.error(t('authentication.notLoggedIn'));
      }
    },
    [remoteClient, setIsDisconnectServicePending, t, userId]
  );

  // Currently this is the same call as the main services one. But it's here for future use, when there is a seperate detail call.
  const fetchServiceDetail = React.useCallback(
    (serviceName: string) => {
      if (userId) {
        setIsFetchServiceDetailPending(true);
        remoteClient
          .getCollaborationSpacesServices()
          .getAllForUser(userId)
          .then((result) => {
            const serviceDetail = result.find((service) => service.name === serviceName);
            serviceDetail && setServicesDetail(serviceDetail);
          })
          .finally(() => {
            setIsFetchServiceDetailPending(false);
          })
          .catch(() => {
            toast.error(t('services.servicesFetch'));
          });
      } else {
        toast.error(t('authentication.notLoggedIn'));
      }
    },
    [remoteClient, setIsFetchServiceDetailPending, t, userId]
  );

  return {
    disconnectService,
    fetchServiceDetail,
    fetchServices,
    isDisconnectServicePending,
    isFetchServiceDetailPending,
    isFetchServicesPending,
    services,
    servicesDetail,
  };
};

export default useServicesStore;
