import { RevokeConsent } from 'assets/icons';
import IconButton from 'components/button/IconButton';
import { Confirmation } from 'components/confirmationModal';
import { DataSourceTile } from 'components/data-source';
import { Flexer, Spacer } from 'components/interface';
import BackButton from 'components/nav-links/BackButton';
import Paper from 'components/paper/Paper';
import Tooltip from 'components/tooltip';
import { useExtension } from 'hooks/useExtension';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { logoFactory } from 'utils/images';
import useServicesStore from '../hooks/useServicesStore';
import LoadingState from './LoadingState';
import {
  DataSources,
  DataSourcesContent,
  DataSourcesHeader,
  Description,
  DetailConnected,
  DetailDisconnected,
  DetailHeader,
  HeaderContent,
  HeaderImageAndCategory,
  Link,
  Properties,
  Property,
  ServiceName,
  SubTitle,
  WebsiteAndPurpose,
} from './ServiceDetail.styled';
import { Logo } from "../overview/Services.styled";

type Props = {
  isCatalogService?: boolean;
};

const ServiceDetails: React.FC<Props> = ({ isCatalogService }: Props) => {
  const { t } = useTranslation(['services', 'connections']);
  const hasNoExtension = useExtension() === undefined;
  const urlParams = useParams<{ service: string }>();

  const {
    disconnectService,
    fetchServiceDetail,
    isDisconnectServicePending,
    isFetchServiceDetailPending,
    servicesDetail,
  } = useServicesStore();

  const getServices = React.useCallback(() => {
    fetchServiceDetail(urlParams.service);
  }, [urlParams]);

  useEffect(() => {
    getServices();
  }, []);

  const handleDisconnectService = React.useCallback(
    (serviceId, event: React.MouseEvent) => {
      event.stopPropagation();
      disconnectService(serviceId, () => window.location.reload());
    },
    [disconnectService]
  );

  const handleConnectService = React.useCallback((serviceUrl, event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(serviceUrl + '?startPassport=true', '_blank');
  }, []);

  return (
    <React.Fragment>
      <BackButton />
      <Paper>
        {isFetchServiceDetailPending && <LoadingState />}
        {!isFetchServiceDetailPending && servicesDetail && (
          <React.Fragment>
            <DetailHeader>
              <HeaderContent>
                <ServiceName>{servicesDetail.name}</ServiceName>
                <Description>{servicesDetail.description}</Description>
                {servicesDetail.userData?.applicationActivated ? (
                  <React.Fragment>
                    <Spacer top />
                    <Flexer align="center">
                      <DetailConnected>{t('connections:table.connected')}</DetailConnected>
                      <Spacer right />
                      <Confirmation
                        variant="delete"
                        orientation="bottom-right"
                        callback={(e) => handleDisconnectService(servicesDetail?.userData?.activeConsentId, e)}
                        customConfirmText={t('services:actions.confirmDisconnect')}
                        customDescription={t('services:actions.confirmDescription')}
                      >
                        <IconButton
                          tooltip={t('services:actions.disconnect')}
                          tooltipPosition="bottom"
                          isLoading={Boolean(isDisconnectServicePending)}
                          isDisabled={Boolean(isDisconnectServicePending) || !servicesDetail?.id}
                        >
                          <RevokeConsent />
                        </IconButton>
                      </Confirmation>
                    </Flexer>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Spacer top />
                    <Tooltip
                      content={hasNoExtension ? t('services:actions.disabled.connect') : t('services:actions.connect')}
                    >
                      <DetailDisconnected
                        disabled={hasNoExtension}
                        onClick={(e) => handleConnectService(servicesDetail.appSettings.appUrl, e)}
                      >
                        {t('connections:table.clickToConnect')}
                      </DetailDisconnected>
                    </Tooltip>
                  </React.Fragment>
                )}
              </HeaderContent>
              <HeaderImageAndCategory>
                <Logo src={servicesDetail.appSettings.logoUrl || logoFactory(servicesDetail.name)} alt="logo" />
                <span>{servicesDetail.category}</span>
              </HeaderImageAndCategory>
            </DetailHeader>
            <Properties>
              <WebsiteAndPurpose>
                <SubTitle>{t('services:detail.website')}</SubTitle>
                <Property>
                  <Link href={servicesDetail.appSettings.appUrl} target="_blank" rel="noopener noreferrer">
                    {servicesDetail.appSettings.appUrl}
                  </Link>
                </Property>
                <SubTitle>{t('services:detail.purposeOfUse')}</SubTitle>
                <Property>{servicesDetail.purpose}</Property>
              </WebsiteAndPurpose>
            </Properties>
            <DataSources>
              <DataSourcesHeader>
                {isCatalogService
                  ? t('services:detail.recommendedDataSources')
                  : t('services:detail.activatedDataSources')}
              </DataSourcesHeader>
              <DataSourcesContent>
                {isCatalogService
                  ? servicesDetail?.recommendedProviders?.map((dataSource: string, index) => (
                      <DataSourceTile key={index} name={dataSource} logo={logoFactory(dataSource)} />
                    ))
                  : servicesDetail?.userData?.activatedProviders?.map((dataSource: string, index) => (
                      <DataSourceTile key={index} name={dataSource} logo={logoFactory(dataSource)} enabled={true} />
                    ))}
              </DataSourcesContent>
            </DataSources>
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default ServiceDetails;
