import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const Connections: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 16.4929V7.63852C20.9996 7.25034 20.8967 6.86909 20.7017 6.53301C20.5067 6.19693 20.2264 5.91785 19.8889 5.72376L12.1111 1.29657C11.7733 1.10228 11.3901 1 11 1C10.6099 1 10.2267 1.10228 9.88889 1.29657L2.11111 5.72376C1.77363 5.91785 1.49331 6.19693 1.29829 6.53301C1.10327 6.86909 1.0004 7.25034 1 7.63852V16.4929C1.0004 16.8811 1.10327 17.2623 1.29829 17.5984C1.49331 17.9345 1.77363 18.2136 2.11111 18.4077L9.88889 22.8349C10.2267 23.0292 10.6099 23.1314 11 23.1314C11.3901 23.1314 11.7733 23.0292 12.1111 22.8349L19.8889 18.4077C20.2264 18.2136 20.5067 17.9345 20.7017 17.5984C20.8967 17.2623 20.9996 16.8811 21 16.4929Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6 3.44385L11 6.32152L16 3.44385" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 20.6879V14.9436L1 12.0659" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21 12.0659L16 14.9436V20.6879" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M1.2998 6.48755L10.9998 12.0769L20.6998 6.48755"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M11 23.2224V12.0659" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </BaseSvgWrapper>
  );
};

export default Connections;
