import { DataSourceMetadata, DatasourceRecord } from '@datavillage-me/api';
import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from 'components/Skeleton';

type Props = {
  isActiveDSPending: boolean;
  filteredActiveDataSource: (sourceId: string) => DatasourceRecord[];
  dataSource: DataSourceMetadata;
};

const AuthDate: React.FC<Props> = ({ isActiveDSPending, dataSource, filteredActiveDataSource }: Props) => {
  return (
    <React.Fragment>
      {isActiveDSPending ? (
        <SkeletonGenerator count={1}>
          <ContentLoader width={120} height={20}>
            <rect x="0" y="2" rx="4" ry="4" width="100" height="16" />
          </ContentLoader>
        </SkeletonGenerator>
      ) : filteredActiveDataSource(dataSource.id).length > 0 ? (
        new Date(filteredActiveDataSource(dataSource.id)[0]?.activationDate).toLocaleDateString()
      ) : (
        '-'
      )}
    </React.Fragment>
  );
};

export default AuthDate;
