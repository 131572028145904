import { Activity as ActivityApiType } from '@datavillage-me/api';
import React from 'react';
import ActivityItem from './ActivityItem';
import ActivityLogLoadingState from './ActivityLog.loading';
import { FlexHelper } from './ActivityLog.styled';

type Props = {
  activityLog: ActivityApiType[] | null;
  isPending: boolean;
};

const ActivityLogTable = ({ activityLog, isPending }: Props) => {
  return (
    <FlexHelper>
      {isPending ? (
        <ActivityLogLoadingState />
      ) : (
        activityLog &&
        activityLog.length > 0 && (
          <>
            {activityLog.map((activity: ActivityApiType, index: number) => (
              <ActivityItem activity={activity} key={index} />
            ))}
          </>
        )
      )}
    </FlexHelper>
  );
};

export default ActivityLogTable;
