import { useLocation } from 'react-router-dom';

/**
 * A type modifier that excludes a field K from a type T
 */
export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

export function useQuery() {
  return Object.fromEntries(new URLSearchParams(useLocation().search));
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
