import styled from 'styled-components';
import { palette } from '../../config/theme/themeFiles';

export const StoreContainer = styled.div`
  padding: 46px 20px 100px 20px;
  margin-left: 50px;
  position: relative;
`;

export const Title = styled.h1`
  margin-bottom: 40px;
  font-weight: 600;
`;

export const Explanation = styled.p`
  max-width: 600px;
  white-space: break-spaces;
  line-height: 170%;
`;

export const CTAbutton = styled.button`
  padding: 10px 40px;
  border-radius: 5px;
  border: none;
  margin-right: 20px;
  font-weight: 500;
  cursor: pointer;

  background: ${palette.primary.shade1};
  &.neutral {
    background: none;
  }
  &:hover {
    background: ${palette.primary.shade4};
  }

  color: ${palette.primary.shade7};
  > a {
    color: ${palette.primary.shade7};
  }

  transition: all 0.2s ease-in-out;
`;

export const PageButton = styled.button`
  padding: 10px 26px;
  background: ${palette.primary.shade1};
  border-radius: 5px;
  border: none;
  font-weight: 500;
  color: ${palette.primary.shade7};
  cursor: pointer;

  &:disabled {
    color: ${palette.neutral.shade4};
    &:hover {
      background: ${palette.danger.shade1};
    }
  }

  &.neutral {
    background: none;
  }
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${palette.primary.shade4};
  }
`;

export const CallToActionContainer = styled.div`
  position: absolute;
  bottom: 20px;
`;
