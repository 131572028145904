import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const MyData: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={color}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.96951 0.0635717C8.74312 -0.0213228 8.49365 -0.0213228 8.26726 0.0635717L0.648999 2.92042C0.258695 3.06678 0.00012207 3.4399 0.00012207 3.85675V10.5227C0.00012207 13.826 2.18643 16.4606 4.15075 18.1794C5.07344 18.9867 5.99214 19.6318 6.71382 20.0927V17.6703C6.31305 17.3767 5.88989 17.0436 5.46776 16.6742C3.62294 15.06 2.00012 12.9331 2.00012 10.5227V4.54975L8.61839 2.0679L15.2366 4.54975V6.7136H17.2366V3.85675C17.2366 3.4399 16.9781 3.06678 16.5878 2.92042L8.96951 0.0635717Z"
        />
        <path
          d="M20.0457 20.9979V19.7281C20.0457 19.0546 19.7781 18.4087 19.3019 17.9325C18.8256 17.4563 18.1797 17.1887 17.5062 17.1887H13.0622C12.3888 17.1887 11.7428 17.4563 11.2666 17.9325C10.7904 18.4087 10.5228 19.0546 10.5228 19.7281V20.9979"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8083 14.3317C16.1231 14.3317 17.189 13.2658 17.189 11.951C17.189 10.6362 16.1231 9.57028 14.8083 9.57028C13.4934 9.57028 12.4276 10.6362 12.4276 11.951C12.4276 13.2658 13.4934 14.3317 14.8083 14.3317Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </BaseSvgWrapper>
  );
};

export default MyData;
