import { getMasterPod, PodDescriptor, Resource, RESOURCE_TYPE } from '@datavillage-me/api';
import useToggle from 'hooks/useToggle';
import { AppContext, useRemoteClient } from 'modules/app/components/AppContext';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const useDataPodDetailsStore = () => {
  const { t } = useTranslation(['errors']);
  const remoteClient = useRemoteClient();
  const context = useContext(AppContext);
  const currentUser = context.currentUser;
  const userId = context.currentUser?.id;
  const [dataPodDetails, setDataPodDetails] = useState<Resource<RESOURCE_TYPE> | null>(null);
  const [masterPod, setMasterPod] = useState({} as PodDescriptor);
  const [isPending, setIsPending] = useToggle(true);

  const fetchMasterPod = useCallback(() => {
    setIsPending(true);
    currentUser && setMasterPod(getMasterPod(currentUser));
    setIsPending(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const fetchDataPodDetail = useCallback(
    (podId: string) => {
      if (userId) {
        setIsPending(true);
        remoteClient
          .getUsersServices()
          .getPodInstance(userId, podId)
          .getResource('')
          .then((result) => {
            setDataPodDetails(result);
          })
          .finally(() => {
            setIsPending(false);
          })
          .catch(() => {
            toast.error(t('dataPods.dataPods_fetch'));
          });
      } else {
        toast.error(t('authentication.notLoggedIn'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsPending]
  );

  return {
    isPending,
    fetchDataPodDetail,
    fetchMasterPod,
    dataPodDetails,
    masterPod,
  };
};

export default useDataPodDetailsStore;
