import { Refresh, RevokeConsent } from 'assets/icons';
import IconButton from 'components/button/IconButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  refreshDataSource: (providerKey: string) => void;
  revokeDataSource: (providerKey: string) => void;
  selectedDataSourceIds: string[];
  isRevokeDSPending: boolean;
};

const DataSourcesHeader: React.FC<Props> = ({
  selectedDataSourceIds,
  refreshDataSource,
  revokeDataSource,
  isRevokeDSPending,
}: Props) => {
  const { t } = useTranslation(['dataSources']);

  const OneOrMoreSelected = selectedDataSourceIds.length >= 1;

  const handleMultiRevoke = () => {
    OneOrMoreSelected && selectedDataSourceIds.forEach((providerKey) => revokeDataSource(providerKey));
  };
  const handleMultiRefresh = () => {
    OneOrMoreSelected && selectedDataSourceIds.forEach((providerKey) => refreshDataSource(providerKey));
  };

  return (
    <FlexHelper>
      <IconButton
        onClick={handleMultiRevoke}
        isLoading={isRevokeDSPending}
        tooltip={t('actions.revoke')}
        tooltipPosition="bottom"
        isDisabled={!OneOrMoreSelected}
        disabledTooltip={t('actions.disabled.noIdsSelected')}
      >
        <RevokeConsent />
      </IconButton>
      <IconButton
        onClick={handleMultiRefresh}
        tooltip={t('actions.refresh')}
        tooltipPosition="bottom"
        isDisabled={!OneOrMoreSelected}
        disabledTooltip={t('actions.disabled.noIdsSelected')}
      >
        <Refresh />
      </IconButton>
    </FlexHelper>
  );
};

export default DataSourcesHeader;

const FlexHelper = styled.div`
  display: flex;
  flex-direction: row;
`;
