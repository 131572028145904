import { ContentLoader, SkeletonGenerator } from 'components/Skeleton';
import { TableRow } from 'components/table/Table.styles';
import * as React from 'react';

type Props = {
  topSpacing?: boolean;
};

const StoredDataLoadingState = ({ topSpacing }: Props) => (
  <TableRow noHover>
    <td colSpan={4}>
      <section style={{ maxWidth: 800, margin: topSpacing ? '40px' : 0 }}>
        <SkeletonGenerator count={1}>
          <ContentLoader width={800} height={80}>
            <rect x="0" y="0" rx="10" ry="10" width="200" height="20" />
            <rect x="400" y="0" rx="10" ry="10" width="120" height="20" />
            <rect x="0" y="30" rx="10" ry="10" width="180" height="20" />
            <rect x="300" y="30" rx="10" ry="10" width="280" height="20" />
            <rect x="0" y="60" rx="10" ry="10" width="200" height="20" />
            <rect x="350" y="60" rx="10" ry="10" width="200" height="20" />
          </ContentLoader>
        </SkeletonGenerator>
      </section>
    </td>
  </TableRow>
);

export default StoredDataLoadingState;
