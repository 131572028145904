/* eslint-disable react/prop-types */
import { animations } from 'config/theme/keyframes';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { palette } from '../../config/theme/themeFiles';
import Tooltip from '../tooltip';

type Props = {
  isDisabled?: boolean;
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  isLoading?: boolean;
  positionAbsolute?: boolean;
  disabledTooltip?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

const IconButton = ({
  children,
  tooltip,
  positionAbsolute,
  onClick,
  disabledTooltip,
  isDisabled,
  tooltipPosition,
  ...other
}: Props) => {
  return (
    <Tooltip
      content={isDisabled ? disabledTooltip : tooltip}
      positionAbsolute={positionAbsolute}
      isActive={Boolean(tooltip)}
      position={tooltipPosition}
    >
      <StyledIconButton {...other} isDisabled={isDisabled} type="button" onClick={!isDisabled ? onClick : undefined}>
        {children}
      </StyledIconButton>
    </Tooltip>
  );
};

const StyledIconButton = styled('button')<Props>`
  align-items: center;
  background: transparent;
  border-radius: 50%;
  border: none;
  color: ${palette.neutral.shade4};
  cursor: pointer;
  height: 42px;
  display: flex;
  justify-content: space-around;
  margin: 0 2px;
  padding: 0;
  transition: all 0.25s ease-out;
  position: relative;
  width: 42px;
  ${(props) => props.isDisabled && 'cursor: not-allowed'};

  &:hover {
    color: ${palette.neutral.shade1};
    background: ${(props) => (props.isDisabled ? 'transparent' : palette.primary.shade1)};
    box-shadow: ${(props) => (props.isDisabled ? '' : '0px 2px 8px rgba(20, 130, 130, 0.4)')};
  }

  > span > svg,
  > span > span > svg {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    stroke: ${(props) => (props.isDisabled ? palette.neutral.shade4 : palette.neutral.shade9)};
    transition: all 0.25s ease-out;
  }

  ${(props) =>
    props.isLoading &&
    css`
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -2px;
        bottom: -2px;
        right: -2px;
        left: -2px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-right: 2px solid ${palette.neutral.shade3};
        opacity: 0;
        animation: ${animations.rotation} 1.5s ease-in-out infinite, ${animations.fadeIn} 0.3s ease-in forwards;
      }
    `}
`;

export default IconButton;
