import ClockImg from 'assets/icons/clock.svg';
import FolderImg from 'assets/icons/folder.svg';
import styled from 'config/theme/styled';
import React from 'react';
import { palette } from '../../config/theme/themeFiles';
// import BrandIcon from './BrandIcon';
import StyledDataSource from './DataSource.styled';

type Props = {
  folderName: string;
  lastUsedDate: string;
  type: string;
};

const DataSource: React.FC<Props> = ({ type, folderName, lastUsedDate }: Props) => {
  return (
    <StyledDataSource>
      <Header>
        <h2>
          {/* <BrandIcon brand={type} /> */}
          {type}
        </h2>
      </Header>
      <Folders>
        <Folder>
          <StyledIcon src={FolderImg} alt="folder" />
          {folderName}
        </Folder>
      </Folders>
      <DateWrapper>
        <StyledIcon src={ClockImg} alt="clock" />
        {new Date(lastUsedDate).toLocaleString()}
      </DateWrapper>
    </StyledDataSource>
  );
};

export default DataSource;

const Header = styled.div``;

const Folders = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const DateWrapper = styled.div`
  background: ${palette.purple.shade2};
  padding: 7px 10px;
  border-radius: 5px;
  font-size: 0.8em;
`;
const Folder = styled.p`
  background: ${palette.blue.shade2};
  border-radius: 5px;
  padding: 7px 10px;
  margin: 0 0 5px 0;
  font-size: 0.8em;
  width: 100%;
`;

const StyledIcon = styled.img`
  height: 20px;
  margin-right: 10px;
  padding: 3px;
  border-radius: 3px;
  vertical-align: middle;
`;
