import * as React from 'react';
import { AppContext } from '../../app/components/AppContext';

type Props = {
  appId: string;
};

const ApplicationLabel = ({ appId }: Props) => {
  const { applications } = React.useContext(AppContext);

  const activeApp = applications.data && applications.data.find((app) => app.id === appId);

  return <>{activeApp && activeApp.appSettings? <a href={activeApp.appSettings.appUrl}>{activeApp.name}</a> : appId}</>;
};

export default ApplicationLabel;
