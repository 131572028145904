import { MASTER_POD_ALIAS } from '@datavillage-me/api';
import useToggle from 'hooks/useToggle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AppContext, useRemoteClient } from '../../../app/components/AppContext';

const useDataSourceActions = () => {
  const { t } = useTranslation(['errors', 'dataSources']);
  const remoteClient = useRemoteClient();
  const userId = React.useContext(AppContext).currentUser?.id;

  const [isRevokeDSPending, setIsRevokeDSPending] = useToggle(false);

  const revokeDataSource = React.useCallback(
    (sourceId: string) => {
      if (userId) {
        setIsRevokeDSPending(true);

        remoteClient
          .getUsersServices()
          .getPodInstance(userId, MASTER_POD_ALIAS)
          .revokeDatasource(sourceId)
          .then(() => {
            toast.success(t('dataSources:actions.revoke_success'));
          })
          .finally(() => {
            setIsRevokeDSPending(false);
          })
          .catch(() => {
            toast.error(t('errors:dataSources.dataSources_revoke'));
          });
      } else {
        toast.error(t('errors:authentication.notLoggedIn'));
      }
    },
    [remoteClient, setIsRevokeDSPending, t, userId]
  );

  const refreshDataSource = React.useCallback(
    async (providerKey: string) => {
      if (userId) {
        window.open(
          await remoteClient.getPassport().getDatasourceActivationUrl(window.location.toString(), providerKey, true)
        );
      } else {
        toast.error(t('errors:authentication.notLoggedIn'));
      }
    },
    [remoteClient, t, userId]
  );

  return {
    revokeDataSource,
    isRevokeDSPending,
    refreshDataSource,
  };
};

export default useDataSourceActions;
