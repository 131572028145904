import React, { useEffect } from 'react';
import Paper from 'components/paper/Paper';
import useActivityLogStore from '../hooks/useActivityLogStore';
import ActivityLogTable from './ActivityLogTable';
import { useTranslation } from 'react-i18next';

const ActivityLog: React.FC = () => {
  const { t } = useTranslation(['activityLog']);
  const { fetchActivityLog, activityLog, isPending } = useActivityLogStore();

  const getActivityLog = React.useCallback(() => {
    fetchActivityLog();
  }, [fetchActivityLog]);

  useEffect(() => {
    getActivityLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivityLog]);

  return (
    <React.Fragment>
      <Paper heading={t('overview.heading')} subHeading={t('overview.subHeading')}>
        <ActivityLogTable isPending={isPending} activityLog={activityLog} />
      </Paper>
    </React.Fragment>
  );
};

export default ActivityLog;
