import * as React from 'react';
import { DetailHeader, HeaderContent, ServiceName } from './ServiceDetail.styled';

const LoadingState = () => (
  <DetailHeader>
    <HeaderContent>
      <ServiceName>Loading...</ServiceName>
    </HeaderContent>
  </DetailHeader>
);

export default LoadingState;
