import { buttonTypes } from '@datavillage-me/dv-common-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu, ContextMenuContainer } from '../contextMenu';
import { ContextMenuOrientation, ORIENTATION_TOP_LEFT } from '../contextMenu/constants';
import { ContextMenuRenderProps } from '../contextMenu/ContextMenu';
import BasicConfirmation from './BasicConfirmation';

export type ToggleConfirmationType = (toggleConfirmation: (e: React.MouseEvent) => void) => JSX.Element;

type Props = {
  callback: (args: any) => void;
  children: ToggleConfirmationType | JSX.Element;
  customConfirmText?: string;
  customDescription?: string;
  orientation?: ContextMenuOrientation;
  isDisabled?: boolean;
  variant: 'delete' | 'confirm';
  customOverlayComponent?: React.ReactElement;
  visibleOverflow?: boolean;
  disableSubmit?: boolean;
  isLoading?: boolean;
};

const Confirmation: React.FC<Props> = ({
  children,
  callback,
  customConfirmText,
  customDescription,
  orientation,
  isDisabled,
  variant,
  customOverlayComponent,
  visibleOverflow,
  disableSubmit,
  isLoading,
}: Props) => {
  const { t } = useTranslation(['translation']);

  return (
    <ContextMenu
      enhanceWithOnContext={false}
      orientation={orientation || ORIENTATION_TOP_LEFT}
      content={
        <ContextMenuContainer visibleOverflow={visibleOverflow}>
          {customOverlayComponent && React.cloneElement(customOverlayComponent)}
          <BasicConfirmation
            isLoading={isLoading}
            disableSubmit={disableSubmit}
            callback={callback}
            cancelText={t('general.cancel')}
            type={variant === 'delete' ? buttonTypes.danger : buttonTypes.primary}
            confirmText={
              customConfirmText !== undefined
                ? customConfirmText
                : variant === 'delete'
                ? t('general.delete')
                : t('confirmation.yes')
            }
            description={
              customDescription !== undefined
                ? customDescription
                : variant === 'delete'
                ? t('deleteConfirmation.description')
                : t('confirmation.description')
            }
          />
        </ContextMenuContainer>
      }
    >
      {({ toggleMenu }: ContextMenuRenderProps) =>
        typeof children === 'function'
          ? React.cloneElement(children(toggleMenu), { onClick: isDisabled ? () => undefined : toggleMenu })
          : React.cloneElement(children, { onClick: isDisabled ? () => undefined : toggleMenu })
      }
    </ContextMenu>
  );
};

export default Confirmation;
