import Paper from 'components/paper/Paper';
import { AppContext } from 'modules/app/components/AppContext';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDataPodDetailStore from './hooks/useDataPodDetailStore';
import StoredData from './storedData/StoredData';

const DataPodDetails = ({path} : {path?: string}) => {
  const { t } = useTranslation(['dataPods']);
  const ctx = useContext(AppContext);

  const currentUser = ctx.currentUser?.id;
  const { masterPod, fetchMasterPod } = useDataPodDetailStore();

  const getMasterDataPod = useCallback(() => {
    fetchMasterPod();
  }, [fetchMasterPod]);

  useEffect(() => {
    getMasterDataPod();
  }, [getMasterDataPod]);

  return (
    <React.Fragment>
      <Paper heading={t('dataPodDetails.header')} subHeading={t('dataPodDetails.subHeader')}>
        {/* <PodDetailHeader>
          <PodSummary masterPod={masterPod} />
        </PodDetailHeader> */}
        {currentUser && masterPod?.uri && <StoredData pod={masterPod.uri} path={path}/>}
      </Paper>
    </React.Fragment>
  );
};

export default DataPodDetails;
