import { ActivityLogIcon, Connections, House, LogOut, MyData } from 'assets/icons';
import Logo from 'assets/images/datavillage_logo_small.svg';
import { useExtension } from 'hooks/useExtension';
import useGetPodName from 'hooks/useGetPodName';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { palette } from '../../config/theme/themeFiles';
import Tooltip from '../tooltip';
import ExtensionButton from './ExtensionButton';
import NavButton from './nav-button/NavButton.styled';
import { StyledNavLink } from './nav-link/NavLink.styled';
import {
  ConnectionsNavLinks,
  CurrentInfo,
  CurrentPodContainer,
  CurrentPodName,
  Label,
  NavButtonTitle,
  NavLinkTitle,
  NavLogo,
} from './SideNavContent.Styled';

export type Props = {
  handleLogOut: VoidFunction;
  userId: string;
  podUrl?: string;
};

const SideNavContent: React.FC<Props> = ({ handleLogOut, podUrl }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const podName = useGetPodName();
  const hasNoExtension = useExtension() === undefined;

  const handlePodUrlClick = useCallback(() => {
    window.open(podUrl, '_blank');
  }, [podUrl]);

  return (
    <React.Fragment>
      <div>
        <CurrentPodContainer>
          <NavLogo src={Logo} alt="logo" />
          <CurrentInfo>
            <Tooltip content={t('navigation.goToPDS')}>
              <CurrentPodName onClick={handlePodUrlClick}>
                <Label>{t('general.personalDataStore')}</Label>
                {podName}
              </CurrentPodName>
            </Tooltip>
          </CurrentInfo>
        </CurrentPodContainer>

        <StyledNavLink exact isActive={() => ['/', '/dashboard'].includes(location.pathname)} to="/dashboard">
          <House />
          <NavLinkTitle>{t('navigation.dashboard')}</NavLinkTitle>
        </StyledNavLink>
        <StyledNavLink to="/myData">
          <MyData color={palette.primary.shade7} />
          <NavLinkTitle>{t('navigation.myData')}</NavLinkTitle>
        </StyledNavLink>
        {/* <StyledNavLink id="onboarding_btn" exact to="/">
          <House />
          <NavLinkTitle>{t('navigation.onboarding')}</NavLinkTitle>
        </StyledNavLink> */}
        <StyledNavLink id="connections_btn" to="/connections">
          <Connections />
          <NavLinkTitle>{t('navigation.connections')}</NavLinkTitle>
        </StyledNavLink>
        <ConnectionsNavLinks className={`${location.pathname.includes('connections') && 'current'}`}>
          <StyledNavLink className="sub" to="/connections/services">
            {t('navigation.services')}
          </StyledNavLink>
          <StyledNavLink className="sub" to="/connections/data-sources">
            {t('navigation.dataSources')}
          </StyledNavLink>
          {/* <StyledNavLink className="sub" to="/connections/catalog">
            {t('navigation.catalog')}
          </StyledNavLink> */}
        </ConnectionsNavLinks>
        <StyledNavLink to="/activityLog">
          <ActivityLogIcon />
          <NavLinkTitle>{t('navigation.activityLog')}</NavLinkTitle>
        </StyledNavLink>
        {/* <StyledNavLink to="/settings">
          <Settings />
          <NavLinkTitle>{t('navigation.settings')}</NavLinkTitle>
        </StyledNavLink> */}
        {hasNoExtension && <ExtensionButton />}
      </div>
      <div>
        <NavButton onClick={handleLogOut}>
          <LogOut />
          <NavButtonTitle>{t('navigation.logOut')}</NavButtonTitle>
        </NavButton>
      </div>
    </React.Fragment>
  );
};

export default SideNavContent;
