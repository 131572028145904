import styled from 'config/theme/styled';
import { palette, typography } from 'config/theme/themeFiles';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)`
  align-items: center;
  border-radius: 8px;
  color: ${palette.primary.shade7};
  display: flex;
  font-family: ${typography.fontFamily};
  font-size: 1em;
  margin: 10px 0 20px 14px;
  padding: 10px 16px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  svg {
    stroke: ${palette.primary.shade7};
  }

  &.sub {
    margin-left: 20px;
    font-size: 1em;
  }

  &.active {
    background: ${palette.secondary.shade8};
    border-left: 4px solid ${palette.primary.shade4};
    color: ${palette.primary.shade4};

    svg {
      stroke: ${palette.primary.shade4};
      transition: all 0.15s ease-in-out;
    }
  }

  &:hover {
    background: ${palette.secondary.shade8};
    border-left: 4px solid ${palette.primary.shade4};
    color: ${palette.primary.shade4};

    svg {
      stroke: ${palette.primary.shade4};
      transition: all 0.15s ease-in-out;
    }
  }
`;
