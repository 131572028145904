import { DatasourceRecord, DATA_SOURCE_TYPE } from '@datavillage-me/api';
import { Refresh, RevokeConsent } from 'assets/icons';
import IconButton from 'components/button/IconButton';
import Paper from 'components/paper/Paper';
import Table from 'components/table/Table';
import { ActionsWrapper, Checkbox, FlexTableData, TableRow } from 'components/table/Table.styles';
import Tooltip from 'components/tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logoFactory } from 'utils/images';
import { FacebookCta } from '../../login/FacebookCta';
import { Logo, LogoHelper, NameAndCategoryContainer } from '../services/overview/Services.styled';
import DataSourcesHeader from './components/DataSourcesHeader';
import DataSourcesEmptyState from './DataSources.empty';
import DataSourcesLoadingState from './DataSources.loading';
import useDataSourceActions from './hooks/useDataSourceActions';
import useDataSourcesStore from './hooks/useDataSourcesStore';
import AuthDate from './table/AuthDate';

const DataSources: React.FC = () => {
  const { t } = useTranslation(['dataSources']);

  const {
    activeDataSources,
    allDataSources,
    fetchActiveDataSources,
    fetchAllDataSources,
    isActiveDSPending,
    isAllDSPending,
  } = useDataSourcesStore();

  const { revokeDataSource, isRevokeDSPending, refreshDataSource } = useDataSourceActions();

  const isPending = isActiveDSPending || isAllDSPending;
  const [selectedDataSourceIds, setSelectedDataSourceIds] = useState<string[]>([]);

  const getDataSources = React.useCallback(() => {
    fetchActiveDataSources();
    fetchAllDataSources();
  }, [fetchActiveDataSources, fetchAllDataSources]);

  useEffect(() => {
    getDataSources();
  }, [getDataSources]);

  const handleCheckDataSource = (id: string) => {
    if (selectedDataSourceIds.includes(id)) {
      setSelectedDataSourceIds(selectedDataSourceIds.filter((dataSource) => dataSource !== id));
    } else {
      setSelectedDataSourceIds([...selectedDataSourceIds, id]);
    }
  };
  const handleCheckAll = () => {
    if (allDataSources && selectedDataSourceIds.length === allDataSources.length) {
      setSelectedDataSourceIds([]);
    } else {
      allDataSources && setSelectedDataSourceIds([...allDataSources.map((dataSource) => dataSource.id)]);
    }
  };

  const filteredActiveDataSource = (sourceId: string) =>
    (activeDataSources &&
      activeDataSources.filter((activeDataSource: DatasourceRecord) => activeDataSource.resourceUri === sourceId)) ||
    [];

  const isDataSourceEmpty =
    !isPending && allDataSources && allDataSources.length < 1 && activeDataSources && activeDataSources.length < 1;

  return (
    <React.Fragment>
      <Paper heading={t('overview.heading')} subHeading={t('overview.subHeading')}>
        <Table
          handleCheckboxChange={handleCheckAll}
          withCheckBox
          tableHeaders={[
            { title: t('table.headers.dataSource') },
            { title: t('table.headers.actionType') },
            { title: t('table.headers.authorizedSince') },
            { title: '', width: 100 },
          ]}
          actions={
            <DataSourcesHeader
              isRevokeDSPending={isRevokeDSPending}
              revokeDataSource={revokeDataSource}
              refreshDataSource={refreshDataSource}
              selectedDataSourceIds={selectedDataSourceIds}
            />
          }
        >
          <React.Fragment>
            {isPending ? (
              <DataSourcesLoadingState />
            ) : isDataSourceEmpty ? (
              <DataSourcesEmptyState />
            ) : (
              allDataSources?.map((dataSource) => (
                <TableRow noHover key={dataSource.id}>
                  <td>
                    <Checkbox
                      type="checkbox"
                      checked={selectedDataSourceIds.filter((id) => id === dataSource.id).length > 0}
                      onChange={() => handleCheckDataSource(dataSource.id)}
                    />
                  </td>
                  <td>
                    <NameAndCategoryContainer>
                      <LogoHelper>
                        <Tooltip content={dataSource.label}>
                          <Logo src={logoFactory(dataSource.label)} alt={dataSource.label} />
                        </Tooltip>
                      </LogoHelper>
                    </NameAndCategoryContainer>
                  </td>
                  <td>
                    {dataSource?.mappings?.map((mappedAction, index) => (
                      <React.Fragment key={index}>{<p key={index}>{mappedAction.label}</p>}</React.Fragment>
                    ))}
                  </td>
                  <td>
                    <AuthDate
                      isActiveDSPending={isActiveDSPending}
                      filteredActiveDataSource={filteredActiveDataSource}
                      dataSource={dataSource}
                    />
                  </td>
                  <FlexTableData>
                    {dataSource.type === DATA_SOURCE_TYPE.PULL && (
                      <ActionsWrapper>
                        {filteredActiveDataSource(dataSource.id).length ? (
                          <IconButton
                            onClick={() => revokeDataSource(dataSource.id)}
                            isLoading={isRevokeDSPending}
                            tooltip={t('actions.revoke')}
                            tooltipPosition="left"
                          >
                            <RevokeConsent />
                          </IconButton>
                        ) : null}
                        {dataSource.id.toLowerCase() === 'facebook' &&
                        !filteredActiveDataSource(dataSource.id).length ? (
                          <FacebookCta onClick={() => refreshDataSource(dataSource.id)} />
                        ) : (
                          <IconButton
                            onClick={() => refreshDataSource(dataSource.id)}
                            tooltip={t('actions.refresh')}
                            tooltipPosition="left"
                          >
                            <Refresh />
                          </IconButton>
                        )}
                      </ActionsWrapper>
                    )}
                  </FlexTableData>
                </TableRow>
              ))
            )}
          </React.Fragment>
        </Table>
      </Paper>
      {/* <DataSourceDetails
        isOpen={showDetails}
        handleShowDetails={toggleShowDetails}
        dataSourceDetails={selectedDataSourceDetails}
      /> */}
    </React.Fragment>
  );
};

export default DataSources;
