import React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';

import styled from '../../../config/theme/styled';

class PassportErrorBoundary extends React.Component<{ t: TFunction }> {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
  };

  static getDerivedStateFromError = (error: any) => {
    return { hasError: true };
  };

  componentDidCatch = (error: any, info: any) => {
    this.setState({ error, info });
  };

  // TODO error message with page reload to restart the flow
  render() {
    const { hasError, error, info } = this.state;
    console.log(error, info);
    const { children } = this.props;

    return hasError ? <ErrorComponent t={this.props.t} /> : children;
  }
}

const ErrorComponent = ({ t }: { t: TFunction }) => {
  return (
    <Container>
      <h3>{t('general.somethingWrongTryAgain')}</h3>
      <Button
        {...{
          buttonType: buttonTypes.primary,
          onClick: () => window.parent.postMessage({ source: 'codename', content: 'closeModal' }, '*'),
          label: t('general.close'),
        }}
      />
    </Container>
  );
};

export default withTranslation()(PassportErrorBoundary);

const Container = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 20px;
  }
`;
