import { AppContext } from 'modules/app/components/AppContext';
import { useContext, useEffect, useState } from 'react';

const useGetPodName = () => {
  const context = useContext(AppContext);
  const [podName, setPodName] = useState<string>('');

  useEffect(() => {
    switch (context?.currentUser?.pods[0].type) {
      case 'google':
        return setPodName('Google Drive');
      case 'solid':
        const processedPodName = context.currentUser.masterPodUri.split('/');
        if (processedPodName[2].length > 20) {
          return setPodName(processedPodName[2].substring(0, 20) + '...');
        } else return setPodName(processedPodName[2]);
      default:
        return setPodName('');
    }
  }, [context]);

  return podName;
};

export default useGetPodName;
