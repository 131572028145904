import ServiceTile from 'components/service';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { logoFactory } from 'utils/images';
import Paper from '../../../components/paper/Paper';
import useServicesStore from '../services/hooks/useServicesStore';
import CatalogLoadingState from './Catalog.loading';
import { CatalogContainer, ServicesContainer } from './Catalog.styled';

const Catalog: React.FC = () => {
  const { t } = useTranslation(['catalog']);
  const { isFetchServicesPending, services, fetchServices } = useServicesStore();

  const getServices = React.useCallback(() => {
    fetchServices();
  }, [fetchServices]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  return (
    <React.Fragment>
      <Paper heading={t('overview.heading')} subHeading={t('overview.subHeading')}>
        <CatalogContainer>
          <ServicesContainer>
            {isFetchServicesPending ? (
              <CatalogLoadingState />
            ) : (
              services &&
              services.length > 0 &&
              Object.values(services).map((service, index) => (
                <ServiceTile
                  key={index}
                  to={`/connections/catalog/service/${service.name}`}
                  name={service.name}
                  enabled={service.hasOwnProperty('consent')}
                  logo={logoFactory(service.name)}
                />
              ))
            )}
          </ServicesContainer>
        </CatalogContainer>
      </Paper>
    </React.Fragment>
  );
};

export default Catalog;
