import { POD_IDENTITY_PROVIDER } from '@datavillage-me/api';
import InruptImg from 'assets/images/inrupt.png';
import styled from 'config/theme/styled';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'utils';
import { palette } from '../../../config/theme/themeFiles';
import { AppContext } from '../../app/components/AppContext';
import { handleExternalAuth } from '../external-provider/SelectProvider';

const SolidProviders: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const redirectUrlParam = useQuery().redirectUrl;

  const appContext = useContext(AppContext);

  const handleInruptExternalAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // TODO pass Solid identity issuer as third param (defaults to inrupt)
    handleExternalAuth(appContext, redirectUrlParam, appContext.passport_url, POD_IDENTITY_PROVIDER.Solid);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <Styledh1>{t('login.external.selectIdentity')}</Styledh1>
      <InruptLogin onClick={handleInruptExternalAuth} disabled={loading}>
        <BrandImg src={InruptImg} alt="solid"></BrandImg>
      </InruptLogin>
    </React.Fragment>
  );
};

export default SolidProviders;

const Styledh1 = styled.h1`
  margin-top: 50px;
  text-align: center;
`;

const InruptLogin = styled.button`
  background: none;
  border-radius: 10px;
  border: 1px solid ${palette.inrupt.shade4};
  cursor: pointer;
  display: block;
  padding: 5px 16px;
  text-align: center;
  width: 100%;
`;

const BrandImg = styled.img`
  height: 30px;
  vertical-align: middle;
`;
