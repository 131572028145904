import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from 'components/Skeleton';

const TileLoadingState: React.FC = () => (
  <section style={{ maxWidth: 400 }}>
    <SkeletonGenerator count={4}>
      <ContentLoader width={400} height={30}>
        <rect x="0" y="0" rx="4" ry="4" width="120" height="30" />
        <rect x="140" y="0" rx="4" ry="4" width="180" height="30" />
      </ContentLoader>
    </SkeletonGenerator>
  </section>
);

export default TileLoadingState;
