import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../modules/app/components/AppContext';
import { DatavillageExtension } from '../utils/extension';

export const useExtension = () => {
  const appContext = useContext(AppContext);
  const [extension, setExtension] = useState<DatavillageExtension>();

  useEffect(() => {
    if (appContext.extensionId) {
      setExtension(new DatavillageExtension(appContext.extensionId));
    } else {
      setExtension(undefined);
    }
  }, [appContext.extensionId]);

  return extension;
};
