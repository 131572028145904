import { createGlobalStyle } from 'styled-components';
import { palette } from '../../config/theme/themeFiles';

const StyledShepardTour = createGlobalStyle`
  /* Wrapper */
  /* .shepherd-modal-overlay-container{
    background: rgba(0,0,0,0.5);
    fill: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    transition: all 0.2s ease-in-out;
  } */

  /* Model content */
  /* .shepherd-content {
    padding: 20px;
    background: ${palette.primary.shade1};
  } */
  
  /* Buttons */
  .onboarding-button-primary {
    color:${palette.primary.shade7};
    background: ${palette.primary.shade1};
  }
  .onboarding-button-secondary {
    background: black;
    color: white
  }
  .onboarding-button-tertiary {
    background: red;
  }
`;

export default StyledShepardTour;
