import { useRemoteClient } from '../app/components/AppContext';
import * as React from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router';
import { useState } from 'react';
import { ColumnFlexDiv, Tab, Tabs } from './styles';
import { CodeEditor, guessMode } from '../../utils/code-editor';
import { PromiseFnContainer } from "@datavillage-me/dv-common-ui";

export function AdminConsentsRoute(props: any) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:consentId`}>
        <AdminConsentDetails />
      </Route>
      <Route path={`${path}`}>
        <AdminConsentsList />
      </Route>
    </Switch>
  );
}

export const AdminConsentsList = () => {
  const client = useRemoteClient();
  const { url } = useRouteMatch();

  return (
    <ColumnFlexDiv>
      <h2>Consents</h2>
      <PromiseFnContainer promiseFn={() => client.getConsentsServices().getConsents()} deps={[]}>
        {(consents) => (
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>Timestamp</td>
                <td>Application</td>
                <td>User</td>
                <td>Status</td>
              </tr>
              {consents
                .sort((consent1, consent2) =>
                  consent1['gConsent:atTime']['time:inXSDDateTimeStamp'] <
                  consent2['gConsent:atTime']['time:inXSDDateTimeStamp']
                    ? 1
                    : -1
                )
                .map((consent) => (
                  <tr key={consent['@id']}>
                    <td>
                      <Link to={`${url}/${consent['@id']}`}>{consent['@id']}</Link>
                    </td>
                    <td>{new Date(consent['gConsent:atTime']['time:inXSDDateTimeStamp']).toLocaleString()}</td>
                    <td>
                      <Link to={`/admin/clients/${consent.clientId}/apps/${consent.applicationId}`}>
                        {consent.applicationId}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/admin/users/${consent.userId}`}>{consent.userId}</Link>
                    </td>
                    <td>{consent['gConsent:hasStatus']}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};

export const AdminConsentDetails = () => {
  const apiClient = useRemoteClient();
  const { params } = useRouteMatch<{ consentId: string }>();

  const [activeTab, setActiveTab] = useState(0);

  return (
    <ColumnFlexDiv>
      <h2>Consent {params.consentId}</h2>
      <PromiseFnContainer
        promiseFn={() => apiClient.getConsentsServices().getConsent(params.consentId)}
        deps={[params.consentId]}
      >
        {(consent) =>
          consent ? (
            <>
              <ColumnFlexDiv>
                <div>
                  <Tabs>
                    <Tab className={`${activeTab == 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
                      Consent Details
                    </Tab>
                    <Tab className={`${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                      Consent Raw
                    </Tab>
                    <Tab className={`${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
                      Data in scope
                    </Tab>
                  </Tabs>
                </div>

                {activeTab == 0 ? (
                  <table>
                    <tbody>
                      <tr>
                        <th>ID</th>
                        <td>{consent['@id']}</td>
                      </tr>
                      <tr>
                        <th>Created on</th>
                        <td>{new Date(consent['gConsent:atTime']['time:inXSDDateTimeStamp']).toLocaleString()}</td>
                      </tr>
                      <tr>
                        <th>Client</th>
                        <td>
                          <Link to={`/admin/clients/${consent.clientId}`}>{consent.clientId}</Link>
                        </td>
                      </tr>
                      <tr>
                        <th>Application</th>
                        <td>
                          <Link to={`/admin/clients/${consent.clientId}/apps/${consent.applicationId}`}>
                            {consent.applicationId}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>User</th>
                        <td>
                          <Link to={`/admin/users/${consent.userId}`}>{consent.userId}</Link>
                        </td>
                      </tr>
                      <tr>
                        <th>ConsentReceipt</th>
                        <td>{consent.consentReceiptId}</td>
                      </tr>
                      <tr>
                        <th>Expiry</th>
                        <td>
                          {new Date(
                            consent['gConsent:hasExpiry']['time:hasEnd']['time:inXSDDateTimeStamp']
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <th>Purpose</th>
                        <td>{consent['gConsent:purpose']}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{consent['gConsent:hasStatus']}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : activeTab == 1 ? (
                  <CodeEditor
                    code={JSON.stringify(consent, undefined, 4)}
                    language={guessMode(undefined, 'application/json')}
                    onCodeChange={(value) => {}}
                  />
                ) : activeTab == 2 ? (
                  <PromiseFnContainer
                    promiseFn={() => {
                      return apiClient
                        .getClientsServices()
                        .getApplicationUserData(consent.clientId, consent.applicationId, consent.userId);
                    }}
                    deps={[consent.clientId, consent.applicationId, consent.userId]}
                  >
                    {(data: any) => (
                      <CodeEditor
                        code={data}
                        language={guessMode(undefined, 'text/turtle')}
                        onCodeChange={(value) => {}}
                      />
                    )}
                  </PromiseFnContainer>
                ) : null}

                {/* TODO display history */}
              </ColumnFlexDiv>
            </>
          ) : null
        }
      </PromiseFnContainer>
    </ColumnFlexDiv>
  );
};
