import styled from 'styled-components';
import { Activity, ActivityMessageStyle, TypeAndDateWrapper } from '../activityLog/overview/ActivityLog.styled';

export const HorizontalFlow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1600px;

  & > * {
    flex: 1;
    min-width: 0;
    overflow: auto;
  }
`;

export const EllipsedText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const TileActivities = styled.div`
  ${Activity} {
  }

  ${TypeAndDateWrapper} {
    min-width: inherit;
    max-width: inherit;
    margin-right: 1em;
  }

  ${ActivityMessageStyle} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const DashboardTable = styled.table`
  border: 1px red;

  td:not(:first-child) {
    padding-left: 1em;
  }
`;

export const RedirectionContainer = styled.div`
  text-align: center;

  button {
    margin: 0 10px;
  }
`;
