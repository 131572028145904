import { palette } from 'config/theme/themeFiles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ServiceHeader = styled.h2`
  font-size: 1em;
  font-weight: 600;
  color: ${palette.primary.shade7};
  margin: 0;
`;

export const StyledService = styled(Link)`
  position: relative;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  border: 1px solid ${palette.neutral.shade2};
  margin-bottom: 30px;
  margin-right: 30px;
  transition: all 0.2s ease-in-out;

  &.enabled {
    border: 1px solid ${palette.success.shade4};
    background: ${palette.success.shade1};
  }

  &:hover {
    border: 1px solid ${palette.primary.shade4};
    background: ${palette.primary.shade1};
    box-shadow: 0px 0px 10px 0px ${palette.primaryLight.shade1};
  }
`;

export const ServiceCategory = styled.span`
  background: ${palette.primary.shade4};
  padding: 5px 20px;
  border-radius: 10px;
  color: ${palette.primary.shade7};
  font-size: 0.8em;
`;

export const Header = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const LogoHelper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 10px 26px 10px;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  max-width: 120px;
  max-height: 70px;
`;

export const Checkmark = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: ${palette.success.shade4};

  svg {
    fill: ${palette.success.shade1};
  }

  text-align: center;
  padding-top: 1px;
`;
