import styled, { css } from 'styled-components';
import { palette } from 'config/theme/themeFiles';

export const ServiceName = styled.h1`
  color: ${palette.primary.shade7};
  font-weight: 600;
  display: inline;
`;

export const Description = styled.p`
  color: ${palette.neutral.shade6};
`;

export const Property = styled.span`
  color: ${palette.neutral.shade9};
  margin-bottom: 30px;
  display: inline-block;
`;

export const DetailHeader = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const SubTitle = styled.h3`
  font-size: 0.8em;
  color: ${palette.neutral.shade6};
`;

export const HeaderContent = styled.div`
  margin: 0 26px 26px 26px;
  flex-grow: 1;
`;

export const HeaderImageAndCategory = styled.div`
  margin: 0 26px 26px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;

export const Properties = styled.div`
  display: flex;
  background: ${palette.neutral.shade1};
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.12);
  padding: 26px;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export const WebsiteAndPurpose = styled.div`
  margin-bottom: 15px;
  margin-right: 20px;
`;

export const DataSources = styled.div`
  padding: 26px;
`;

export const DataSourcesHeader = styled.h2`
  text-transform: uppercase;
  font-weight: 600;
  color: ${palette.neutral.shade8};
  margin-bottom: 30px;
`;

export const DataSourcesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Link = styled.a`
  color: inherit;
  text-decoration: underline;
`;

const DetailStatus = styled.div<{ disabled?: boolean }>`
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: 600;
  display: inline-block;
`;

export const DetailConnected = styled(DetailStatus)`
  background: ${palette.success.shade1};
  color: ${palette.success.shade4};
  transition: all 0.2s ease-in-out;
`;

export const DetailDisconnected = styled(DetailStatus)`
  background: ${palette.primary.shade1};
  color: ${palette.secondary.shade8};
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.disabled
      ? css`
          background: ${palette.neutral.shade2};
          color: ${palette.neutral.shade4};
        `
      : css`
          &:hover {
            background: ${palette.primary.shade7};
            color: ${palette.primary.shade1};
            cursor: pointer;
          }
        `}
`;
