import { palette } from 'config/theme/themeFiles';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  children: JSX.Element | JSX.Element[] | null;
  closeMenu?: VoidFunction;
};

const ContextMenuBlock = ({ title, children, closeMenu }: Props) => (
  <ContextMenuBlockWrapper>
    <h6>{title}</h6>
    <div>
      {children &&
        React.Children.map(children, (child: JSX.Element, index: number) =>
          React.cloneElement(child, { closeMenu, key: `menu_block_${index}` })
        )}
    </div>
  </ContextMenuBlockWrapper>
);

const ContextMenuBlockWrapper = styled.div`
  > h6 {
    font-size: 0.82rem;
    color: ${palette.neutral.shade4};
    font-weight: 600;
    text-transform: uppercase;
    margin: 20px 20px 5px;
    letter-spacing: 1.5px;
  }

  &:first-child {
    > h6 {
      margin-top: 10px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export default ContextMenuBlock;
