import { useEffect, useState } from 'react';

const useListenExtensionVersion = () => {
  const [extensionId, setExtensionId] = useState<string>();

  useEffect(() => {
    const listener = (event: any) => {
      if (event.origin === window.location.origin) {
        event.data.datavillageExtensionId && setExtensionId(event.data.datavillageExtensionId);
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return extensionId;
};

export default useListenExtensionVersion;
