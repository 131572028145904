import { Resource, RESOURCE_TYPE } from '@datavillage-me/api';
import useToggle from 'hooks/useToggle';
import { AppContext, useRemoteClient } from 'modules/app/components/AppContext';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const useStoredDataStore = () => {
  const { t } = useTranslation(['errors']);
  const remoteClient = useRemoteClient();
  const context = useContext(AppContext);
  const userId = context.currentUser?.id;
  const [podResource, setPodResource] = useState<Resource<RESOURCE_TYPE> | null>(null);
  const [isPending, setIsPending] = useToggle(true);

  const fetchDataPodResources = useCallback(
    (pod: string, currentPath?: string) => {
      setIsPending(true);

      if (userId) {
        remoteClient
          .getUsersServices()
          .getPodInstance(userId, pod)
          .getResource(currentPath || '')
          .then((result) => {
            setPodResource(result);
          })
          .finally(() => {
            setIsPending(false);
          })
          .catch(() => {
            // toast.error(t('dataPods.dataPods_fetch'));
          });
      } else {
        toast.error(t('authentication.notLoggedIn'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsPending]
  );

  return {
    isPending,
    fetchDataPodResources,
    podResource,
  };
};

export default useStoredDataStore;
