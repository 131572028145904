import GlobalStyle from 'config/theme/GlobalStyle';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import Loader from 'react-spinners/PuffLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'shepherd.js/dist/css/shepherd.css';
import '../../../config/i18n';
import StyledShepardTour from '../../onboarding/onboarding.styled';
import { WithInitAppContext } from '../components/AppContext';
import MainRouter from './Router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3600,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      {/* React-Query HoC */}
      <QueryClientProvider client={queryClient}>
        {/* Global injects */}
        <GlobalStyle />
        <StyledShepardTour />
        <ToastContainer newestOnTop draggable={false} autoClose={false} />
        {/* Main application within router */}
        <BrowserRouter>
          <WithInitAppContext>
            {() => (
              <React.Fragment>
                <MainRouter />
              </React.Fragment>
            )}
          </WithInitAppContext>
        </BrowserRouter>
      </QueryClientProvider>
    </React.Suspense>
  );
};

export default App;
