import { ContentLoader, SkeletonGenerator } from 'components/Skeleton';
import { TableRow } from 'components/table/Table.styles';
import * as React from 'react';

const ServicesLoadingState: React.FC = () => (
  <TableRow noHover>
    <td colSpan={3}>
      <section style={{ maxWidth: 800 }}>
        <SkeletonGenerator count={4}>
          <ContentLoader width={800} height={65}>
            <rect x="0" y="0" rx="0" ry="0" width="600" height="50" />
          </ContentLoader>
        </SkeletonGenerator>
      </section>
    </td>
  </TableRow>
);

export default ServicesLoadingState;
