import * as React from 'react';
import { ReactNode } from 'react';
import { Checkbox, StyledTable, TableHeader, TableRow } from './Table.styles';

type Props = {
  actions?: React.ReactNode;
  checkAll?: boolean;
  children: React.ReactNode;
  handleCheckboxChange?: VoidFunction;
  tableHeaders: { title: string | ReactNode; width?: number; className?: string }[];
  withCheckBox?: boolean;
};

const Table: React.FC<Props> = ({
  actions,
  checkAll,
  children,
  handleCheckboxChange,
  tableHeaders,
  withCheckBox,
}: Props) => (
  <StyledTable>
    <thead>
      {actions && (
        <TableRow noHover noLine>
          {actions}
        </TableRow>
      )}
      <TableRow noHover>
        {withCheckBox && (
          <TableHeader>
            <Checkbox onChange={handleCheckboxChange} checked={checkAll} type="checkbox" />
          </TableHeader>
        )}
        {tableHeaders.map((header, index) => {
          return (
            <TableHeader
              className={header.className}
              style={{ width: `${header.width}px` || '' }}
              key={typeof header.title === 'string' ? header.title : index}
            >
              {header.title}
            </TableHeader>
          );
        })}
      </TableRow>
    </thead>
    <tbody>{children}</tbody>
  </StyledTable>
);

export default Table;
