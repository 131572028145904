import React from 'react';
import styled from 'styled-components';
import { palette } from '../../config/theme/themeFiles';
import { mediaSize } from '../../config/theme/themeFiles/MediaSizes';

const Image = styled.img`
  opacity: 0.6;
  text-align: center;
`;

const EmptyStateImage = styled(Image)`
  height: 200px;

  ${mediaSize.mobile`
    height: 70px;
  `};
  ${mediaSize.tablet`
    height: 90px;
  `}
  ${mediaSize.laptopSM`
    height: 140px;
  `};
  ${mediaSize.laptopLG`
    height: 160px;
  `};
`;

type Props = {
  paper?: boolean;
};

const EmptyStateWrapper = styled.section<Props>`
  text-align: center;
  width: 100%;

  > h1 {
    color: ${palette.neutral.shade7};
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: initial;
  }

  > p {
    color: ${palette.neutral.shade5};
    font-size: 1rem;
    text-align: center;
    max-width: 450px;
    margin: 20px auto 0;
    white-space: break-spaces;

    ${mediaSize.mobile` max-width: 200px; `};
    ${mediaSize.tablet` max-width: 300px; `};
    ${mediaSize.laptopSM` max-width: 380px; `};
    ${mediaSize.laptopLG` max-width: 400px; `};
  }

  button {
    margin: 30px auto 0;
  }

  ${(props) => mediaSize.desktop`
    ${props.paper && 'max-width: 1400px; margin: 16px; padding: 24px; width: auto;'};
  `};
`;

const TransitionedWrapper: React.FC<Props> = ({ children, ...rest }) => (
  <EmptyStateWrapper {...rest}>{children}</EmptyStateWrapper>
);

export { EmptyStateImage, TransitionedWrapper as EmptyStateWrapper };
