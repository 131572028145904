export const ORIENTATION_TOP_LEFT = 'top-left';
export const ORIENTATION_TOP_RIGHT = 'top-right';
export const ORIENTATION_BOTTOM_LEFT = 'bottom-left';
export const ORIENTATION_BOTTOM_RIGHT = 'bottom-right';

export type ContextMenuOrientation =
  | typeof ORIENTATION_TOP_LEFT
  | typeof ORIENTATION_TOP_RIGHT
  | typeof ORIENTATION_BOTTOM_LEFT
  | typeof ORIENTATION_BOTTOM_RIGHT;
