import * as React from 'react';
import { StyledBurger } from './Burger.styled';

export type Props = {
  isOpen: boolean;
  setIsOpen: VoidFunction;
};

const Burger: React.FC<Props> = ({ isOpen, setIsOpen }: Props) => (
  <StyledBurger isOpen={isOpen} onClick={setIsOpen}>
    <div />
    <div />
    <div />
  </StyledBurger>
);

export default Burger;
