import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import WalletLogo from '../../assets/images/Wallet_Logo_White.png';
import { AppContext } from '../app/components/AppContext';
import { LandingFooter } from '../landing/LandingFooter';
import { LoginContainer, LoginWrapper, Logo, MainContainer } from './Login.styled';
import LoginForm from './LoginForm';

type Props = { passport?: boolean; redirectUri?: string };

const Login: React.FC<Props> = ({ passport, redirectUri }) => {
  const context = useContext(AppContext);

  if (context.currentUser?.id) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <MainContainer passport={passport}>
      <LoginWrapper>
        {!passport && <Logo src={WalletLogo} alt="logo" />}
        <LoginContainer passport={passport}>
          <LoginForm {...{ passport, redirectUri }} />
        </LoginContainer>
        {!passport && <LandingFooter inLogin />}
      </LoginWrapper>
    </MainContainer>
  );
};

export default Login;
