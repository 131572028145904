import SolidProviders from 'modules/login/identity-provider/SolidProviders';
import { Policy } from 'modules/privacy/policy';
import { TermsOfUse } from 'modules/privacy/terms-of-use';
import PassportApp from 'modules/passport-app/containers/PassportApp';

const PublicRoutes = [
  {
    component: PassportApp,
    exact: false,
    path: '/passport',
  },
  {
    component: Policy,
    exact: false,
    path: '/privacy/policy',
  },
  {
    component: SolidProviders,
    exact: true,
    path: '/login/external/solid',
  },
  {
    component: TermsOfUse,
    exact: false,
    path: '/privacy/terms-of-use',
  },
];

export default PublicRoutes;
