import React from 'react';

import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
  className?: string;
};

const UnCheckMarkCircle: React.FC<Props> = ({ color, className }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper className={className}>
      <svg width="60px" height="60px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={color || '#E3FCEF'}
          fillRule={'evenodd'}
          d="M940,510a30,30,0,1,1,30-30A30,30,0,0,1,940,510Zm15-20.047A3.408,3.408,0,0,1,955,494.77l-0.221.22a3.42,3.42,0,0,1-4.833,0l-8.764-8.755a1.71,1.71,0,0,0-2.417,0l-8.741,8.747a3.419,3.419,0,0,1-4.836,0l-0.194-.193a3.408,3.408,0,0,1,.017-4.842l8.834-8.735a1.7,1.7,0,0,0,0-2.43l-8.831-8.725a3.409,3.409,0,0,1-.018-4.844l0.193-.193a3.413,3.413,0,0,1,2.418-1c0.944,0,3.255,1.835,3.872,2.455l7.286,7.287a1.708,1.708,0,0,0,2.417,0l8.764-8.748a3.419,3.419,0,0,1,4.832,0L955,465.243a3.408,3.408,0,0,1,0,4.818l-8.727,8.737a1.7,1.7,0,0,0,0,2.407Z"
          id="uncheck"
          transform="translate(-910 -450)"
        />
      </svg>
    </BaseSvgWrapper>
  );
};

export default UnCheckMarkCircle;
