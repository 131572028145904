import styled from 'config/theme/styled';

const StyledDataSource = styled.div`
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 0 8px 7px rgb(0 0 0 / 4%);
  padding: 10px 16px;
  margin: 10px;

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

export default StyledDataSource;
