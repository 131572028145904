import { UserDetails } from '@datavillage-me/api';
import useToggle from 'hooks/useToggle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DEFAULT_PASSPORT_URL, getBrowserRemoteClient } from '../components/AppContext';

const useContextStore = () => {
  const { t } = useTranslation(['errors']);
  const passport_url = new URLSearchParams(window.location.search).get('passport_url') || DEFAULT_PASSPORT_URL;
  console.log(passport_url)
  const remoteClient = getBrowserRemoteClient(passport_url);

  const [currentUser, setCurrentUser] = React.useState<UserDetails>();

  const [isfetchCurrentUserPending, setIsfetchCurrentUserPending] = useToggle(true);

  const fetchCurrentUser = React.useCallback(() => {
    remoteClient
      .getPassport()
      .getCurrentUser()
      .then((result: UserDetails) => {
        setCurrentUser(result);
      })
      .finally(() => {
        setIsfetchCurrentUserPending(false);
      })
      .catch(() => {
        toast.error(t('authentication.currentUserNotFound'));
      });
  }, [remoteClient, setIsfetchCurrentUserPending, t]);

  return {
    currentUser,
    fetchCurrentUser,
    isfetchCurrentUserPending,
  };
};

export default useContextStore;
