import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { NavLink } from 'react-router-dom';

import Img0 from '../../assets/images/landing_card_0.png';
import Img1 from '../../assets/images/landing_card_1.png';
import Img2 from '../../assets/images/landing_card_2.png';
import styled from '../../config/theme/styled';
import { mediaSize } from '../../config/theme/themeFiles/MediaSizes';
import { palette } from '../../config/theme/themeFiles';

const imgArray = [Img0, Img1, Img2];

type Props = {
  item: {
    title: string;
    subtitle: string;
  };
  index: number;
};

export const LandingCard: React.FC<Props> = ({ item, index }) => {
  const { t } = useTranslation();

  return (
    <CardContainer index={index}>
      <TextContainer>
        <h1>{item.title}</h1>
        <h3>{item.subtitle}</h3>
        <ButtonContainer>
          <NavLink to="/login">
            <Button
              className={'getMyWallet'}
              buttonType={buttonTypes.primary}
              label={t('landing.getWallet')}
              onClick={() => undefined}
            />
          </NavLink>
        </ButtonContainer>
      </TextContainer>
      <ImgContainer>
        <img src={imgArray[index]} />
      </ImgContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div<{ index: number }>`
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-direction: ${(props) => (props.index % 2 === 0 ? 'row' : 'row-reverse')};

  .getMyWallet {
    background-color: ${palette.primary.shade4};
    font-weight: bold;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  ${mediaSize.mobile`
    width: 100%;
  `};
  padding: 0 40px;

  h1 {
    font-weight: 500;
  }

  button {
    margin-top: 30px;
  }
`;

const ImgContainer = styled.div`
  ${mediaSize.mobile`
    display: none;
  `};
  width: 50%;
  padding: 30px;

  img {
    width: 100%;
    border-radius: 4px;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
