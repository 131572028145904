import { UserScopeApplication } from '@datavillage-me/api';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { PaperHeader, PaperSubHeading, Section } from 'components/paper/Paper';
import React, { useContext, useEffect, useState } from 'react';
import useCountDown from 'react-countdown-hook';
import { useTranslation } from 'react-i18next';
import { fetchApplications } from '../app/components/actions';
import { AppContext, useRemoteClient } from '../app/components/AppContext';
import Explanations from './components/Explanations';
// import RecentActivityTile from './activity/RecentActivityTile';
import { HorizontalFlow, RedirectionContainer } from './Dashboard.styled';
import useDashboardStore from './hooks/useDashboardStore';
import RecentRecommendations from './recommendations/RecentRecommendations';

const Dashboard: React.FC = () => {
  const { t } = useTranslation(['dashboard']);
  const {
    activeApplications,
    fetchActivatedDataSources,
    fetchActiveApplications,
    fetchActivityLog,
    fetchConsents,
    // activityLog,
    // isActivityLogPending,
    isFetchAAPending,
  } = useDashboardStore();

  const getDashboardData = React.useCallback(() => {
    fetchActivatedDataSources();
    fetchActiveApplications();
    fetchActivityLog();
    fetchConsents();
  }, [fetchActiveApplications, fetchActivatedDataSources, fetchConsents, fetchActivityLog]);

  React.useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  const context = useContext(AppContext);
  const client = useRemoteClient();

  const [timeLeft, actions] = useCountDown(3000, 100);
  const [appId, setAppId] = useState<string>();
  const [app, setApp] = useState<UserScopeApplication>();

  useEffect(() => {
    const localStorageAppId = localStorage.getItem('appId');
    localStorage.removeItem('appId');
    localStorageAppId && setAppId(localStorageAppId);
  }, []);

  useEffect(() => {
    if (!context.applications.loading && !context.applications.data) {
      fetchApplications(context, client);
    }

    if (context.applications.data && appId) {
      const matchingApp = context.applications.data.find((app) => app.id === appId);
      if (matchingApp) {
        setApp(matchingApp);
        actions.start();
      }
    }
  }, [context.applications]);

  useEffect(() => {
    if (timeLeft === 0 && app) {
      window.open(app.appSettings.appUrl + '?startPassport=true');
      setAppId(undefined);
      setApp(undefined);
    }
  }, [timeLeft]);

  // const { lastMonthAddConsents, dataProcessing, dataImport, dataWritten, dataRead } = useActivities(
  //   activityLog as Activity[]
  // );

  return (
    <React.Fragment>
      <PaperHeader>{t('overview.heading')}</PaperHeader>
      <PaperSubHeading>{t('overview.subHeading')}</PaperSubHeading>
      <HorizontalFlow>
        <Section>
          <Explanations />
        </Section>
        <Section>
          <RecentRecommendations isAApending={isFetchAAPending} activeApplications={activeApplications} />
        </Section>
        {/* <Section>
          <RecentActivityTile isActivityLogPending={isActivityLogPending} activityLog={activityLog} />
        </Section> */}
      </HorizontalFlow>
      {/* <HorizontalFlow>
        <QuickTile pending={isActivityLogPending} title={t('quickTiles.consents')} data={lastMonthAddConsents} />
        <QuickTile pending={isActivityLogPending} title={t('quickTiles.dataProcessing')} data={dataProcessing} />
        <QuickTile pending={isActivityLogPending} title={t('quickTiles.dataImport')} data={dataImport} />
        <QuickTile pending={isActivityLogPending} title={t('quickTiles.dataWritten')} data={dataWritten} />
        <QuickTile pending={isActivityLogPending} title={t('quickTiles.dataRead')} data={dataRead} />
      </HorizontalFlow> */}

      {app && (
        <RedirectionContainer>
          <h2 id="time-left">
            You will be redirect to {app.name} in {(timeLeft / 1000).toFixed(0)} seconds
          </h2>
          <Button
            buttonType={buttonTypes.primary}
            onClick={() => {
              window.open(app.appSettings.appUrl + '?startPassport=true');
            }}
            label={t('overview.redirectNow')}
          />
          <Button
            buttonType={buttonTypes.danger}
            label={t('overview.cancelRedirection')}
            onClick={() => {
              setApp(undefined);
              actions.reset();
            }}
          />
        </RedirectionContainer>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
