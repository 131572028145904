import Paper from 'components/paper/Paper';
import Table from 'components/table/Table';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useServicesStore from '../hooks/useServicesStore';
import ServicesEmptyState from './Services.empty';
import ServicesLoadingState from './Services.loading';
import ServicesTable from './ServicesTable';
// import { Service } from '../types';

const Services: React.FC = () => {
  // const [selectedServiceIds, setSelectedServiceIds] = useState<string[]>([]);
  // const [allChecked, setAllChecked] = useState(false);
  const { t } = useTranslation(['services']);
  const { fetchServices, isFetchServicesPending, services } = useServicesStore();

  const getServices = React.useCallback(() => {
    fetchServices();
  }, [fetchServices]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  // const handleCheckService = (id: string) => {
  //   if (selectedServiceIds.includes(id)) {
  //     setSelectedServiceIds(selectedServiceIds.filter((service) => service !== id));
  //     if (services && selectedServiceIds.length === services.length) {
  //       setAllChecked(false);
  //     }
  //   } else {
  //     setSelectedServiceIds([...selectedServiceIds, id]);
  //     if (services && selectedServiceIds.length === services.length - 1) {
  //       setAllChecked(true);
  //     }
  //   }
  // };
  // const handleCheckAll = () => {
  //   if (services) {
  //     if (selectedServiceIds.length < services.length) {
  //       setSelectedServiceIds([...services.map((service: Service) => service.id)]);
  //       setAllChecked(true);
  //     } else {
  //       setSelectedServiceIds([]);
  //       setAllChecked(false);
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      <Paper heading={t('services.heading')} subHeading={t('services.subHeading')}>
        <Table
          tableHeaders={[
            { title: t('services.servicesColumn') },
            { title: t('services.purposeColumn') },
             /* { title: t('services.dataSourcesColumn') }, */
            { title: t('services.statusColumn'), width: 200 },
            { title: '', width: 150 },
          ]}
        >
          <React.Fragment>
            {isFetchServicesPending && !services ? (
              <ServicesLoadingState />
            ) : !isFetchServicesPending && services && services.length < 1 ? (
              <ServicesEmptyState />
            ) : (
              <ServicesTable servicesList={services} />
            )}
          </React.Fragment>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default Services;
