import styled, { css } from 'styled-components';
import { animations } from 'config/theme/keyframes';
import { palette } from '../../config/theme/themeFiles';

type Props = {
  position?: 'top' | 'bottom' | 'left' | 'right' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  positionAbsolute?: boolean;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  boxed?: boolean;
};

export const StyledTooltip = styled.span<Props>`
  animation: ${animations.fadeIn} 0.1s ease-in forwards;
  background: ${palette.neutral.shade6};
  color: ${palette.neutral.shade2};
  border-radius: 5px;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.1), 1px 3px 4px rgba(0, 0, 0, 0.12);
  font-size: 0.9em;
  opacity: 0;
  padding: 5px 10px;
  position: ${(props) => (props.positionAbsolute ? 'absolute' : 'fixed')};
  z-index: 200;
  white-space: nowrap;

  &&& {
    * {
      color: ${palette.neutral.shade2};
      font-size: 0.9em;
    }
  }

  ${(props) =>
    props.left &&
    css`
      left: ${props.left}px;
    `}
  ${(props) =>
    props.top &&
    css`
      top: ${props.top}px;
    `}
  ${(props) =>
    props.right &&
    css`
      right: ${props.right}px;
    `}
  ${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom}px;
    `}

  ${(props) =>
    props.position === 'left' &&
    css`
      transform: translateX(-100%) translateY(-50%);
    `}

  ${(props) =>
    props.position === 'right' &&
    css`
      transform: translateY(-50%);
    `}

  ${(props) =>
    props.position === 'top-left' &&
    css`
      transform: translateX(-100%) translateY(-100%);
    `}

  ${(props) =>
    props.position === 'top-right' &&
    css`
      transform: translateY(-100%);
    `}

  ${(props) =>
    props.position === 'bottom-left' &&
    css`
      transform: translateX(-100%);
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      transform: translateX(-50%) translateY(-100%);
    `}
  ${(props) =>
    props.position === 'bottom' &&
    css`
      transform: translateX(-50%);
      ${props.positionAbsolute &&
      css`
        transform: translateX(-50%) translateY(100%);
      `}
    `}
  ${(props) =>
    props.boxed &&
    css`
      max-width: '420px';
      white-space: normal;
    `}

  > li {
    list-style-type: none;
  }
`;
