import styled from 'config/theme/styled';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { mediaSize } from '../../config/theme/themeFiles/MediaSizes';
import { AppContext, useRemoteClient } from '../../modules/app/components/AppContext';
import Burger from '../burger/Burger';
import StyledNavBar from './SideNav.styled';
import SideNavContent from './SideNavContent';

import useDataPodDetailsStore from '../../modules/data-pod-details/hooks/useDataPodDetailStore';
import useStoredDataStore from '../../modules/data-pod-details/hooks/useStoredDataStore';

const SideNav: React.FC = () => {
  const { t } = useTranslation(['translation', 'errors']);
  const [isOpen, setIsOpen] = useState(false);

  const context = useContext(AppContext);
  const history = useHistory();
  const client = useRemoteClient();

  const CurrentUserID = context?.currentUser?.id;

  const { podResource, fetchDataPodResources } = useStoredDataStore();
  const { masterPod, fetchMasterPod } = useDataPodDetailsStore();

  const getMasterDataPod = useCallback(() => {
    fetchMasterPod();
  }, [fetchMasterPod]);

  const getPodResource = useCallback(() => {
    if (masterPod.uri) {
      fetchDataPodResources(masterPod.uri);
    }
  }, [fetchDataPodResources, masterPod.uri]);

  useEffect(() => {
    getMasterDataPod();
  }, [getMasterDataPod]);

  useEffect(() => {
    getPodResource();
  }, [getPodResource]);

  const podUrl = podResource?.content[0].directUrl;

  const handleSetOpen = () => setIsOpen(!isOpen);

  const handleLogOut = () => {
    toast.promise(
      client
        .getPassport()
        .logout()
        .then(() => {
          context.updateCtx({ currentUser: undefined });
          history.push('/');
        }),
      {
        pending: t('translation:general.isPending', { description: 'logout' }),
        success: t('translation:general.loggedOut'),
        error: t('errors:authentication.logoutFailed'),
      }
    );
  };

  return (
    <React.Fragment>
      <StyledNavBar isOpen={isOpen}>
        {CurrentUserID && <SideNavContent podUrl={podUrl} handleLogOut={handleLogOut} userId={CurrentUserID} />}
      </StyledNavBar>
      <BurgerHelper>
        <Burger isOpen={isOpen} setIsOpen={handleSetOpen} />
      </BurgerHelper>
      <StyledNavBarHelper>
        <StyledNavBar isOpen={true}>
          {CurrentUserID && <SideNavContent podUrl={podUrl} handleLogOut={handleLogOut} userId={CurrentUserID} />}
        </StyledNavBar>
      </StyledNavBarHelper>
    </React.Fragment>
  );
};

export default SideNav;

const BurgerHelper = styled.div`
  ${mediaSize.tabletAndAbove`
    display: none;
  `}

  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 11;
`;

const StyledNavBarHelper = styled.div`
  ${mediaSize.mobile`
    display: none;
  `}
`;
