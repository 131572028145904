import { ContentLoader, SkeletonGenerator } from 'components/Skeleton';
import * as React from 'react';

const ActivityLogLoadingState: React.FC = () => (
  <section style={{ maxWidth: 800 }}>
    <SkeletonGenerator count={3}>
      <ContentLoader width={800} height={80}>
        <circle x="0" cx="38" cy="38" r="24" />
        <rect x="85" y="20" rx="4" ry="4" width="120" height="16" />
        <rect x="85" y="40" rx="4" ry="4" width="85" height="16" />
        <rect x="335" y="30" rx="4" ry="4" width="250" height="16" />
      </ContentLoader>
    </SkeletonGenerator>
  </section>
);

export default ActivityLogLoadingState;
