import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const DataProcess: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 4H4V20H6H20V18V5.39848V4Z"
          stroke={color || '#221b38'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 10H14V14H10V10Z"
          stroke={color || '#221b38'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9 2V4" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M15 2V4" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M9 20V22" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M15 20V22" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M20 9H22" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M20 14H22" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M2 9H4" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M2 14H4" stroke={color || '#221b38'} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      </svg>
    </BaseSvgWrapper>
  );
};

export default DataProcess;
