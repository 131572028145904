import { Activity, ApplicationDescriptor, Consent, DatasourceRecord, MASTER_POD_ALIAS } from '@datavillage-me/api';
import useToggle from 'hooks/useToggle';
import { AppContext, useRemoteClient } from 'modules/app/components/AppContext';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const useDashboardStore = () => {
  const { t } = useTranslation(['errors']);

  const remoteClient = useRemoteClient();
  const context = useContext(AppContext);
  const userId = context.currentUser?.id;

  const [activeApplications, setActiveApplications] = useState<ApplicationDescriptor[] | null>(null);
  const [isFetchAAPending, setIsFetchAAPending] = useToggle(true);

  const [activatedDataSources, setActivatedDataSources] = useState<DatasourceRecord[] | null>(null);
  const [isFetchADSPending, setIsFetchADSPending] = useToggle(true);

  const [consents, setConsents] = useState<Consent[] | null>(null);
  const [isConsentsPending, setIsConsentsPending] = useToggle(true);

  const [activityLog, setActivityLog] = useState<Activity[] | null>(null);
  const [isActivityLogPending, setIsActivityLogPending] = useToggle(true);

  const fetchActiveApplications = useCallback(() => {
    if (userId) {
      remoteClient
        .getUsersServices()
        .getPodInstance(userId, MASTER_POD_ALIAS)
        .getActiveApplications()
        .then((result) => {
          setActiveApplications(result);
        })
        .finally(() => {
          setIsFetchAAPending(false);
        })
        .catch(() => {
          toast.error(t('dashboard.activeApplications_fetch'));
        });
    } else {
      toast.error(t('authentication.notLoggedIn'));
    }
  }, [remoteClient, setIsFetchAAPending, t, userId]);

  const fetchActivatedDataSources = useCallback(() => {
    if (userId) {
      remoteClient
        .getUsersServices()
        .getPodInstance(userId, MASTER_POD_ALIAS)
        .getActivatedDatasources()
        .then((result) => {
          setActivatedDataSources(result);
        })
        .finally(() => {
          setIsFetchADSPending(false);
        })
        .catch(() => {
          toast.error(t('dashboard.activatedDataSources_fetch'));
        });
    } else {
      toast.error(t('authentication.notLoggedIn'));
    }
  }, [remoteClient, setIsFetchADSPending, t, userId]);

  const fetchConsents = useCallback(() => {
    if (userId) {
      remoteClient
        .getConsentsServices()
        .getConsents(userId, undefined, undefined, undefined, true)
        .then((result) => {
          setConsents(result);
        })
        .finally(() => {
          setIsConsentsPending(false);
        })
        .catch(() => {
          toast.error(t('dashboard.consents_fetch'));
        });
    } else {
      toast.error(t('authentication.notLoggedIn'));
    }
  }, [remoteClient, setIsConsentsPending, t, userId]);

  const fetchActivityLog = useCallback(() => {
    if (userId) {
      remoteClient
        .getActivityLog()
        .getActivityLog({ userId })
        .then((result) => {
          setActivityLog(result);
        })
        .finally(() => {
          setIsActivityLogPending(false);
        })
        .catch(() => {
          toast.error(t('dashboard.activityLog_fetch'));
        });
    } else {
      toast.error(t('authentication.notLoggedIn'));
    }
  }, [remoteClient, setIsActivityLogPending, t, userId]);

  return {
    activatedDataSources,
    activeApplications,
    activityLog,
    consents,
    fetchActivatedDataSources,
    fetchActiveApplications,
    fetchActivityLog,
    fetchConsents,
    isActivityLogPending,
    isConsentsPending,
    isFetchAAPending,
    isFetchADSPending,
  };
};

export default useDashboardStore;
