import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';

import { LandingHeader } from './LandingHeader';
import { useTranslation } from 'react-i18next';
import { LandingTitle } from './LandingTitle';
import styled from '../../config/theme/styled';
import { palette } from '../../config/theme/themeFiles';
import { LandingCard } from './LandingCard';
import { LandingComparison } from './LandingComparison';
import { LandingFooter } from './LandingFooter';
import { LandingFaq } from './LandingFaq';
import { useSetAppInLocalStorage } from '../../hooks/useSetAppId';
import { mediaSize } from '../../config/theme/themeFiles/MediaSizes';

export const Landing: React.FC = () => {
  const { t } = useTranslation();

  const cards = t('landing.cards', { returnObjects: true });

  useSetAppInLocalStorage();

  return (
    <LandingContainer>
      <LandingHeader />
      <TopSection>
        <LandingTitle />
        <TopWave>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </TopWave>
      </TopSection>
      <MiddleContent>
        <CardsContainer>
          {
            // @ts-ignore
            cards.map((card, index) => {
              return <LandingCard key={index} item={card} index={index}></LandingCard>;
            })
          }
        </CardsContainer>
        <LandingComparison />
      </MiddleContent>
      <BottomSection>
        <BottomWave>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </BottomWave>
        <TextContainer>
          <H1>{t('landing.joinPeople')}</H1>
          <NavLink to="/login">
            <Button
              className={'getMyWallet'}
              buttonType={buttonTypes.primary}
              label={t('landing.getWallet')}
              onClick={() => undefined}
            ></Button>
          </NavLink>
        </TextContainer>
        <LandingFaq />
        <LandingFooter />
      </BottomSection>
    </LandingContainer>
  );
};

const LandingContainer = styled.div`
  background-color: white;
`;
const TopSection = styled.div`
  background-image: linear-gradient(to bottom right, ${palette.secondary.shade9}, ${palette.primary.shade4});
  position: relative;
`;

const TopWave = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);

  svg {
    position: relative;
    display: block;
    width: calc(151% + 1.3px);
    height: 189px;
    ${mediaSize.mobile`
        height: 50px;
    `}
  }

  .shape-fill {
    fill: #ffffff;
  }
`;

const MiddleContent = styled.div`
  width: 80%;
  max-width: 1300px;
  margin: auto;
`;

const CardsContainer = styled.div`
  padding-top: 50px;
  ${mediaSize.mobile`
    padding-top: 0;
    `}
`;

const TextContainer = styled.div`
  padding-top: 150px;
  ${mediaSize.mobile`
    padding-top: 75px;
    `}
  width: 80%;
  max-width: 1300px;
  margin: auto;
  text-align: center;

  .getMyWallet {
    background-color: ${palette.primary.shade4};
    font-weight: bold;
  }

  h1 {
    color: white;
  }
`;

const BottomSection = styled.div`
  background-image: linear-gradient(to bottom right, ${palette.primary.shade4}, ${palette.secondary.shade9});
  position: relative;
`;

const BottomWave = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  svg {
    position: relative;
    display: block;
    width: calc(162% + 1.3px);
    height: 161px;
    ${mediaSize.mobile`
        height: 50px;
    `}
  }

  .shape-fill {
    fill: #ffffff;
  }
`;

const H1 = styled.h1`
  margin-bottom: 30px;
`;
