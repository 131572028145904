import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/turtle/turtle';
import 'codemirror/mode/javascript/javascript';

export function guessMode(filename?: string, contentType?: string) {
  switch (contentType) {
    case 'application/rdf':
    case 'text/turtle':
      return 'turtle';
    case 'application/json':
    case 'text/json':
      return 'javascript';
    case 'application/rdf+xml':
    case 'application/xml':
    case 'text/xml':
      return 'xml';
    default:
  }

  // TODO guess mode from content type once available
  const extension = filename?.includes('.') ? filename?.split('.').pop() : undefined;
  switch (extension) {
    case 'rdf': // currently we always have turtle, even in rdf files - TODO : use content type to be sure
    case 'ttl':
      return 'turtle';
    case 'json':
      return 'javascript';
    case 'xml':
      return 'xml';
    default:
      return 'text';
  }
}

export const CodeEditor = memo(
  (props: { code: string; onCodeChange?: (code: string) => void; height?: string | number; language?: string }) => {
    return (
      <StyledCodeMirror
        value={props.code}
        options={{
          mode: props.language,
          //theme: 'material',
          lineNumbers: true,
        }}
        onChange={(editor, data, value) => {props.onCodeChange && props.onCodeChange(value)}}
      />
    );
  }
);

const StyledCodeMirror = styled(CodeMirror)`
  margin-top: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  > div {
    border-radius: 10px;
  }

  .CodeMirror {
    height: inherit;
    flex: 1;
    min-height: 0;
  }
`;
