import styled from 'styled-components';
import { palette } from '../../config/theme/themeFiles';
import { Flexer } from '../interface';

export const Wrapper = styled(Flexer)`
  padding: 16px;
`;

export const ConfirmationDescription = styled.p`
  color: ${palette.neutral.shade4};
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  white-space: pre-line;
`;

export const ConfirmationButtonWrapper = styled(Flexer)`
  button {
    margin-left: 5px;
    :nth-child(1) {
      margin-left: 0px;
    }
  }
`;
