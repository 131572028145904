import announcement from 'assets/illustrations/announcment.svg';
import styled from 'config/theme/styled';
import { palette } from 'config/theme/themeFiles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DATAVILLAGE_CHROME_EXTENSION_URL } from '../../constants';

const ExtensionButton = () => {
  const { t } = useTranslation();

  const handleGoToExtension = () => {
    window.open(DATAVILLAGE_CHROME_EXTENSION_URL, '_blank');
  };

  return (
    <StyledExtensionWrapper>
      <AnnouncementImg src={announcement} />
      <DisclaimerText>{t('extension.shortDisclaimer')}</DisclaimerText>
      <NavButtonDownload onClick={handleGoToExtension}>{t('extension.download')}</NavButtonDownload>
    </StyledExtensionWrapper>
  );
};

const StyledExtensionWrapper = styled.div`
  align-items: center;
  background: ${palette.primary.shade3};
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 20px 2px #00ffff73;
  color: ${palette.primary.shade8};
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin: 120px 0 20px 14px;
  min-height: 50px;
  padding: 0 10px;
  text-align: center;
  text-align: left;
  width: 215px;
  transition: all 0.2s ease-in-out;
`;

const DisclaimerText = styled.p`
  font-size: 13px;
  font-weight: 600;
  line-height: 170%;
  padding: 10px 10px 0;
  text-align: center;
`;

const AnnouncementImg = styled.img`
  margin: -60px auto 0;
  max-width: 80px;
`;

const NavButtonDownload = styled.button`
  background: ${palette.primary.shade8};
  border: none;
  font-size: 14px;
  padding: 10px 20px;
  color: #fff;
  border-radius: 200px;
  margin: 20px auto 15px;

  &:hover {
    cursor: pointer;
    background: ${palette.primary.shade7};
    color: ${palette.primary.shade1};
    box-shadow: 0px 4px 20px 2px #101f3c17;
    transform: translateY(-2px);
    transition: all 0.2s ease-in-out;
  }
`;

export default ExtensionButton;
