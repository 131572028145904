import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const WriteDataPod: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" fill="white"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
      </svg>
    </BaseSvgWrapper>
  );
};

export default WriteDataPod;
