import styled from 'config/theme/styled';
import { palette } from '../../config/theme/themeFiles';

type Props = {
  isOpen?: boolean;
};

export const StyledNavBar = styled.nav<Props>`
  align-items: left;
  background-color: ${palette.neutral.shade1};
  color: ${palette.secondary.shade8};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  opacity: ${(props) => (props.isOpen ? '100%' : '0')};
  overflow: hidden;
  position: fixed;
  text-align: left;
  top: 0;
  transition: all 0.3s ease-in-out;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  width: 250px;

  button {
    margin-right: 15px;
  }
`;

export default StyledNavBar;
