import { palette } from 'config/theme/themeFiles';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  closeMenu?: VoidFunction;
  children: Array<JSX.Element | false | null | undefined> | JSX.Element | false | null | undefined;
  maxHeight?: number;
  className?: string;
  visibleOverflow?: boolean;
};

const ContextMenuContainer = ({ children, closeMenu, maxHeight, className, visibleOverflow }: Props) => {
  const preventBubbling = (e: React.UIEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <ContextMenuContainerWrapper
      maxHeight={maxHeight || 450}
      onScroll={preventBubbling}
      onClick={preventBubbling}
      className={className}
      visibleOverflow={visibleOverflow}
    >
      {children &&
        React.Children.map(
          Array.isArray(children) ? (children.filter((child) => child) as JSX.Element[]) : children,
          (child: JSX.Element, index: number) => React.cloneElement(child, { closeMenu, key: `menu_${index}` })
        )}
    </ContextMenuContainerWrapper>
  );
};

const ContextMenuContainerWrapper = styled.div<{ maxHeight?: number; visibleOverflow?: boolean }>`
  background-color: ${palette.neutral.shade0};
  border-radius: 5px;
  box-shadow: 2px 6px 34px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 400px;
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight}px`};
  overflow-y: ${(props) => (props.visibleOverflow ? 'visible' : 'auto')};
`;

export default ContextMenuContainer;
