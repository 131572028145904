import React, { useMemo } from 'react';
// @ts-ignore
import Faq from 'react-faq-component';
import styled from '../../config/theme/styled';
import { useTranslation } from 'react-i18next';

export const LandingFaq: React.FC = () => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const questions: { title: string; content: string }[] = t('landing.faq.questions', { returnObjects: true });

    return { rows: questions };
  }, []);

  return (
    <LandingFaqContainer>
      <FaqTitle>{t('landing.faq.title')}</FaqTitle>
      <Faq data={data} />
    </LandingFaqContainer>
  );
};

const LandingFaqContainer = styled.div`
  padding: 100px 0 50px;

  .faq-row-wrapper {
    max-width: 1300px;
    width: 80%;
    margin: auto;
    background-color: inherit;
  }

  .row-title-text,
  .row-content-text {
    color: white !important;
  }

  svg {
    fill: white !important;
  }
`;

const FaqTitle = styled.h1`
  width: 80%;
  max-width: 1300px;
  margin: 0 auto 10px;
  color: white;
`;
