import React from 'react';
import styled from 'styled-components';
import UnderConstructionImage from 'assets/illustrations/under_construction.svg';

const Settings: React.FC = () => {
  return (
    <SettingsContainer>
      <Soon>Coming Soon</Soon>
      <SettingsImage src={UnderConstructionImage} />
    </SettingsContainer>
  );
};

export default Settings;

const Soon = styled.h1`
  align-self: center;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const SettingsImage = styled.img`
  max-height: 60vh;
  align-self: center;
`;
