import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const House: React.FC = (): JSX.Element => {
  //   const color = props.color ? props.color : '#000';
  return (
    <BaseSvgWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
        <polyline points="9 22 9 12 15 12 15 22"></polyline>
      </svg>
    </BaseSvgWrapper>
  );
};

export default House;
