import React from 'react';
import styled from 'config/theme/styled';
import paletteTheme from 'config/theme/themeFiles/palette';

type Props = {
  isOpen: boolean;
  handleShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthorizationDetails = ({ isOpen, handleShowDetails }: Props) => (
  <AuthorizationDetailsWrapper isOpen={isOpen} handleShowDetails={handleShowDetails}>
    <h1>VRT</h1>
    <Content>
      <div>
        <h2>Date of authorization</h2>
        <p>24/07/2021</p>
      </div>
      <div>
        <h2>Time remaining</h2>
        <p>41 days 2 hours</p>
      </div>
      <div>
        <h2>Purpose</h2>
        <ul>
          <li>Movie recommendations</li>
          <li>Series scores</li>
          <li>Advertizing</li>
        </ul>
      </div>
    </Content>
    <ButtonsWrapper>
      <div>
        <CloseButton onClick={() => handleShowDetails(!isOpen)}>Close</CloseButton>
      </div>
      <div>
        <RevokeButton className="mr">Revoke</RevokeButton>
        <RenewButton>Renew</RenewButton>
      </div>
    </ButtonsWrapper>
  </AuthorizationDetailsWrapper>
);

export default AuthorizationDetails;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${paletteTheme.neutral.shade1};
  padding: 10px 26px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const CloseButton = styled.button`
  background: ${paletteTheme.neutral.shade3};
  padding: 12px 26px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

const RevokeButton = styled.button`
  background: ${paletteTheme.danger.shade1};
  padding: 12px 26px;
  border-radius: 10px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
`;

const RenewButton = styled.button`
  background: ${paletteTheme.success.shade1};
  padding: 12px 26px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  > div {
    padding: 26px;
  }
  h2 {
    color: ${paletteTheme.neutral.shade4};
    font-weight: 400;
  }
`;
const AuthorizationDetailsWrapper = styled.div<Props>`
  background: white;
  border-radius: 25px;
  box-shadow: 0 4px 11px 9px rgb(158 158 158 / 9%);
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
  left: calc(50% - 300px);
  margin: 16px;
  max-height: 95%;
  max-width: 95%;
  position: fixed;
  top: 50px;
  width: 600px;
  z-index: 2;
  h1 {
    padding: 26px;
  }
`;
