import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import styled from '../../config/theme/styled';
import CodenameDashboard from '../../assets/images/codename_dashboard.png';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { palette } from '../../config/theme/themeFiles';
import { mediaSize } from '../../config/theme/themeFiles/MediaSizes';

export const LandingTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TitleContainer>
      <h1>{t('landing.title')}</h1>
      <h2>
        <Trans i18nKey="landing.subtitle" />
      </h2>
      <NavLink to="/login">
        <Button
          className={'getMyWallet'}
          buttonType={buttonTypes.primary}
          label={t('landing.getWallet')}
          onClick={() => undefined}
        ></Button>
      </NavLink>
      <Img src={CodenameDashboard} />
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  width: 80%;
  max-width: 1300px;
  margin: auto;
  text-align: center;
  padding-top: 125px;

  h1 {
    font-size: 60px;
    margin: 0;
  }

  h2 {
    font-size: 30px;
    font-weight: 200;
    margin: 50px 0;
  }

  h1,
  h2 {
    color: white;
  }

  .getMyWallet {
    background-color: ${palette.primary.shade4};
    margin-bottom: 30px;
    ${mediaSize.mobile`
    margin-bottom: 60px;
    `}
    font-weight: bold;
  }
`;

const Img = styled.img`
  ${mediaSize.mobile`
    display: none;
  `}
  margin-top: 25px;
  width: 100%;
  border-radius: 5px;
`;
