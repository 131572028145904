import styled from '../../config/theme/styled';
import palette from '../../config/theme/themeFiles/palette';

type ContainerProps = { providerLinked: boolean; editable?: boolean; noPaddingBottom?: boolean };

export const CardContainer = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid ${palette.neutral.shade2};
  margin-right: 20px;
  padding: ${(props) => (props.noPaddingBottom ? '10px 10px 0 10px' : '10px')};
  margin-bottom: 10px;

  ${(props) =>
    props.providerLinked &&
    `
    border: 1px solid ${palette.success.shade4};
    background: ${palette.success.shade1};
  `}
  .activateCta {
    display: none;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  :hover .activateCta {
    display: block;
  }

  :hover > * {
    :not(.activateCta) {
      opacity: ${(props) => (props.editable ? 0.3 : 1)};
    }
  }
`;

export const LogoHelper = styled.div`
  display: flex;
  flex-direction: column;

  img {
    max-height: 55px;
  }
`;

export const ServiceCategory = styled.span`
  background: ${palette.primary.shade4};
  padding: 5px 20px;
  border-radius: 10px;
  color: ${palette.primary.shade7};
  font-size: 0.8em;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ServiceHeader = styled.h2`
  font-size: 0.8em;
  font-weight: 400;
  color: ${palette.primary.shade7};
  margin: 0 0 10px;
`;
