import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const ReadDataPod: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="none"
          d="M6.51493 4.12125C5.62459 4.34383 5 5.1438 5 6.06153V6.806C5 5.5889 6.07798 4.65398 7.28284 4.8261L17 6.21427V4.06153C17 2.76039 15.7772 1.80567 14.5149 2.12125L6.51493 4.12125Z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
        <path
          fill="black"
          d="M6.51493 4.12125L6.36941 3.53916L6.36941 3.53916L6.51493 4.12125ZM4.4 6.806C4.4 7.13737 4.66863 7.406 5 7.406C5.33137 7.406 5.6 7.13737 5.6 6.806H4.4ZM7.28284 4.8261L7.3677 4.23213L7.3677 4.23213L7.28284 4.8261ZM17 6.21427L16.9151 6.80824C17.0873 6.83283 17.2617 6.7815 17.3931 6.66754C17.5245 6.55358 17.6 6.3882 17.6 6.21427H17ZM14.5149 2.12125L14.3694 1.53916L14.3694 1.53916L14.5149 2.12125ZM5.6 6.06153C5.6 5.41912 6.03722 4.85914 6.66045 4.70333L6.36941 3.53916C5.21197 3.82852 4.4 4.86848 4.4 6.06153H5.6ZM5.6 6.806V6.06153H4.4V6.806H5.6ZM5.6 6.806C5.6 5.95403 6.35458 5.29958 7.19799 5.42007L7.3677 4.23213C5.80137 4.00837 4.4 5.22377 4.4 6.806H5.6ZM7.19799 5.42007L16.9151 6.80824L17.0849 5.6203L7.3677 4.23213L7.19799 5.42007ZM16.4 4.06153V6.21427H17.6V4.06153H16.4ZM14.6605 2.70333C15.5441 2.48243 16.4 3.15073 16.4 4.06153H17.6C17.6 2.37005 16.0104 1.12892 14.3694 1.53916L14.6605 2.70333ZM6.66045 4.70333L14.6605 2.70333L14.3694 1.53916L6.36941 3.53916L6.66045 4.70333Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M5 6.80602C5 5.58892 6.07798 4.654 7.28284 4.82612L17.2828 6.25469C18.2681 6.39545 19 7.23929 19 8.23459V20.194C19 21.4111 17.922 22.346 16.7172 22.1739L6.71716 20.7453C5.73186 20.6046 5 19.7607 5 18.7654V6.80602Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M9 10L15 11"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M9 14L15 15"
        ></path>
      </svg>
    </BaseSvgWrapper>
  );
};

export default ReadDataPod;
