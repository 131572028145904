import { ActivityType } from '@datavillage-me/api';
import {
  AddConsent,
  DatabaseCheck,
  DatabaseCross,
  // DataImport,
  DataProcess,
  IssueToken,
  ReadDataPod,
  Refresh,
  RevokeConsent,
  UpdateConsent,
  WriteDataPod,
} from 'assets/icons';
import * as React from 'react';

type Props = {
  activityType: ActivityType;
};

const ActivityLogIcon = ({ activityType }: Props) => {
  switch (activityType) {
    case 'ADD_CONSENT':
      return <AddConsent color="white" />;

    case 'DATASOURCE_GRANT':
      return <DatabaseCheck color="white" />;

    case 'DATASOURCE_REVOKE':
      return <DatabaseCross color="white" />;

    case 'DATA_IMPORT':
      return <DataProcess color="white" />;

    case 'DATA_PROCESSING':
      return <DataProcess color="white" />;

    case 'ISSUE_TOKEN':
      return <IssueToken color="white" />;

    case 'READ_POD_DATA':
      return <ReadDataPod color="white" />;

    case 'REVOKE_CONSENT':
      return <RevokeConsent color="white" />;

    case 'UPDATE_CONSENT':
      return <UpdateConsent color="white" />;

    case 'WRITE_POD_DATA':
    case 'WRITE_APP_RESULT':
      return <WriteDataPod color="white" />;

    // TODO INIT_POD

    default:
      return <Refresh color="white" />;
  }
};

export default ActivityLogIcon;
