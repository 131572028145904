export class DatavillageExtension {
  private _extensionId: string;

  constructor(extensionId: string) {
    this._extensionId = extensionId;
  }

  notifyLogoutSuccess() {
    if (chrome.runtime) {
      chrome.runtime.sendMessage(this._extensionId, 'LOGOUT_SUCCESS');
    } else {
      console.warn('Chrome runtime not found in scope - cannot notify ');
    }
  }

  notifyLoginSuccess() {
    if (chrome.runtime) {
      chrome.runtime.sendMessage(this._extensionId, 'LOGIN_SUCCESS');
    } else {
      console.warn('Chrome runtime not found in scope - cannot notify ');
    }
  }
}

export const getExtension = (extensionId: string) => new DatavillageExtension(extensionId);
