import {
  prependNamespacePrefix,
  reduceWithWellKnownPrefix,
  UnprefixedForPersonalData,
  Consent,
} from '@datavillage-me/api';
import { SharedDataSource } from '../modules/passport-app/context/PassportContext';

export const generateForPersonalData = (sharedDataSources: SharedDataSource[]): UnprefixedForPersonalData[] => {
  const forPersonalData: UnprefixedForPersonalData[] = [];

  sharedDataSources.forEach((sDS) => {
    sDS.categories.forEach((category) => {
      const dataType = reduceWithWellKnownPrefix(category);
      const index = forPersonalData.findIndex((fPD) => fPD.personalDataType === dataType);
      const providerQualifiedId = prependNamespacePrefix('dv', sDS.dataSourceId);

      index > -1
        ? forPersonalData[index].personalDataSource.push(providerQualifiedId)
        : forPersonalData.push({
            personalDataType: dataType,
            personalDataSource: [providerQualifiedId],
          });
    });
  });

  return forPersonalData;
};

const removeDatavillagePrefix = (str: string): string => {
  return str.replace('dv:', '');
};

export const extractDataSourceIds = (consent: Consent): string[] => {
  const previouslyActivatedDataSourceIds: string[] = [];
  consent['gConsent:forPersonalData'].forEach((fPD) => {
    fPD['dvConsent:personalDataSource'].forEach((pDS) => {
      if (!previouslyActivatedDataSourceIds.includes(removeDatavillagePrefix(pDS)))
        previouslyActivatedDataSourceIds.push(removeDatavillagePrefix(pDS));
    });
  });

  return previouslyActivatedDataSourceIds;
};
