import AlertTriangle from 'assets/icons/alert-triangle.svg';
import Auvio from 'assets/images/auvio.png';
import Facebook from 'assets/images/facebook.svg';
import Netflix from 'assets/images/netflix.svg';
import Spotify from 'assets/images/spotify.svg';
import Strava from 'assets/images/strava.svg';
import VRTNu from 'assets/images/vrtnu.png';

export const logoFactory = (nameOrId: string) => {
  nameOrId = nameOrId.toLowerCase();

  switch (nameOrId) {
    case 'auvio':
    case 'feabd20d-629d-4e47-91da-cbb1b373a415':
    case '9bbff57f-4651-4c50-b29a-ec63365b3fbf':
    case '55bd628c-b799-4d46-9e41-0f3e4dc255bb':
      return Auvio;

    case 'facebook':
      return Facebook;

    case 'netflix':
      return Netflix;

    case 'spotify':
      return Spotify;

    case 'strava':
      return Strava;

    case 'vrt':
    case 'vrtnu':
    case '6e883edf-528a-4b18-8909-be5b1d6cd7e9':
    case '00eca6c5-d17c-4870-bdec-780fd3ca2676':
      return VRTNu;

    default:
      return AlertTriangle;
  }
};
