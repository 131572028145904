import React from 'react';
import { Button, buttonTypes } from '@datavillage-me/dv-common-ui';
import { NavLink } from 'react-router-dom';

import styled from '../../config/theme/styled';
import { palette } from '../../config/theme/themeFiles';
import { useTranslation } from 'react-i18next';
import Logo from 'assets/images/Wallet_Logo_White.png';
import { useOnScroll } from '../../hooks/useOnScroll';

export const LandingHeader: React.FC<{ hideSignIn?: boolean }> = ({ hideSignIn }) => {
  const { t } = useTranslation();

  const scroll = useOnScroll();

  return (
    <HeaderContainer scroll={scroll}>
      <DvLogo src={Logo}></DvLogo>
      {!hideSignIn && (
        <NavLink to="/login">
          <Button className={'signInCta'} onClick={() => undefined} buttonType={buttonTypes.secondary} label={t('landing.signIn')} />
        </NavLink>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div<{ scroll: number }>`
  height: 75px;
  background-color: ${(props) => (props.scroll > 10 ? palette.secondary.shade9_80 : 'none')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1;

  .signInCta {
    color: white;
    width: auto;
    margin-right: 30px;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const DvLogo = styled.img`
  height: 50px;
  margin: 0 10px 0 40px;
`;
