import { DataSourceMetadata, DatasourceRecord, MASTER_POD_ALIAS } from '@datavillage-me/api';
import useToggle from 'hooks/useToggle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AppContext, useRemoteClient } from '../../../app/components/AppContext';

const useDataSourcesStore = () => {
  const { t } = useTranslation(['errors']);
  const remoteClient = useRemoteClient();
  const userId = React.useContext(AppContext).currentUser?.id;

  const [activeDataSources, setActiveDataSources] = React.useState<DatasourceRecord[] | null>(null);
  const [allDataSources, setAllDataSources] = React.useState<DataSourceMetadata[] | null>(null);

  const [isActiveDSPending, setIsActiveDSPending] = useToggle(true);
  const [isAllDSPending, setIsAllDSPending] = useToggle(true);

  const fetchActiveDataSources = React.useCallback(() => {
    if (userId) {
      setIsActiveDSPending(true);
      remoteClient
        .getUsersServices()
        .getPodInstance(userId || '', MASTER_POD_ALIAS)
        .getActivatedDatasources()
        .then((result: DatasourceRecord[]) => {
          setActiveDataSources(result);
        })
        .finally(() => {
          setIsActiveDSPending(false);
        })
        .catch(() => {
          toast.error(t('dataSources.dataSources_fetch'));
        });
    } else {
      toast.error(t('authentication.notLoggedIn'));
    }
  }, [remoteClient, setIsActiveDSPending, t, userId]);

  const fetchAllDataSources = React.useCallback(() => {
    if (userId) {
      setIsAllDSPending(true);
      remoteClient
        .getDataBridge()
        .getDataSources()
        .then((dataSources) => {
          setAllDataSources(dataSources);
        })
        .finally(() => {
          setIsAllDSPending(false);
        })
        .catch(() => {
          toast.error(t('dataSources.dataSources_fetch'));
        });
    } else {
      toast.error(t('authentication.notLoggedIn'));
    }
  }, [remoteClient, setIsAllDSPending, t, userId]);

  return {
    activeDataSources,
    allDataSources,
    fetchActiveDataSources,
    fetchAllDataSources,
    isActiveDSPending,
    isAllDSPending,
  };
};

export default useDataSourcesStore;
