import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button, buttonTypes, palette, Text, textTypes } from '@datavillage-me/dv-common-ui';

import { AppContext, useRemoteClient } from '../app/components/AppContext';
import { generateForPersonalData } from '../../utils/consents';
import { PassportAppContext } from '../passport-app/context/PassportContext';
import { Toggle } from '../../components/toggle/Toggle';
import useToggle from '../../hooks/useToggle';
import { replaceAppNameInLabel } from '../../utils/labels';
import { Loader } from '../../components/loader/Loader';
import { getIcon } from '../../utils/dataSources';
import { LogoHelper } from '../../components/dataProvider/dataProviderStyle';
import { Footer } from '../passport-app/containers/PassportApp';

type Props = { goToPreviousStep: VoidFunction, validateStep: VoidFunction };

export const PassportSignConsent: React.FC<Props> = ({ goToPreviousStep, validateStep }) => {
    const appContext = useContext(AppContext);
    const passportContext = useContext(PassportAppContext);
    const remoteClient = useRemoteClient();
    const forPersonalData = useMemo(() => generateForPersonalData(passportContext.sharedDataSources!), [passportContext]);
    const { t } = useTranslation();
    const [checked, toggle] = useToggle(false);
    const [isLoading, toggleLoading] = useToggle(false);
    const [isSuccess, toggleSuccess] = useToggle(false);
    const [error, setError] = useState(null);

    const options = [
        { value: 1, label: t('passport.signConsent.oneDay') },
        { value: 7, label: t('passport.signConsent.oneWeek') },
        { value: 30, label: t('passport.signConsent.oneMonth') },
        { value: 365, label: t('passport.signConsent.oneYear') },
        { value: 36500, label: t('passport.signConsent.untilWithdraw') }
    ];
    const [selectedDuration, setSelectedDuration] = useState<any>(options[options.length - 1]);

    if (error) {
        throw error;
    }

    return isLoading
        ? <Loader />
        : <Container>
            <MainContent>
                {isSuccess
                    ? <>
                        <CheckIcon>✓</CheckIcon>
                        <Text type={textTypes.h3}>
                            {replaceAppNameInLabel(t, 'passport.signConsent.success', passportContext)}
                        </Text>
                    </>
                    : <>
                        <Text type={textTypes.h4}>
                            {replaceAppNameInLabel(t, 'passport.signConsent.selectedDatasources', passportContext)}
                        </Text>
                        <ProvidersContainer>
                            {passportContext.sharedDataSources!.map((sDS, index) => (
                                <LogoHelper className={'logo'} key={sDS.dataSourceId + index}>
                                    <img src={getIcon(sDS?.dataSourceId)} alt='logo' />
                                </LogoHelper>
                            ))}
                        </ProvidersContainer>
                        <Text type={textTypes.h4} className={'purpose'}>
                            {passportContext.consentReceipt?.value?.['gConsent:purpose']}
                        </Text>
                        <Text type={textTypes.h4}>
                            {replaceAppNameInLabel(t, 'passport.signConsent.selectDuration', passportContext)}
                        </Text>
                        <SelectContainer>
                            <Select options={options} value={selectedDuration}
                                    onChange={(event) => setSelectedDuration(event)} />
                        </SelectContainer>
                        <ToggleContainer>
                            <Toggle {...{ className: 'toggle', checked, toggle }} />
                            <Text type={textTypes.h4} className={'allow'}>
                                {replaceAppNameInLabel(t, 'passport.signConsent.allowService', passportContext)}
                            </Text>
                        </ToggleContainer>
                    </>
                }
            </MainContent>
            <Footer>
                <Button
                    {...{
                        buttonType: buttonTypes.secondary,
                        onClick: goToPreviousStep,
                        label: 'Previous'
                    }}
                />
                <Button
                    {...{
                        buttonType: buttonTypes.success,
                        disabled: !selectedDuration || !checked,
                        onClick: () => {
                            toggleLoading();
                            remoteClient
                                .getConsentsServices()
                                .createConsent(
                                    appContext.currentUser!.id,
                                    passportContext.consentReceipt!.value!['@id'],
                                    selectedDuration!.value * 24 * 3600 * 1000, // createConsent API expects a duration in millis
                                    forPersonalData,
                                    true
                                )
                                .then(() => {
                                        toggleLoading();
                                        toggleSuccess();

                                        window.parent.postMessage(
                                            {
                                                source: 'codename',
                                                content: 'closeModal'
                                            },
                                            '*'
                                        );

                                        console.log('window.location');
                                        console.log(window.location);
                                    }
                                )
                                .catch(setError);
                        },
                        label: t('passport.signConsent.confirm')
                    }}
                />
            </Footer>
        </Container>;

};

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectContainer = styled.div`
  width: 300px;
  margin-bottom: 25px;
`;

const ProvidersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 25px;

  .logo {
    img {
      margin-right: 20px;
      height: 35px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;

  .purpose {
    margin-bottom: 25px;
  }

  .allow {
    margin-left: 25px;
    margin-bottom: 0;
  }
`;

const MainContent = styled.div`
  margin: 0 auto;
`;

const CheckIcon = styled.div`
  border-radius: 30px;
  height: 60px;
  width: 60px;
  padding: 7px;
  font-size: 30px;
  text-align: center;
  color: green;
  border: 1px solid ${palette.success.shade4};
  background: ${palette.success.shade1};
  margin: auto;
  margin-bottom: 30px;
`;