import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  color?: string;
};

const IssueToken: React.FC<Props> = ({ color }: Props): JSX.Element => {
  return (
    <BaseSvgWrapper color={color}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="">
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M22 2H14V10H22V2Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M10 14H2V22H10V14Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          stroke={color || '#221b38'}
          fill="none"
          d="M10 2H2V10H10V2Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="square"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M14 18H22"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="square"
          strokeWidth="2"
          stroke={color || '#221b38'}
          d="M18 14V22"
        ></path>
      </svg>
    </BaseSvgWrapper>
  );
};

export default IssueToken;
