import React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const LogOut: React.FC = (): JSX.Element => {
  //   const color = props.color ? props.color : '#000';
  return (
    <BaseSvgWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
      </svg>
    </BaseSvgWrapper>
  );
};

export default LogOut;
