import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DATA_SOURCE_TYPE, DataSourceMetadata } from '@datavillage-me/api';
import { Button, buttonTypes, textTypes, Text } from '@datavillage-me/dv-common-ui';

import { CardContainer, ServiceHeader } from './dataProviderStyle';
import { PassportAppContext } from '../../modules/passport-app/context/PassportContext';
import { getIcon } from '../../utils/dataSources';
import styled from '../../config/theme/styled';
import useToggle from '../../hooks/useToggle';
import { ActivateDataSourceView } from './ActivateDataSourceView';

type Props = {
    dataSource: DataSourceMetadata & { recommended?: boolean };
    activated?: boolean;
    activateDataProvider: VoidFunction;
    deactivateDataProvider: VoidFunction;
};

export const DataProviderCard = ({ dataSource, activated, activateDataProvider, deactivateDataProvider }: Props) => {
    const passportContext = useContext(PassportAppContext);
    const { t } = useTranslation();
    const [showActivationView, toggleShowActivationView] = useToggle(false);

    const sharedActions = (passportContext.sharedDataSources || []).find(
        (sDS) => sDS.dataSourceId === dataSource.id
    )?.categories;
    const providerLinked = !!(sharedActions && sharedActions.length > 0);

    return (
        <Container>
            <CardContainer providerLinked={providerLinked} noPaddingBottom={true} editable={!showActivationView}>
                <ServiceHeader>{dataSource.label}</ServiceHeader>
                {showActivationView ? (
                    <ActivateDataSourceView dataSource={dataSource} onCancel={toggleShowActivationView} />
                ) : (
                    <>
                        <ImgContainer>
                            <img src={getIcon(dataSource.id)} alt='logo' />
                        </ImgContainer>

                        <Text type={textTypes.p} className={'mapping'}>
                            {t('passport.selectSources.usingMapping', {
                                MAPPING:
                                    dataSource.mappings
                                        .slice(0, -1)
                                        .map((mapping) => t('passport.selectSources.mapping.' + mapping.id))
                                        .join(t('passport.selectSources.mappingConnector')) +
                                    (dataSource.mappings.length > 1 ? t('passport.selectSources.lastMappingConnector') : '') +
                                    t('passport.selectSources.mapping.' + dataSource.mappings.slice(-1)[0].id)
                            })}
                        </Text>

                        {!providerLinked ? (
                            <Button
                                {...{
                                    className: 'activateCta',
                                    buttonType: buttonTypes.success,
                                    onClick: () => {
                                        if (dataSource.type !== DATA_SOURCE_TYPE.PULL || activated) {
                                            activateDataProvider();
                                        } else {
                                            toggleShowActivationView();
                                        }
                                    },
                                    label: t(`passport.selectSources.useSource`)
                                }}
                            />
                        ) : (
                            <Button
                                {...{
                                    className: 'activateCta',
                                    buttonType: buttonTypes.danger,
                                    onClick: deactivateDataProvider,
                                    label: t(`passport.selectSources.stopUsing`)
                                }}
                            />
                        )}
                        {/*{providerLinked && <DataProviderSelectAction providerActivated={!!activated} provider={provider} />}*/}
                        {dataSource?.recommended ? (
                            <RecommendedTag>Recommended</RecommendedTag>
                        ) : (
                            <RecommendedTag>&nbsp;</RecommendedTag>
                        )}
                    </>
                )}
            </CardContainer>
        </Container>
    );
};

const RecommendedTag = styled.p`
  font-size: 12px;
  font-style: italic;
  text-align: right;
  margin-top: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
`;
const Container = styled.div`
  width: 30%;
  margin-bottom: 20px;

  img {
    height: 55px;
  }

  .mapping {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const ImgContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
